import * as React from "react";
import {UserRegister} from "../../../model/User";
import TaskUpdateUser from "../../../ws/auth/TaskUpdateUser";
import Alert from "../../../base/alerts/Alert";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_DATOS_ACTUALIZADOS_CORRECTAMENTE} from "../../../I18n/constants";
import BillingDataForm, {BillingFormData} from "./BillingDataForm";
import BillingDataSectionViewReducer from "./BillingDataSectionViewReducer";
import {connect} from "react-redux";
import TaskLogin from "../../../ws/auth/TaskLogin";
import AuthManager from "../../../utils/AuthManager";

export interface BillingDataSectionProps {
    initialValues: Partial<UserRegister>
}

const mapStateToProps = BillingDataSectionViewReducer.autoMapToProps();

type Props = BillingDataSectionProps & typeof mapStateToProps;

class BillingDataSectionView extends React.Component<Props> {

    private handleSubmit = (values: BillingFormData): void => {
        new TaskUpdateUser(values)
            .onSuccess(() => {
                const auth = AuthManager.getAuth();
                auth && new TaskLogin(auth.username, auth.password).execute();
                Alert.success(I18nUtils.tr(TR_DATOS_ACTUALIZADOS_CORRECTAMENTE));
            })
            .onFail((message) => Alert.error(message))
            .execute();
    };

    public render(): React.ReactNode {
        return (
            <BillingDataForm
                parentLoading={this.props.loadingUploadBilling}
                onSubmit={this.handleSubmit}
                initialValues={this.props.initialValues}
            />
        )
    }
}

export default connect(mapStateToProps)(BillingDataSectionView as unknown as React.ComponentType<BillingDataSectionProps>);