import * as React from 'react';
import {ReducerBuilder} from "co-redux-builders";
import {connect} from "react-redux";
import I18nUtils from "../../../I18n/I18nUtils";
import MasterDataReducer from "../../../redux/reducers/MasterDataReducer";
import {TR_HORA, TR_NO_TIENES_NINGUN_TICKET} from "../../../I18n/constants";
import LoginReducer from "../../login/LoginReducer";
import {AppIconSVG} from "../../../components/IconSVG/AppIconSVG";
import IconBase from "../../../components/IconSVG/IconBase";
import DateFormatter from "../../../utils/DateFormatter";
import ParkingMeterListReducer from "./ParkingMeterListViewReducer";
import TaskParkTicketList from "../../../ws/park/TaskParkTicketList";
import LoadingView from "../../../base/loading/LoadingView";
import ParkingTicketDetailModal from "../ParkingTicketDetailModal";
import ParkingMeterListViewActions from "./ParkingMeterListViewActions";
import {HistoryViewDataHelpers} from "../HistoryViewData";
import Lodash from "lodash";
import TaskMasterData from "../../../ws/masterdata/TaskMasterData";
import {truncateTwoDecimals} from "../../../utils/StringUtils";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    ParkingMeterListReducer.autoMapToProps(),
    LoginReducer.autoMapToProps(),
    MasterDataReducer.autoMapToProps(),
);

const mapActionsToProps = ParkingMeterListViewActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

interface State {
    showParkingTicketDetailModal: boolean,
}

class ParkingMeterListView extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            showParkingTicketDetailModal: false,
        }
    }

    public componentDidMount(): void {
        new TaskParkTicketList(1).execute();
        if (!this.props.masterData) {
            new TaskMasterData(1).execute()
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (!Lodash.isEqual(this.props.masterData, prevProps.masterData)) {
            new TaskParkTicketList(1).execute();
        }
    }

    private onShowParkingTicketDetailModal = (): void => {
        this.setState({showParkingTicketDetailModal: true})
    };

    private onHideParkingTicketDetailModal = (): void => {
        this.setState({showParkingTicketDetailModal: false})
    };

    public render(): React.ReactNode {
        const {parkTicketLoading, parkTicketList, loading} = this.props;
        if (parkTicketLoading || loading) {
            return <LoadingView loading={true}/>
        }

        return (
            <div className={"transaction-container"}>

                {parkTicketList.length !== 0 ? this.renderParTickets() :
                    <div
                        className={"empty-text"}>{`${I18nUtils.tr(TR_NO_TIENES_NINGUN_TICKET)}.`}
                    </div>}
            </div>
        )
    }

    private getZoneName(zoneId: string): string {
        const {masterData} = this.props;
        const currentZone = masterData && masterData.zones && masterData.zones.find((zone) => zone.id.toString() === zoneId);
        return currentZone ? currentZone.name : "";
    }

    private renderParTickets(): React.ReactNode {
        const {parkTicketList, setCurrentParkTicket, userData} = this.props;

        return (
            <>
                <div className={"list-container"}>

                    {parkTicketList.map((parkTicket, index) =>
                        <div key={index}
                             className={"transaction-list pointer"}
                             onClick={() => {
                                 setCurrentParkTicket(parkTicket);
                                 this.onShowParkingTicketDetailModal()
                             }}
                        >

                            <div className={"transaction"}>
                                <IconBase icon={AppIconSVG.TIME.icon}
                                          className={"icon-time"}/>

                                <div className={"data"}>
                                    <div className={'time-data'}>
                                        {I18nUtils.tr(TR_HORA)} {`${DateFormatter.formatTimestampToTime(parkTicket.start_time, true)}h`}
                                    </div>

                                    <div className={"date-licplate"}>
                                        {parkTicket.start_time !== 0 &&
                                        <span className={"date m-r-15"}>
                                {DateFormatter.formatTimestampToDate(parkTicket.start_time)}</span>}
                                        {parkTicket.vehicle_id && userData && HistoryViewDataHelpers.renderVehicleLicName(parkTicket.vehicle_id, userData)}
                                    </div>
                                    <div className={"font-bold"}>
                                        {parkTicket.zone_id && this.getZoneName(parkTicket.zone_id.toString())}
                                        <br/>
                                        {parkTicket.position_name}
                                    </div>
                                </div>

                                <IconBase icon={AppIconSVG.ARROW_RIGHT.icon}
                                          className={"icon-arrow-right"}
                                />
                            </div>
                        </div>
                    )}

                </div>
                {this.renderParkingTicketDetailModal()}
            </>)
    }


    private renderParkingTicketDetailModal(): React.ReactNode {
        const {currentParkTicket, userData} = this.props;

        const endTime = currentParkTicket && currentParkTicket.end_time ? DateFormatter.formatTimestampToTime(currentParkTicket.end_time, true) : undefined;
        const zone = currentParkTicket && <>{this.getZoneName(currentParkTicket.zone_id.toString())}<br/>{currentParkTicket.position_name}</>;

        return (
            currentParkTicket &&
            <ParkingTicketDetailModal
                startTime={DateFormatter.formatTimestampToTime(currentParkTicket.start_time, true)}
                endTime={endTime}
                startDate={DateFormatter.formatTimestampToDate(currentParkTicket.start_time)}
                paidAmount={truncateTwoDecimals(currentParkTicket.price)}
                licplateWithDescription={userData && HistoryViewDataHelpers.renderVehicleLicName(currentParkTicket.vehicle_id, userData)}
                zone={zone}
                pdfUrl={currentParkTicket.pdf_ticket}
                show={this.state.showParkingTicketDetailModal}
                onClose={this.onHideParkingTicketDetailModal}
                commission={currentParkTicket.commission}
                state={HistoryViewDataHelpers.getState(currentParkTicket.end_time, currentParkTicket.payment_date)}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ParkingMeterListView as unknown as React.ComponentType<{}>);