import * as React from "react";
import UnloggedScreen from "../../base/screens/UnloggedScreen";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_CONTRASENA,
    TR_EMAIL,
    TR_EMAIL_ENVIADO_CORRECTAMENTE,
    TR_INTRODUCE_EMAIL,
    TR_NO_HA_SIDO_POSIBLE_ENVIAR_EL_EMAIL,
    TR_RECUPERAR_CONTRASENA
} from "../../I18n/constants";
import Button from "../../components/Button";
import {ROUTE_PERSONAL_AREA_CONTACT} from "../../routing/Routes";
import AuthManager from "../../utils/AuthManager";
import LoginActions from "./LoginActions";
import LoginReducer from "./LoginReducer";
import {connect} from "react-redux";
import TaskLogin from "../../ws/auth/TaskLogin";
import Alert from "../../base/alerts/Alert";
import {goToRoute} from "../../utils/Router";
import md5 from "md5";
import RecoverPasswordModal from "./RecoverPasswordModal";
import TaskRecoverPassword from "../../ws/auth/TaskRecoverPassword";
import Logo from "../../res/img/logo.png";

const mapActionsToProps = LoginActions.autoMapToProps();
const mapStateToProps = LoginReducer.autoMapToProps();

type Props = typeof mapActionsToProps & typeof mapStateToProps;

interface State {
    showRecoverPwdModal: boolean,
}

class LoginScreen extends UnloggedScreen<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {showRecoverPwdModal: false}
    }

    private handleKeyboard = (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            this.handleLogin();
        }
    };

    private handleLogin = (): void => {
        const {username, password, setError} = this.props;
        new TaskLogin(username, md5(password))
            .onSuccess(() => {
                AuthManager.setAuth(username, md5(password));
                goToRoute(ROUTE_PERSONAL_AREA_CONTACT)
            })
            .onFail((error) => {
                setError(error);
            })
            .execute();
    };

    private handleRecoverPwd = (email: string): void => {
        this.onCloseRecoverPwdModal();
        new TaskRecoverPassword(email)
            .onSuccess(() => {
                Alert.success(I18nUtils.tr(TR_EMAIL_ENVIADO_CORRECTAMENTE))
            })
            .onFail(() => {
                Alert.error(I18nUtils.tr(TR_NO_HA_SIDO_POSIBLE_ENVIAR_EL_EMAIL))
            })
            .execute();
    };

    private onOpenRecoverPwdModal = (): void => {
        this.setState({showRecoverPwdModal: true})
    };

    private onCloseRecoverPwdModal = (): void => {
        this.setState({showRecoverPwdModal: false})
    };

    public renderScreen(): React.ReactNode {
        const {
            loginLoading, username, password, setUserName, setPassword, allowLogin, error, recoverPasswordLoading
        } = this.props;

        return (
            <Card loading={loginLoading || recoverPasswordLoading}>
                <CardBody>
                    <div className={"signin-container"}>

                        <div className={"head"}>
                            <img src={Logo} alt={"logo"}/>
                        </div>

                        <div className={"border-bg"}/>

                        <form className="form-signin">
                            <div className={"form-wrapper"}>
                                <label htmlFor={"email"}>{I18nUtils.tr(TR_EMAIL)}</label>
                                <input type="email"
                                       required={true}
                                       autoFocus={true}
                                       value={username}
                                       onChange={(event) => setUserName(event.target.value)}
                                       placeholder={I18nUtils.tr(TR_INTRODUCE_EMAIL)}
                                       className="form-control"
                                       onKeyPress={this.handleKeyboard}
                                       autoComplete="username"
                                />
                            </div>

                            <div className="form-wrapper m-t-20">
                                <label htmlFor="password">{I18nUtils.tr(TR_CONTRASENA)}</label>
                                <input type="password"
                                       required={true}
                                       value={password}
                                       onChange={(event) => setPassword(event.target.value)}
                                       placeholder={I18nUtils.tr(TR_CONTRASENA)}
                                       className="form-control"
                                       onKeyPress={this.handleKeyboard}
                                       autoComplete="current-password"
                                />
                            </div>

                            {error && <label className={"error"}>{error}</label>}

                            <div className={"form-recover-password m-t-25"}>
                                <span className={"btn-link mx-auto pointer"}
                                      onClick={this.onOpenRecoverPwdModal}
                                >
                                    {I18nUtils.tr(TR_RECUPERAR_CONTRASENA)}
                                </span>
                            </div>

                            <Button
                                className={"btn btn-lg btn-form btn-submit btn-center"}
                                text={I18nUtils.tr(TR_ACEPTAR)}
                                block={true}
                                onClick={this.handleLogin}
                                disabled={!allowLogin}
                            />
                        </form>
                    </div>
                </CardBody>

                <RecoverPasswordModal show={this.state.showRecoverPwdModal}
                                      onClose={this.onCloseRecoverPwdModal}
                                      onSubmit={this.handleRecoverPwd}
                />
            </Card>
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(LoginScreen as any) as React.ComponentType<{}>;