import * as React from "react";
import {RouteComponentProps} from "react-router";
import MenuItem from "./MenuItem";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_CERRAR_SESION, TR_CONTACTA_CON_NOSOTROS, TR_HISTORIAL} from "../../../../I18n/constants";
import {AppIconSVG} from "../../../../components/IconSVG/AppIconSVG";
import onClickOutside from "react-onclickoutside";
import {ROUTE_CONTACT, ROUTE_HISTORY, ROUTE_PERSONAL_AREA_CONTACT} from "../../../../routing/Routes";
import LoginReducer from "../../../../modules/login/LoginReducer";
import {connect} from "react-redux";
import {ReducerBuilder} from "co-redux-builders";
import MasterDataReducer from "../../../../redux/reducers/MasterDataReducer";
import Logo from "../../../../res/img/logo.png";
import CloseSessionModal from "./CloseSessionModal";

interface SideBarProps {
    collapsedHandler: () => void,
}

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    LoginReducer.autoMapToProps(),
    MasterDataReducer.autoMapToProps(),
);

interface State {
    showConfirmCloseModal: boolean,
}

type Props = SideBarProps & RouteComponentProps & typeof mapStateToProps;


class SideBar extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            showConfirmCloseModal: false,
        }
    }

    // @ts-ignore: usado por el HOC outside
    private handleClickOutside = (event: any) => {
        !this.state.showConfirmCloseModal && this.props.collapsedHandler();
    };

    private onShowConfirmCloseModal = (): void => {
        this.setState({showConfirmCloseModal: true});
    };

    private onHideConfirmCloseModal = (): void => {
        this.setState({showConfirmCloseModal: false})
    };

    public render(): React.ReactNode {
        const {userData} = this.props;
        const userImage = userData && userData.icon && userData.icon.length !== 0 ? userData.icon : undefined;

        return (
            <>
                <div className={"row sidebar-content fixed-bar"} id="body-row">

                    <div id="sidebar-container"
                         className={"sidebar-container sidebar-expanded"}>

                        <div className={"logo"}>
                            <img src={Logo} alt={"logo"}/>
                        </div>

                        <ul className={"list-group"}>

                            <MenuItem title={I18nUtils.tr(TR_CONTACTA_CON_NOSOTROS)}
                                      icon={AppIconSVG.TELEPHONE}
                                      route={ROUTE_CONTACT}
                                      collapsed={false}
                                      active={false}
                            />

                            <MenuItem title={userData ? userData.name : ''}
                                      route={ROUTE_PERSONAL_AREA_CONTACT}
                                      collapsed={false}
                                      active={false}
                                      arrowItem={true}
                                      icon={AppIconSVG.PERSON}
                                      userImage={userImage}
                            />

                            {/*<MenuItem title={I18nUtils.tr(TR_VEHICULOS)}*/}
                            {/*          icon={AppIconSVG.CAR}*/}
                            {/*          route={ROUTE_PERSONAL_AREA_VEHICLES}*/}
                            {/*          collapsed={false}*/}
                            {/*          active={false}*/}
                            {/*/>*/}

                            <MenuItem title={I18nUtils.tr(TR_HISTORIAL)}
                                      icon={AppIconSVG.TIME}
                                      route={ROUTE_HISTORY}
                                      collapsed={false}
                                      active={false}
                            />

                            {/*** Deshabilitado */}
                            {/*<MenuItem title={I18nUtils.tr(TR_CONFIGURACION)}*/}
                            {/*          icon={AppIconSVG.SETTINGS}*/}
                            {/*          route={ROUTE_CONFIGURATION}*/}
                            {/*          collapsed={false}*/}
                            {/*          active={false}*/}
                            {/*/>*/}

                            {/*<MenuItem title={I18nUtils.tr(TR_ACERCA_DE)}*/}
                            {/*          icon={AppIconSVG.INFORMATION}*/}
                            {/*          route={''}*/}
                            {/*          collapsed={false}*/}
                            {/*          active={false}*/}
                            {/*/>*/}
                        </ul>

                        <ul className={"list-group sidebar-footer"}>

                            <MenuItem title={I18nUtils.tr(TR_CERRAR_SESION)}
                                      icon={AppIconSVG.CLOSE_SESSION}
                                      collapsed={false}
                                      active={false}
                                      closeSessionItem={true}
                                      onClick={() => {
                                          this.onShowConfirmCloseModal()
                                      }}
                            />

                        </ul>
                    </div>


                </div>

                <CloseSessionModal show={this.state.showConfirmCloseModal}
                                   onClose={this.onHideConfirmCloseModal}/>
            </>
        )
    }
}

export default connect(mapStateToProps)(onClickOutside(SideBar) as React.ComponentType<SideBarProps>);