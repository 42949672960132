import Config from "../Config";
// @ts-ignore
import cookie from 'react-cookies'

export interface Auth {
    username: string,
    password: string,
}

export default class AuthManager {

    private static COOKIE_USERNAME = Config.AUTH_COOKIE_USERNAME;
    private static COOKIE_PASSWORD = Config.AUTH_COOKIE_PASSWORD;
    private static auth?: Auth;

    public static initialize() {
        this.auth = {
            username: cookie.load(this.COOKIE_USERNAME),
            password: cookie.load(this.COOKIE_PASSWORD),
        }
    }

    public static setAuth(username: string | null, password: string | null) {
        if (username !== null && password !== null) {
            this.auth = {
                username,
                password,
            };
        }
        const cookieOptions: {path: string, maxAge?: number} = { path: '/' };
        cookie.save(this.COOKIE_USERNAME, username, cookieOptions);
        cookie.save(this.COOKIE_PASSWORD, password, cookieOptions);
    }

    public static getAuth(): Auth | undefined {
        return this.auth;
    }

    public static login(username: string, password: string) {
        this.setAuth(username, password);
    }

    public static logout() {
        this.setAuth(null, null); //setAuth pasa los valores a string, por tanto "null" es un string
    }

    public static isLogged(): boolean {
        if (this.auth) {
            const existAuthData = !!this.auth.username && !!this.auth.password;
            if (existAuthData) {
                return this.auth.username !== "null" || this.auth.password !== "null";
            }
            return false;
        }
        return false;
    }
}