import * as React from "react"
import BaseModal, {BaseModalProps} from "../../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import ModalHeaderTitle from "../../../components/modal/ModalHeaderTitle";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_SELECCIONA_UNA_IMAGEN,
    TR_SOLO_SE_ADMITEN_ARCHIVOS_DE_IMAGEN,
    TR_VNG_APARCAMENTS
} from "../../../I18n/constants";
import TaskUpdateUserIcon from "../../../ws/auth/TaskUpdateUserIcon";
import CardOkCancelFooter from "../../../components/card/CardOkCancelFooter";
import TaskLogin from "../../../ws/auth/TaskLogin";
import AuthManager from "../../../utils/AuthManager";

interface ChangeUserImageModalProps extends BaseModalProps {
}

type Props = ChangeUserImageModalProps;

interface State {
    userImage?: string,
    showFileError?: boolean,
}

const IMAGE_EXTENSIONS = ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/svg"];

export default class ChangeUserImageModal extends BaseModal<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {userImage: undefined, showFileError: false};
    }

    private onSubmit = (): void => {
        const {userImage} = this.state;
        const userImageName = userImage ? userImage : undefined;
        if (!!userImageName) {
            new TaskUpdateUserIcon(userImageName)
                .onSuccess((response) => {
                    const authData = AuthManager.getAuth();
                    if (!!authData) {
                        new TaskLogin(authData.username, authData.password).execute()
                    }
                    this.props.onClose();
                })
                .execute();
        }
    };

    private imageToBase64(file: any): void {
        if (this.acceptedImage(file)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                file = reader.result;
                this.setState({userImage: file, showFileError: false});
            };
        } else {
            this.setState({userImage: undefined, showFileError: true});
        }
    }

    private acceptedImage(file: File): boolean {
        const fileExtension = file.type;
        return IMAGE_EXTENSIONS.includes(fileExtension);
    }

    public render(): React.ReactNode {
        const {onClose, show} = this.props;
        const {showFileError} = this.state;

        return (
            <Modal onHide={onClose} show={show} size={"lg"}>
                <ModalHeaderTitle title={I18nUtils.tr(TR_VNG_APARCAMENTS)}
                                  classNameTitle={`modal-subtitle p-b-0"}`}
                                  showCloseButton={false}
                />

                <Modal.Body className={"p-b-45"}>
                    <div className={"input-file"}>
                        <label htmlFor={"user-image"} className={"label-file"}>
                            {`${I18nUtils.tr(TR_SELECCIONA_UNA_IMAGEN)}:`}
                        </label>
                        <br/>
                        <input type={"file"}
                               id={"user-image"}
                               name={"user-image"}
                               className={"form-control-file"}
                               accept={IMAGE_EXTENSIONS.join(',')}
                               onChange={(event) => event.target.files && event.target.files.length !== 0 && this.imageToBase64(event.target.files[0])}
                               multiple={false}
                               value={undefined}
                        />
                        {showFileError && <label className={"error m-t-15"}>{`${I18nUtils.tr(TR_SOLO_SE_ADMITEN_ARCHIVOS_DE_IMAGEN)}.`}</label>}
                    </div>
                </Modal.Body>
                <div className={"m-25"}>
                    <CardOkCancelFooter okTitle={I18nUtils.tr(TR_ACEPTAR)}
                                        okHandler={this.onSubmit}
                                        cancelHandler={onClose}
                                        disableOk={!this.state.userImage}
                                        classNameButton={"btn-slim"}
                    />
                </div>
            </Modal>
        )
    }

}