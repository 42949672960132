import * as React from "react";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Row from "../../../components/Row";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_APELLIDOS,
    TR_APELLIDOS_OBLIGATORIO,
    TR_CONTRASENA,
    TR_CONTRASENA_OBLIGATORIA,
    TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO,
    TR_EMAIL,
    TR_EMAIL_OBLIGATORIO,
    TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO,
    TR_GUARDAR,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_TELEFONO,
    TR_TELEFONO_OBLIGATORIO
} from "../../../I18n/constants";
import FormInput from "../../../components/form/FormInput";
import Button from "../../../components/Button";
import {isEmailValid} from "../../../utils/StringUtils";
import {AppIconSVG} from "../../../components/IconSVG/AppIconSVG";

const FORM_NAME = "userForm";

export interface UserFormData {
    name: string,
    last_name: string,
    username: string, //email
    // dni: string,
    phone: string,
    password: string,
}

enum UserFormFields {
    NAME = "name",
    LAST_NAME = "last_name",
    USERNAME = "username", // email
    // DNI = "dni",
    TELEPHONE = "phone",
    PASSWORD = "password",
}

interface UserFormProps {
    onSubmit: (data: UserFormData) => void,
    initialValues?: Partial<UserFormData>,
    parentLoading: boolean,
    lineMode?: boolean,
}

type Props = InjectedFormProps<UserFormData> & UserFormProps;

class UserForm extends React.Component<Props> {

    public render(): React.ReactNode {
        const {handleSubmit, onSubmit, invalid, pristine, initialValues, lineMode = true} = this.props;

        return (
            <form className="form-signin"
                  id={"form-signin"}
                  onSubmit={handleSubmit(onSubmit)}>
                <Row className={"m-t-30"}>
                    <Field
                        type={"text"}
                        name={UserFormFields.NAME}
                        component={FormInput}
                        lineMode={lineMode}
                        placeholder={I18nUtils.tr(TR_NOMBRE)}
                        icon={lineMode && lineMode && AppIconSVG.PERSON}
                        label={!lineMode && I18nUtils.tr(TR_NOMBRE)}
                    />
                </Row>
                <Row className={"m-t-30"}>
                    <Field
                        type={"text"}
                        name={UserFormFields.LAST_NAME}
                        component={FormInput}
                        lineMode={lineMode}
                        placeholder={I18nUtils.tr(TR_APELLIDOS)}
                        icon={lineMode && AppIconSVG.PERSON}
                        label={!lineMode && I18nUtils.tr(TR_APELLIDOS)}
                    />
                </Row>
                {/*<Row className={"m-t-30"}>*/}
                {/*    <Field*/}
                {/*        type={"text"}*/}
                {/*        name={UserFormFields.DNI}*/}
                {/*        component={FormInput}*/}
                {/*        lineMode={lineMode}*/}
                {/*        placeholder={I18nUtils.tr(TR_DNI)}*/}
                {/*        icon={lineMode && AppIconSVG.DNI}*/}
                {/*        iconBaseProps={AppIconSVG.DNI.iconProps}*/}
                {/*        iconClassName={"icon-dni"}*/}
                {/*        label={!lineMode && I18nUtils.tr(TR_DNI)}*/}
                {/*    />*/}
                {/*</Row>*/}
                <Row className={"m-t-30"}>
                    <Field
                        type={"tel"}
                        name={UserFormFields.TELEPHONE}
                        component={FormInput}
                        lineMode={lineMode}
                        placeholder={I18nUtils.tr(TR_TELEFONO)}
                        icon={lineMode && AppIconSVG.TELEPHONE}
                        iconClassName={"icon-phone"}
                        label={!lineMode && I18nUtils.tr(TR_TELEFONO)}
                    />
                </Row>
                <Row className={"m-t-30"}>
                    <Field
                        type={"email"}
                        name={UserFormFields.USERNAME}
                        placeholder={I18nUtils.tr(TR_EMAIL)}
                        component={FormInput}
                        lineMode={lineMode}
                        disabled={!!initialValues}
                        icon={lineMode && AppIconSVG.MAIL}
                        iconClassName={"icon-mail"}
                        label={!lineMode && I18nUtils.tr(TR_EMAIL)}
                    />
                </Row>
                {!initialValues &&
                <Row className={"m-t-30"}>
                    <Field
                        type={"password"}
                        name={UserFormFields.PASSWORD}
                        placeholder={I18nUtils.tr(TR_CONTRASENA)}
                        lineMode={lineMode}
                        component={FormInput}
                        icon={lineMode && AppIconSVG.PASSWORD}
                        label={!lineMode && I18nUtils.tr(TR_CONTRASENA)}
                    />
                </Row>}

                <Button
                    className={"btn btn-lg btn-form btn-submit btn-center m-t-60"}
                    text={I18nUtils.tr(initialValues ? TR_GUARDAR : TR_ACEPTAR)}
                    block={true}
                    type={"submit"}
                    disabled={invalid || pristine}
                />
            </form>

        )
    }
}

function validate(values: UserFormData, props: UserFormProps) {
    const errors: FormErrors<UserFormData> = {};
    const editMode = !!props.initialValues;

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO)
    }
    if (!values.last_name || values.last_name.length === 0) {
        errors.last_name = I18nUtils.tr(TR_APELLIDOS_OBLIGATORIO)
    }
    // if (!values.dni || values.dni.length === 0) {
    //     errors.dni = I18nUtils.tr(TR_DNI_OBLIGATORIO)
    // } else if (!isNifNiePersonValid(values.dni)) {
    //     errors.dni = I18nUtils.tr(TR_DNI_INVALIDO)
    // }
    if (!editMode) {
        if (!values.password || values.password.length === 0) {
            errors.password = I18nUtils.tr(TR_CONTRASENA_OBLIGATORIA)
        }
    }
    if (!values.username || values.username.length === 0) {
        errors.username = I18nUtils.tr(TR_EMAIL_OBLIGATORIO)
    } else if (!isEmailValid(values.username)) {
        errors.username = I18nUtils.tr(TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO)
    }
    if (!values.phone || values.phone.length === 0) {
        errors.phone = I18nUtils.tr(TR_TELEFONO_OBLIGATORIO)
    } else if (values.phone.length !== 9 || !/^\d+$/.test(values.phone)) {
        errors.phone = I18nUtils.tr(TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO)
    }

    return errors;
}

export default reduxForm<UserFormData, UserFormProps>({
    validate,
    form: FORM_NAME,
    enableReinitialize: true
})(UserForm as any) as unknown as React.ComponentType<UserFormProps>;