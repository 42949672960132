import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import LegalStatuses from "../../model/LegalStatuses";

export default class TaskRetrieveLegalStatuses extends BaseGenericRequest<{}, TaskResponse<LegalStatuses[]>> {

    public constructor() {
        super(METHOD.METHOD_POST, Urls.URL_LEGAL_STATUSES_LIST);
    }
}