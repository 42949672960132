import moment from 'moment';
import I18nUtils from "../I18n/I18nUtils";
// import {TR_AYER, TR_HOY} from "../I18n/constants";

export default class DateFormatter {

    public static formatDate(date: Date | string): string {
        if (date) {
            return moment(date).format('DD/MM/YYYY');
        }
        return '';
    }

    public static formatHourTodayDate(date: Date): string {
        if (date) {
            if (DateUtils.isToday(date)) {
                return moment(date).format('HH:mm');
            }
            return moment(date).format('DD/MM/YYYY');
        }
        return '';
    }

    public static formatDay(date: Date | string): string {
        if (date) {
            moment.locale(I18nUtils.getLocale());
            return moment(date).format('dddd');
        }
        return '';
    }

    public static formatDatetime(date: Date | string): string {
        if (date) {
            return moment(date).format('HH:mm DD/MM/YYYY');
        }
        return '';
    }

    public static formatTime(date: Date | string): string {
        if (date) {
            return moment(date).format('HH:mm');
        }
        return '';
    }

    public static formatInputDate(date?: Date): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }
        return '';
    }

    public static formatStringToDate(dateString: string): string {
        if (!dateString) {
            return ''
        }
        if (dateString.indexOf('/') !== -1) {
            const dateSplit = dateString.split('/');
            return [dateSplit[2], dateSplit[1], dateSplit[0]].join('-');
        } else {
            return dateString;
        }
    }

    public static formatTimestampToTime(timestamp: number, hideSeconds?: boolean): string {
        if (timestamp === 0) {
            return !hideSeconds ? "00:00:00" : "00:00";
        }
        const date = new Date(timestamp * 1000);
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();
        const seconds = "0" + date.getSeconds();
        return !hideSeconds ?
            hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) :
            hours + ':' + minutes.substr(-2);
    }

    public static formatTimestampToDate(timestamp: number): string {
        if (timestamp === 0) {
            return "";
        }
        return moment.unix(timestamp).format("DD/MM/YYYY");
    }

}

export class DateHelpers {

    public static getPreviousMonday(date: Date): Date {
        const mondayIndex = 1;
        return moment(date).isoWeekday(mondayIndex).toDate();
    }

    public static getXDaysAgo(days: number, date: Date = new Date()): Date {
        return moment(date).subtract(days, 'days').toDate();
    }

    public static getXMonthsAgo(months: number, date: Date = new Date()): Date {
        return moment(date).subtract(months, 'months').toDate();
    }

    public static getXYearsAgo(years: number, date: Date = new Date()): Date {
        return moment(date).subtract(years, 'years').toDate();
    }

    public static getXWeeksAgo(weeks: number, date: Date = new Date()): Date {
        return moment(date).subtract(weeks, 'weeks').toDate();
    }

    public static getFirstDayOfYear(year: number): Date {
        return new Date(year, 0, 1);
    }

    public static getLastDayOfYear(year: number): Date {
        return new Date(year, 11, 31);
    }

    public static getFirstDayOfTrimester(year: number, trimester: 1 | 2 | 3 | 4): Date {
        const month = trimester * 3 - 3;
        return new Date(year, month, 1);
    }

    public static getLastDayOfTrimester(year: number, trimester: 1 | 2 | 3 | 4): Date {
        const month = trimester * 3;
        return new Date(year, month, 0);
    }

    public static getFirstSecond(date: Date): Date {
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }

    public static getLastSecond(date: Date): Date {
        date.setUTCHours(23, 59, 59, 999);
        return date;
    }
}

export class DateUtils {

    // public static getDate(date: Date): string {
    //     if (DateUtils.isToday(date)) {
    //         return I18nUtils.tr(TR_HOY);
    //     } else {
    //         if (DateUtils.isYesterday(date)) {
    //             return I18nUtils.tr(TR_AYER);
    //         } else {
    //             return DateFormatter.formatDate(date);
    //         }
    //     }
    // }

    public static isToday(date: Date): boolean {
        return moment(date).isSame(moment(), 'day');
    }

    public static getYesterday(): Date {
        const today = new Date();
        // @ts-ignore
        return today.setDate(today.getDate() - 1);
    }

    public static isYesterday(date: Date): boolean {
        return moment(date).isSame(this.getYesterday(), 'day');
    }

    public static isSameDay(firstDate: Date, secondDate: Date): boolean {
        return moment(firstDate).isSame(secondDate, 'day');
    }

    public static getCurrentDate(): Date {
        const today = new Date();
        today.setDate(today.getDate());
        // @ts-ignore
        return moment(today).utc().format();
    }

    public static parseStringToDate(date: string): Date {
        // @ts-ignore
        return moment(date).utc().format();
    }

    public static isGreaterThan(firstDate: Date, secondDate: Date): boolean {
        return firstDate > secondDate
    }

}