import * as React from 'react';
import {Component, ReactNode} from 'react';
import { default as AlertView} from 'react-s-alert';

export default class Alert extends Component {

    public static info(message: string){
        AlertView.info(message);
    }

    public static success(message: string){
        AlertView.success(message);
    }

    public static warning(message: string){
        AlertView.warning(message);
    }

    public static error(message: string){
        AlertView.error(message);
    }

    public static functionNoAvailable(){
        AlertView.warning('Funcionalidad no disponible');
    }

    public render(): ReactNode {
        return (
            <div>
                <AlertView
                    stack={{limit: 5}}
                    position={'top-right'}
                    effect={'genie'}
                    timeout={10000}
                    offset={60}
                />
            </div>
        );
    }

}

