import * as React from "react";
import LoggedScreen from "../../base/screens/LoggedScreen";
import CardBody from "../../components/card/CardBody";
import {ROUTE_CONTACT, ROUTE_CONTACT_SUGGESTION} from "../../routing/Routes";
import IconBase from "../../components/IconSVG/IconBase";
import {AppIconSVG} from "../../components/IconSVG/AppIconSVG";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_FORMULARIO_DE_CONTACTO, TR_WEB} from "../../I18n/constants";
import MasterDataReducer from "../../redux/reducers/MasterDataReducer";
import {connect} from "react-redux";
import {goToRoute} from "../../utils/Router";
import Logo from "../../res/img/logo.png";
import Config from "../../Config";
import TaskMasterData from "../../ws/masterdata/TaskMasterData";
import LoadingView from "../../base/loading/LoadingView";

const mapStateToProps = MasterDataReducer.autoMapToProps();

type Props = typeof mapStateToProps;

class ContactScreen extends LoggedScreen<Props> {

    public constructor(props: Props, context: any) {
        super(props, context);
        this.setNavbarTitleFromRouteNameScreen(ROUTE_CONTACT)
    }
    
    public componentDidMount(): void {
        if (!this.props.masterData) {
            new TaskMasterData(1).execute();
        }
    }

    public renderScreen(): React.ReactNode {
        const {cities, loading} = this.props;
        const currentCity = cities.length !== 0 && cities[0];
        const telephone = currentCity ? currentCity.phone_number : "";
        const address = currentCity ? currentCity.address : "";

        if (loading) {
            return <LoadingView loading={true}/>
        }
        
        return (
            <CardBody>
                <div className={"signin-container"}>

                    <div className={"head"}>
                        <img src={Logo} alt={"logo"}/>
                    </div>

                    <div className={"border-bg"}/>

                    <div className={"contact-info"}>
                        {telephone && <div className={"info-item"}>
                            <div className={"icon-container"}>
                                <IconBase icon={AppIconSVG.TELEPHONE.icon}
                                          className={"icon telephone"}
                                />
                            </div>
                            <div className={"text"}>{telephone}</div>
                        </div>}

                        <div className={"info-item"}>
                            <div className={"icon-container"}>
                                <IconBase icon={AppIconSVG.CONTACT_FORM.icon}
                                          className={"icon form"}/>
                            </div>
                            <div className={"text pointer"}
                                 onClick={() => goToRoute(ROUTE_CONTACT_SUGGESTION)}
                            >{I18nUtils.tr(TR_FORMULARIO_DE_CONTACTO)}</div>
                        </div>

                        {address && <div className={"info-item"}>
                            <div className={"icon-container"}>
                                <IconBase icon={AppIconSVG.LOCATION.icon}
                                          iconBaseProps={AppIconSVG.LOCATION.iconProps}
                                          className={"icon location"}/>
                            </div>
                            <div className={"text"}>{address}</div>
                        </div>}
                    </div>

                    <div className={"contact-footer"}>
                        <div className={"social-networks-contact"}>
                            <div className={"social-container"}>
                                <span className={"social"}>
                                    <a href={Config.SVG_WEB_URL}>
                                        <IconBase icon={AppIconSVG.WEB.icon} className={"icon-social"}/>
                                        <p className={"social-name"}>{I18nUtils.tr(TR_WEB)}</p>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        )
    }
}

export default connect(mapStateToProps)(ContactScreen as unknown as React.ComponentType<{}>);