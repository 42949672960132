export default class NavbarTitleManager {

    private title: string;

    constructor() {
        this.title = "";
    }

    public setTitleFromRouteName(newTitle: string): void {
        this.title = newTitle;
    }

    public getTitle(): string {
        return this.title;
    }
}