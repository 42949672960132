import * as React from "react";
import {SyntheticEvent} from "react";

interface CheckboxProps {
    onChange: (event: any) => void,
    onClick?: (event: any) => void,
    checked?: boolean,
    className?: string,
    disabled?: boolean,
    // label?: string,
    label?: any,
    name?: string,
}

export default class Checkbox extends React.Component<CheckboxProps> {

    public render(): React.ReactNode {
        const {checked, onChange, className, disabled, label, name} = this.props;
        const cursorClass = disabled ? "disable-cursor" : "";

        return (
            <span className={`${label ? "display-inline-flex m-t-20" : ""}`}>
                <label className={`row-checkbox ${className} ${cursorClass}`}>
                    <input
                        name={name}
                        type={"checkbox"}
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                        onClick={(event: SyntheticEvent<HTMLInputElement>) => event.stopPropagation()}
                    />
                    <span className={`row-input-checkbox ${cursorClass}`}
                          onClick={(event) => event.stopPropagation()}
                    />
                </label>
                {label && onChange ?
                    <label className={"label-checkbox"}
                           onClick={() => onChange(checked)}
                    >
                        {label}
                    </label> : null}
            </span>
        )
    }
}