import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Auth} from "../../utils/AuthManager";
import Invoice from "../../model/Invoice";

type CustomCriteriaParkTicket = {
    start_date: number,
    end_date: number,
}

interface TaskParkTicketRequest extends Auth {
    criteria?: CustomCriteriaParkTicket
}


export default class TaskRetrieveInvoices extends BaseGenericRequest<TaskParkTicketRequest, TaskResponse<Invoice[]>> {

    private readonly data?: CustomCriteriaParkTicket;

    public constructor(customCriteriaParkTicket?: CustomCriteriaParkTicket) {
        super(METHOD.METHOD_POST, Urls.URL_INVOICE_LIST);
        this.data = customCriteriaParkTicket
    }

    protected getRequest(): TaskParkTicketRequest {
        return {
            ...this.getAuth() as Auth,
            criteria: this.data ? this.data : undefined,
        };
    }
}