import * as React from "react";
import NavBar from "./NavBar";
import SideBar from "./sideBar/SideBar";
import Card from "../../../components/card/Card";

interface Props {
    navbarTitle: string,
}

interface State {
    collapsed: boolean,
}

export default class MenuWrapper extends React.Component<Props, State> {

    private readonly mql: any;

    public constructor(props: Props) {
        super(props);
        this.mql = window.matchMedia(`(max-width: 758px)`);

        this.state = {
            collapsed: true,
        }
    }

    public UNSAFE_componentWillMount(): void {
        this.mql.addEventListener('MediaQueryList', this.mediaQueryChanged)
    }

    private mediaQueryChanged = (): void => {
        if (this.mql.matches) {
            this.setState({collapsed: true})
        } else {
            this.setState({collapsed: false})
        }
    };

    private handleCollapsedMenu = (): void => {
        this.setState({collapsed: !this.state.collapsed});
    };

    public render(): React.ReactNode {
        const {children, navbarTitle} = this.props;
        const menuExpanded = !this.state.collapsed;

        return (
            <Card>
                <div className={`${menuExpanded && "disabled-screen"}`}>
                    <NavBar sideBarCollapsedHandler={this.handleCollapsedMenu}
                            navbarTitle={navbarTitle}
                    />
                    {children}
                </div>
                {menuExpanded && <SideBar collapsedHandler={this.handleCollapsedMenu}/>}
            </Card>
        )
    }

}
