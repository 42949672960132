import * as React from "react";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_HAS_CREADO_TU_USUARIO_CON_EXITO_AHORA_PUEDES_DESCARGAR_LA_APP, TR_REGISTRO_EXCLUSIVO_PARA_COMERCIOS_Y_NEGOCIOS,
    TR_SI_YA_TENIAS_LA_APP_DESCARGADA_VUELVE_A_INICIAR_SESION_EN_LA_APP_PARA_ACTUALIZAR_TU_CUENTA
} from "../../I18n/constants";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import Logo from "../../res/img/logo.png";
import AppStore from "../../res/img/app-store.png";
import GooglePlay from "../../res/img/google-play.png";

export default class RegisterMerchantSuccessScreen extends React.Component {

    public render(): React.ReactNode {
        return (
            <Card>
                <CardBody>
                    <div className={"signin-container"}>

                        <div className={"head"}>
                            <div className={"head-img"}><img src={Logo} alt={"logo"}/></div>
                            <div className={'title'}>{I18nUtils.tr(TR_REGISTRO_EXCLUSIVO_PARA_COMERCIOS_Y_NEGOCIOS).toUpperCase()}</div>
                        </div>

                        <div className={"border-bg"}/>

                        <div className={"register-success-container"}>
                            <div
                                className={"success-message"}>{`${I18nUtils.tr(TR_HAS_CREADO_TU_USUARIO_CON_EXITO_AHORA_PUEDES_DESCARGAR_LA_APP)}.`}</div>
                            <div className={"app-icons"}>
                                <a href={"https://play.google.com/store/apps/details?id=com.vilanova.app"}>
                                    <img src={GooglePlay}
                                         alt={"google-play"}
                                         className={"cursor"}
                                    />
                                </a>
                                <a href={"https://apps.apple.com/es/app/id1511535550"}>
                                    <img src={AppStore}
                                         alt={"app-store"}
                                         className={"cursor"}
                                    />
                                </a>
                            </div>
                            <div className={"login-message"}>
                                {`${I18nUtils.tr(TR_SI_YA_TENIAS_LA_APP_DESCARGADA_VUELVE_A_INICIAR_SESION_EN_LA_APP_PARA_ACTUALIZAR_TU_CUENTA)}.`}
                            </div>
                        </div>
                    </div>

                </CardBody>

            </Card>
        )
    }
}