import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import TaskResponse from "../TaskResponse";
import BaseGenericRequest from "../BaseGenericRequest";
import User from "../../model/User";
import AuthRequest from "../AuthRequest";

export default class TaskLogin extends BaseGenericRequest<AuthRequest, TaskResponse<User>> {

    private readonly username: string;
    private readonly password: string;

    public constructor(username: string, password: string) {
        super(METHOD.METHOD_POST, Urls.URL_LOGIN);
        this.username = username;
        this.password = password;
    }

    protected getRequest(): AuthRequest {
        return {
            username: this.username,
            password: this.password,
        }
    }
}