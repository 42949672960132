import * as React from 'react';
import {Modal} from "react-bootstrap";
import AuthManager from "../../../../utils/AuthManager";
import ModalHeaderTitle from "../../../../components/modal/ModalHeaderTitle";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_CERRAR_SESION, TR_SEGURO_QUE_DESEAS_CERRAR_SESION} from "../../../../I18n/constants";
import CardOkCancelFooter from "../../../../components/card/CardOkCancelFooter";
import BaseModal, {BaseModalProps} from "../../../modal/BaseModal";

interface CloseSessionModalProps extends BaseModalProps {
    show: boolean,
    onClose: () => void,
}

export default class CloseSessionModal extends BaseModal<CloseSessionModalProps> {

    private onCloseSessionModal = (): void => {
        AuthManager.logout();
        window.location.reload();
    };

    public render(): React.ReactNode {
        const {onClose, show} = this.props;
        const contentSize = 'lg';

        return (
            <Modal onHide={onClose} show={show} size={contentSize}>
                <ModalHeaderTitle title={I18nUtils.tr(TR_CERRAR_SESION)}
                                  classNameTitle={"modal-subtitlep-b-0"}
                                  showCloseButton={true}
                />

                <Modal.Body>
                    <div className={"m-b-40"}>
                        {I18nUtils.tr(TR_SEGURO_QUE_DESEAS_CERRAR_SESION)}
                    </div>
                </Modal.Body>

                <Modal.Footer className={"footer-terms m-t-40"}>
                    <CardOkCancelFooter okTitle={I18nUtils.tr(TR_CERRAR_SESION)}
                                        okHandler={this.onCloseSessionModal}
                                        cancelHandler={onClose}
                                        disableOk={false}
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}