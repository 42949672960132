import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
// import classNames from 'classnames';
import Icon from "../icon/Icon";
import {AppIcon} from "../../Config";
import {OverlayTrigger, Popover} from "react-bootstrap";
import IconBase from "../IconSVG/IconBase";
import {IconSVG} from "../IconSVG/AppIconSVG";

export interface FormInputProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    type: 'text' | 'number' | 'date' | 'datetime' | 'color' | 'datetime-local' | 'email' | 'month' | 'password' | 'tel' | 'time' | 'url' | 'week';
    disabled?: boolean;
    className?: string;
    col: FormColProps
    dependentFieldsValues?: any[],
    labelClassName?: string,
    showVerifyIcon?: string,
    forceInvalid?: boolean,
    classNameFormCol?: string,
    minNumberValue?: string,
    maxNumberValue?: string,
    value?: any,
    defaultValue?: any,
    forceErrorMsg?: string,
    restrictTwoDecimals?: boolean,
    onFocusOut?: (event: HTMLInputElement) => void,
    step?: any,
    labelTextHelp?: string,
    toUppercase?: boolean,
    required?: boolean,
    lineMode?: boolean,
    errorClassName?: string,
    maxLength?: string,
    existErrorHandler?: (existError: boolean) => void,
    icon?: IconSVG,
    iconClassName?: string,
}

export default class FormInput extends Component<FormInputProps> {

    public componentDidUpdate(prevProps: Readonly<FormInputProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.meta.error !== prevProps.meta.error && !!this.props.existErrorHandler) {
            if (this.props.meta.touched && !!this.props.meta.error && this.props.meta.error.length !== 0) {
                this.props.existErrorHandler(true);
            } else {
                this.props.existErrorHandler(false);
            }
        }
    }

    public render(): ReactNode {
        const {
            input, meta, label, placeholder, type, disabled, col, className, labelClassName, dependentFieldsValues = [],
            showVerifyIcon = false, forceInvalid, classNameFormCol, minNumberValue, value, defaultValue, forceErrorMsg,
            restrictTwoDecimals = false, onFocusOut, step, labelTextHelp, toUppercase = false, required = false,
            maxNumberValue, lineMode = false, errorClassName, maxLength, name, icon, iconClassName,
        } = this.props;

        let dependentFieldsHasValidValues = !(dependentFieldsValues.length === 0);

        dependentFieldsValues.forEach((value: any) => {
            if (!value) {
                dependentFieldsHasValidValues = false;
            }
        });

        let moreProps: any = restrictTwoDecimals ? {step: ".01"} : {};
        moreProps = onFocusOut ? {...moreProps, onBlur: onFocusOut} : moreProps;
        moreProps = toUppercase ? {...moreProps, style: {textTransform: "uppercase"}} : moreProps;

        const isInvalidIcon = meta.invalid || forceInvalid;
        return (
            <FormCol {...col} className={`m-b-0 p-0 ${classNameFormCol}`}>
                <div className={`${lineMode ? "" : 'form-wrapper'}`}>
                    {label &&
                    <label className={`${labelClassName} ${lineMode ? "label-line" : ""}`}>
                        {label || ''}
                        {required && <span className={"required-field"}>{` *`}</span>}
                        {labelTextHelp && this.renderTooltip(labelTextHelp)}
                    </label>}

                    <div className={`${icon ? "input-icon" : ""}`}>
                        {icon && <IconBase icon={icon.icon} iconBaseProps={icon.iconProps} className={`icon ${iconClassName}`}/>}

                        <input type={type}
                               name={name}
                               className={`form-control ${lineMode ? "input-line" : ""} ${className}`}
                               placeholder={placeholder}
                               min={minNumberValue}
                               max={maxNumberValue}
                               value={value}
                               defaultValue={defaultValue}
                               step={step}
                               maxLength={maxLength}
                               {...input}
                               disabled={disabled || false}
                               {...moreProps}
                        />
                        {showVerifyIcon && <Icon icon={`fas ${isInvalidIcon ? 'fa-times' : 'fa-check'}`}
                                                 className={isInvalidIcon ?
                                                     "danger-color" :
                                                     "success-color"
                                                 }
                        />}
                    </div>
                    {forceErrorMsg ? <label className={"error"}>{forceErrorMsg}</label> :
                        <label className={`${errorClassName ? `error ${errorClassName}` : "error"}`}>
                            {(meta.touched || dependentFieldsHasValidValues) ? meta.error : ""}</label>}
                </div>
            </FormCol>
        );
    }

    private renderTooltip(text?: React.ReactNode | string): React.ReactNode {
        const tooltip: React.ReactNode = (
            <Popover id={Math.random()}>
                {text}
            </Popover>);

        return (
            <OverlayTrigger overlay={tooltip} trigger={'hover'} placement={'auto'}>
                <i className={`${AppIcon.INFORMATION} m-l-10`}/>
            </OverlayTrigger>
        )
    }

}

