import * as React from 'react';
import {ChangeEventHandler, Component, FocusEventHandler, ReactNode} from 'react';
import FormInputOption from "./FormInputOption";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_TODOS} from "../../I18n/constants";

export interface SelectProps {
    label?: string,
    value: string,
    options: FormInputOption[],
    name?: string,
    error?: string,
    disabled?: boolean,
    onChange?: ChangeEventHandler<HTMLSelectElement>,
    onFocus?: FocusEventHandler<HTMLSelectElement>,
    onBlur?: FocusEventHandler<HTMLSelectElement>,
    classFormGroup?: string,
    classFormLine?: string,
    classSelect?: string,
    emptyOption?: boolean,
    labelClassName?: string,
    className?: string,
    allSelectedOption?: boolean,
    placeholder?: string,
    required?: boolean,
    formLine?: boolean,
}

export default class Select extends Component<SelectProps> {

    public render(): ReactNode {
        const {label, formLine = true} = this.props;

        return (
            <>
                {label &&
                <label className={this.props.labelClassName}>
                    {label} {this.props.required && <span className={"required-field"}>{` *`}</span>}
                </label>}
                <select
                    className={`form-control ${formLine ? " line-select" : " select-no-line"}`}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                >
                    {this.props.emptyOption && <option key={0} value={'-1'}/>}
                    {this.props.placeholder && <option key={-1} value={'-1'} disabled>
                        {this.props.placeholder}
                    </option>}
                    {this.props.allSelectedOption && <option key={-2} value={'-1'}>
                        {I18nUtils.tr(TR_TODOS)}
                    </option>}
                    {this.props.options.map((option: FormInputOption) => <option key={option.value}
                                                                                 value={option.value}>{option.name}</option>)}
                </select>

                {this.props.error ? <label className="error">{this.props.error || ''}</label> : null}

            </>
        );
    }

}

