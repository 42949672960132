import {ReducerBuilder} from "co-redux-builders";
import AuthActions from "./LoginActions";
import TaskLogin from "../../ws/auth/TaskLogin";
import User from "../../model/User";
import TaskRecoverPassword from "../../ws/auth/TaskRecoverPassword";

interface State {
    loginLoading: boolean
    allowLogin: boolean,
    error: string,
    username: string,
    password: string,
    userData?: User,
    recoverPasswordLoading: boolean,
}

const INITIAL_STATE: State = {
    username: '',
    password: '',
    loginLoading: false,
    allowLogin: false,
    error: '',
    userData: undefined,
    recoverPasswordLoading: false,
};

const buildState = (state: any) => {
    return ({...state, allowLogin: state.username.length > 0 && state.password.length > 0, error: ''});
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loginLoading: true}
    })
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loginLoading: false}
    })
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, userData: payload.data}
    })

    .onEvent(TaskRecoverPassword.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, recoverPasswordLoading: true}
    })
    .onEvent(TaskRecoverPassword.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, recoverPasswordLoading: false}
    })

    .onEvent(AuthActions.setUserName, (oldState, payload) => buildState({
        ...oldState,
        username: payload.username || ''
    }))
    .onEvent(AuthActions.setPassword, (oldState, payload) => buildState({
        ...oldState,
        password: payload.password || ''
    }))
    .onEvent(AuthActions.setError, (oldState, payload) => ({...oldState, error: payload.error}))

    .build();
