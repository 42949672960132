/* eslint-disable */

export function isEmailValid(email: string): boolean {

    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export function Int(value: any): number {
    return parseInt(String(value || -1), 10);
}

export function isIPValid(ip: string): boolean {
    return /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/.test(ip);
}

export function truncate(text: string, length: number = 15, delimiter: string = '...'): string {
    if (text.split(" ").length > length) {
        return text.split(" ").splice(0, length).join(" ") + delimiter;
    }
    return text;
}

export function contains(text: string, substring: string): boolean {
    return cleanText(text).includes(cleanText(substring));
}

function cleanText(text: string): string {
    const normalized = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    return normalized.toLowerCase();
}

export function isNifNiePersonValid(nif: string) {

    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = nif.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return false;
    }

    const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    return validChars.charAt(charIndex) === letter;
}

export function isNifCompanyValid(nif: string) {
    // Q 28 26004 J -> Letra - Provincia - Numeros - Letra/Numero
    const validChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'N', 'P', 'Q', 'R', 'S', 'U', 'V', 'W'];
    const str = nif.toString().toUpperCase();

    const firstLetter = str.substr(0, 1);
    if (!validChars.includes(firstLetter)) {
        return false;
    }

    const digits = str.substr(1, 7);
    let sumA = 0;
    let sumB = 0;
    for (let index = 0; index < digits.length; index++) {
        if (index % 2 != 0) {
            sumA += Int(digits.charAt(index));
        } else {
            const mult = Int(digits.charAt(index)) * 2;
            const result = Math.trunc(mult / 10);
            const rest = mult % 10;
            sumB += result + rest;
        }
    }
    const sumC = sumA + sumB;
    let unitDigit = sumC % 10;
    if (unitDigit !== 0) {
        unitDigit = 10 - unitDigit;
    }

    const relationValidLastLetter = {0: 'J', 1: 'A', 2: 'B', 3: 'C', 4: 'D', 5: 'E', 6: 'F', 7: 'G', 8: 'H', 9: 'I'};
    // @ts-ignore
    const validLastLetter = relationValidLastLetter[unitDigit];
    const toTestLastUnit = str.substr(-1, 1);

    if (/^[PQRSW]$/i.test(firstLetter)) { // la última unidad será una letra
        return validLastLetter === toTestLastUnit;
    } else if (/^[ABEH]$/i.test(firstLetter)) { // la última unidad será un número
        return Int(str.substr(-1, 1)) === unitDigit;
    } else { // número o letra
        return Int(str.substr(-1, 1)) === unitDigit || validLastLetter === toTestLastUnit;
    }
}

export function isPostalCodeValid(postalCode: string): boolean {
    if (postalCode.toString().length !== 5) {
        return false;
    }
    const prefix = postalCode.toString().charAt(0) + postalCode.toString().charAt(1);
    return !(Int(prefix) <= 0 || Int(prefix) > 52);
}

export function truncateMiddle(text: string, maxLength: number = 30, separator: string = '...') {
    if (text.length <= maxLength) {
        return text;
    }
    const sepLen = separator.length;
    const charsToShow = maxLength - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return text.substr(0, frontChars) +
        separator +
        text.substr(text.length - backChars);
}

export function getMBfromBytes(bytes: number, decimals: number = 1): string {
    const mb = bytes / 1024 / 1024;
    return mb.toFixed(decimals);
}

export function getErrorCodeResponse(errorMessage: string): string {
    return errorMessage.substr(0, 3);
}

export function maskValidator(value: string, mask: string): boolean {
    if (value.length !== mask.length) {
        return false;
    }
    for (let i = 0; i < value.length; i = i + 1){
        switch (mask[i].toUpperCase()) {
            case 'A':
                if (!isLetter(value[i])) {
                    return false;
                }
                continue;
            case '0':
                if (!isCharNumber(value[i])) {
                    return false;
                }
                continue;
            case 'X':
                if (!isCharNumber(value[i]) &&!isLetter(value[i]) ) {
                    return false;
                }
                continue;
        }
        if (mask[i] !== value[i]) {
            return false;
        }
    }
    return true;
}

export function isLetter(c: string): boolean {
    return c.toLowerCase() != c.toUpperCase();
}

export function isCharNumber(c: string): boolean {
    return c >= '0' && c <= '9';
}

export function isInvalidDecimals(number: number): boolean {
    const splitNumber = number.toString().split('.');
    if (splitNumber.length === 2) {
        const decimals = Int(splitNumber[1]);
        return decimals > 99;
    }
    return splitNumber.length !== 1;
}

export function parsePrice(price: number): string {
    const pricePoint = parseFloat(price.toString()).toFixed(2).toString();
    return pricePoint.toString().split('.').join(',');
}

export function toFormatParkTime(minutes: number, showUnits: boolean): string {
    const HOUR = 60;
    if (minutes < HOUR) {
        if (minutes < 10) {
            return showUnits ? `0:0${minutes} m` : `0:0${minutes}`;
        }
        return showUnits ? `0:${minutes} m` : `0:${minutes}`;
    }
    if (minutes === HOUR - 1) {
        return showUnits ? "1:00 h" : "1:00";
    } else {
        const hours = Math.trunc(minutes / HOUR);
        let minutesFromHours = (minutes % HOUR).toString();
        if (minutesFromHours.length === 1) {
            minutesFromHours = `0${minutesFromHours}`
        }
        return showUnits ? `${hours} : ${minutesFromHours} h` : `${hours} : ${minutesFromHours}`;
    }
}

export function setFirstCharToLowerCase(text: string): string {
    return text.charAt(0).toLowerCase() + text.slice(1);
}

export function setFirstCharToUpperCase(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function truncateTwoDecimals(amount: number): string {
    const decimals = amount.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    if (decimals) {
        return decimals[0];
    }
    return amount.toString();
}