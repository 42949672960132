import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Auth} from "../../utils/AuthManager";
import Vehicle, {VehicleData} from "../../model/Vehicle";

export interface TaskAddVehicleRequest extends Auth {
    vehicle: VehicleData
}

export default class TaskAddVehicle extends BaseGenericRequest<TaskAddVehicleRequest, TaskResponse<Vehicle>> {

    private readonly vehicleData: VehicleData;

    public constructor(vehicleData: VehicleData) {
        super(METHOD.METHOD_POST, Urls.URL_VEHICLE_ADD);

        this.vehicleData = vehicleData;
    }

    protected getRequest(): TaskAddVehicleRequest {
        return {
            ...this.getAuth() as Auth,
            vehicle: this.vehicleData,
        }
    }
}