import * as React from "react";
import LoggedScreen from "../../base/screens/LoggedScreen";
import CardBody from "../../components/card/CardBody";
import Card from "../../components/card/Card";
import IconBase from "../../components/IconSVG/IconBase";
import {AppIconSVG} from "../../components/IconSVG/AppIconSVG";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_CONTACTO, TR_DATOS_DE_FACTURACION} from "../../I18n/constants";
import {ReducerBuilder} from "co-redux-builders";
import {connect} from "react-redux";
// import ParkScreenActions from "../park/ParkScreenActions";
import {RouteProps} from "react-router";
import UserContactSectionView from "./userDataView/UserContactSectionView";
import LoginReducer from "../login/LoginReducer";
// import ParkScreenReducer from "../park/ParkScreenReducer";
import ChangeUserImageModal from "./userDataView/ChangeUserImageModal";
import {ROUTE_PERSONAL_AREA_CONTACT} from "../../routing/Routes";
import {UserFormData} from "./userDataView/UserForm";
import BillingDataSectionView from "./billingDataView/BillingDataSectionView";
import {BillingFormData} from "./billingDataView/BillingDataForm";

interface PersonalAreaScreenProps {
}

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    LoginReducer.autoMapToProps(),
    // ParkScreenReducer.autoMapToProps(),
);

// const mapActionsToProps = ParkScreenActions.autoMapToProps();

type Props = PersonalAreaScreenProps & typeof mapStateToProps & RouteProps;

export type PersonalAreaSection = 'contact' | 'billingData'
    // | 'vehicles'
    ;

interface State {
    currentActiveSection: PersonalAreaSection,
    showChangeUserImageModal: boolean,
}

class PersonalAreaScreen extends LoggedScreen<Props, State> {

    public constructor(props: Props, context: any) {
        super(props, context);
        this.setNavbarTitleFromRouteNameScreen(ROUTE_PERSONAL_AREA_CONTACT);
        this.state = {
            currentActiveSection: 'contact',
            showChangeUserImageModal: false,
        }
    }

    public componentDidMount(): void {
        const {location,
            // userVehicles
        } = this.props;
        if (!!location) {
            const route = location.pathname.split('/');
            const section = route[route.length - 1] as PersonalAreaSection;
            this.setState({currentActiveSection: section})
        }
        // if (userVehicles.length === 0) {
        //     this.getVehicles();
        // }
        this.forceUpdate();
    }

    // private getVehicles = (): void => {
    //     new TaskVehicles().execute();
    // };


    private setCurrentActiveSection = (section: 'contact' | 'billingData'
        // | 'vehicles'
    ): void => {
        this.setState({currentActiveSection: section})
    };

    // private onDeleteVehicle = (vehicleId: number): void => {
    //     const {userData, onHideConfirmDeleteVehicleModal} = this.props;
    //     const userVehicles = userData ? userData.vehicles : [];
    //
    //     new TaskRemoveVehicle(vehicleId)
    //         .onSuccess(() => {
    //             if (CarManager.getCarId() === vehicleId.toString()) {
    //                 if (userVehicles.length === 1) {
    //                     CarManager.setCarId("");
    //                     CarManager.setCarName(DEFAULT_VEHICLE_NAME);
    //                 }
    //                 if (userVehicles.length > 1) {
    //                     if (userVehicles[0].id !== vehicleId) {
    //                         CarManager.setCarId(userVehicles[0].id.toString());
    //                         CarManager.setCarName(`${userVehicles[0].licplate} ${userVehicles[0].name}`)
    //                     } else {
    //                         CarManager.setCarId(userVehicles[1].id.toString());
    //                         CarManager.setCarName(`${userVehicles[1].licplate} ${userVehicles[1].name}`)
    //                     }
    //                 }
    //             }
    //             onHideConfirmDeleteVehicleModal();
    //             this.getVehicles();
    //
    //         })
    //         .execute();
    // };

    private onShowChangeUserImageModal = (): void => {
        this.setState({showChangeUserImageModal: true})
    };

    private onHideChangeUserImageModal = (): void => {
        this.setState({showChangeUserImageModal: false})
    };

    public renderScreen(): React.ReactNode {
        const {loginLoading, userData} = this.props;
        const {currentActiveSection} = this.state;

        const existUserDataIcon = userData && userData.icon && userData.icon.length !== 0;

        return (
            <>
                <Card loading={loginLoading}>
                    <CardBody>
                        <div className={"personal-area-container"}>
                            <div className={"head"}>
                                <div className={"text-center"}>
                                    {existUserDataIcon ?
                                        <img src={userData && userData.icon} alt={"user"}
                                             onClick={this.onShowChangeUserImageModal}/> :
                                        <IconBase icon={AppIconSVG.PERSON.icon} className={"icon-person"}/>
                                    }
                                    <div className={"user-name"}>{userData ? userData.name : ''}</div>
                                </div>
                            </div>
                            {this.renderChangeUserImageModal()}

                            <div className={"actions-section"}>
                                <div className={`action site ${currentActiveSection === "contact" && "active"}`}
                                     onClick={() => this.setCurrentActiveSection("contact")}>
                                    <IconBase icon={AppIconSVG.TELEPHONE.icon}
                                              className={"icon icon-telephone"}
                                    />
                                    <div className={"font-middle-bold m-t-5"}>{I18nUtils.tr(TR_CONTACTO)}</div>
                                </div>

                                <div className={`action ticket ${currentActiveSection === "billingData" && "active"}`}
                                     onClick={() => this.setCurrentActiveSection("billingData")}>
                                    <IconBase icon={AppIconSVG.BILLING.icon}
                                              className={"icon icon-card"}
                                    />
                                    <div className={"font-middle-bold m-t-5"}>{I18nUtils.tr(TR_DATOS_DE_FACTURACION)}</div>
                                </div>

                                {/*<div*/}
                                {/*    className={`action cancellation-complaint ${currentActiveSection === "vehicles" && "active"}`}*/}
                                {/*    onClick={() => this.setCurrentActiveSection("vehicles")}>*/}
                                {/*    <IconBase icon={AppIconSVG.CAR.icon}*/}
                                {/*              className={"icon icon-car"}*/}
                                {/*    />*/}
                                {/*    <div className={"font-middle-bold m-t-5"}>*/}
                                {/*        {I18nUtils.tr(TR_VEHICULOS)}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                            <div className={"section-content"}>
                                {currentActiveSection === 'contact' && this.renderContactSection()}
                                {currentActiveSection === 'billingData' && this.renderBillingDataSection()}
                                {/*{currentActiveSection === 'vehicles' && this.renderVehiclesSection()}*/}
                            </div>

                        </div>
                    </CardBody>
                </Card>
                {/*{this.renderAddVehicleModal()}*/}
            </>
        )
    }

    private renderContactSection(): React.ReactNode {
        const {userData} = this.props;
        const initialValuesData: Partial<UserFormData> | undefined = userData ?
            {
                name: userData.name,
                phone: userData.phone,
                username: userData.email,
                last_name: userData.last_name,
            } : undefined;

        return !!initialValuesData ? <UserContactSectionView initialValues={initialValuesData}/> : null
    }

    private renderBillingDataSection(): React.ReactNode {
        const {userData} = this.props;
        const initialValuesData: Partial<BillingFormData> | undefined = userData ?
            {
                dni: userData.dni,
                address: userData.address,
                city: userData.city,
                postal_code: userData.postal_code
            } : undefined;

        return !!initialValuesData ? <BillingDataSectionView initialValues={initialValuesData}/> : null
    }

    // TODO: Desactivado. Eliminar cuando se sepa claro si no se va a utilizar
    // private renderVehiclesSection(): React.ReactNode {
    //     const {userData, onShowAddVehicleModal, currentVehicle} = this.props;
    //     const userVehicles = userData ? userData.vehicles : [];
    //     const currentVehicleId = currentVehicle ? currentVehicle.id : undefined;
    //
    //     return (
    //         <>
    //             <VehicleListContent userVehicles={userVehicles}
    //                                 deleteVehicleHandler={() => {
    //                                     currentVehicleId && this.onDeleteVehicle(currentVehicleId)
    //                                 }}
    //             />
    //             <div className={"content-form-footer section"}>
    //                 <Button
    //                     className={"btn btn-lg btn-center btn-form btn-submit"}
    //                     text={I18nUtils.tr(TR_NUEVO_VEHICULO)}
    //                     block={true}
    //                     onClick={onShowAddVehicleModal}
    //                     disabled={false}
    //                 />
    //             </div>
    //         </>
    //     )
    // }

    // private renderAddVehicleModal(): React.ReactNode {
    //     const {onHideAddVehicleModal, showAddVehicleModal, currentVehicle, setCurrentVehicle, onHideConfirmDeleteVehicleModal} = this.props;
    //     const vehicleFormModalMoreProps = !!currentVehicle ? {initialValues: currentVehicle} : {};
    //
    //     return (
    //         // @ts-ignore
    //         <VehicleFormModal show={showAddVehicleModal}
    //                           onConfirmAction={() => {
    //                               this.getVehicles();
    //                               onHideAddVehicleModal();
    //                               setCurrentVehicle(undefined);
    //                           }}
    //                           onClose={() => {
    //                               onHideAddVehicleModal();
    //                               setCurrentVehicle(undefined);
    //                           }}
    //                           deleteVehicleHandler={(vehicleId) => {
    //                               onHideConfirmDeleteVehicleModal();
    //                               this.onDeleteVehicle(Int(vehicleId));
    //                               this.getVehicles();
    //                               onHideAddVehicleModal();
    //                               setCurrentVehicle(undefined);
    //                           }}
    //                           {...vehicleFormModalMoreProps}
    //         />
    //     )
    // }

    private renderChangeUserImageModal(): React.ReactNode {
        return (
            <ChangeUserImageModal show={this.state.showChangeUserImageModal}
                                  onClose={this.onHideChangeUserImageModal}/>
        )
    }
}

export default connect(mapStateToProps)(PersonalAreaScreen as unknown as React.ComponentType<PersonalAreaScreenProps>);