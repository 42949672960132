import * as React from 'react';
import {Component, ReactNode} from 'react';
import {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';
import Select from "./Select";
import FormInputOption from "./FormInputOption";

export interface FormSelectProps extends WrappedFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    options: FormInputOption[],
    onSelected: (selectedValue: string) => void,
    labelClassName?: string,
    classNameFormCol?: string,
    required?: boolean,
    enabledEmptyOption?: boolean,
    formLine?: boolean,
}

export default class FormSelect extends Component<FormSelectProps> {

    public render(): ReactNode {
        const {
            input, meta, name, label, disabled, options, labelClassName, className,
            required = false, enabledEmptyOption=true, formLine = true
        } = this.props;
        const classFormLine = classNames({focused: meta.touched && meta.error, error: meta.touched && meta.error});

        return (
            <Select
                classFormLine={classFormLine}
                label={label || ''}
                name={name}
                value={input.value}
                onChange={(event) => {
                    input.onChange(event);
                    if (this.props.onSelected) {
                        this.props.onSelected(event.target.value)
                    }
                }}
                disabled={disabled || false}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
                options={options}
                error={meta.touched ? meta.error : ""}
                emptyOption={enabledEmptyOption}
                labelClassName={labelClassName}
                className={className}
                required={required}
                formLine={formLine}
            />
        );
    }

}

