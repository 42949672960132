import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import User, {UserRegister} from "../../model/User";
import ShopRegister from "../../model/ShopRegister";
import md5 from "md5";

export interface TaskMerchantRegisterRequest {
    user: UserRegister,
    shop: ShopRegister,
}

export default class TaskUserRegister extends BaseGenericRequest<TaskMerchantRegisterRequest, TaskResponse<User>> {

    private readonly data: TaskMerchantRegisterRequest;

    public constructor(data: TaskMerchantRegisterRequest) {
        super(METHOD.METHOD_POST, Urls.URL_REGISTER);
        this.data = data;
    }

    protected getRequest(): TaskMerchantRegisterRequest {
        return {
            user: {
                ...this.data.user,
                password: this.data.user.password ? md5(this.data.user.password) : '',
            },
            shop: {...this.data.shop}
        }
    }
}