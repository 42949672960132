import * as React from 'react';
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import ModalHeaderTitle from "../../components/modal/ModalHeaderTitle";
import Button from "../../components/Button";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_ACEPTAR} from "../../I18n/constants";

interface ConfirmModalProps extends BaseModalProps {
    title: string,
    message?: string,
    onConfirmAction: (values?: any) => void,
    showCloseButton?: boolean
    disabled?: boolean,
}

export default class TermsAndConditionsModal extends BaseModal<ConfirmModalProps> {

    public render(): React.ReactNode {
        const {
            onClose, show, title, message, onConfirmAction, children, showCloseButton = true, disabled = false
        } = this.props;

        const contentSize = 'lg';

        return (
            <Modal onHide={onClose} show={show} size={contentSize} className={"full-screen"}>
                <ModalHeaderTitle title={title}
                                  message={message}
                                  classNameTitle={`modal-subtitle ${children ? "" : "p-b-0"}`}
                                  showCloseButton={showCloseButton}
                />
                {children ?
                    <Modal.Body>
                        {children}
                    </Modal.Body> : null}

                <Modal.Footer className={"footer-terms"}>
                    <Button
                        className={"btn btn-lg btn-form btn-submit btn-center"}
                        text={I18nUtils.tr(TR_ACEPTAR)}
                        block={true}
                        onClick={onConfirmAction}
                        disabled={disabled}
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}