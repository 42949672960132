import * as React from "react";
import {routerMiddleware} from "react-router-redux";
import {ReduxStorageUtils} from "co-redux-builders";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {History} from "history";
import CombinedReducers from "./redux/CombinedReducers";
import "./App.scss";
import Routing from "./routing/Routing";
import AuthManager from "./utils/AuthManager";
const history = require("history");

class App extends React.Component {

    protected history: History;

    public componentDidMount() {
        this.history = history.createBrowserHistory();
        const middleware = routerMiddleware(this.history);
        ReduxStorageUtils.initialize(createStore(CombinedReducers, applyMiddleware(thunk, middleware)));
        AuthManager.initialize();
        // CarManager.initialize();
        this.forceUpdate();
    }

    public render() {
        const store = ReduxStorageUtils.getStorage();
        if (!store) {
            return null;
        }
        return (
            <Provider store={store}>
                <Routing history={this.history} />
            </Provider>
        );
    }

}

export default App;
