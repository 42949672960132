import * as  React from "react";
import {ReactNode} from "react";
import {RouterProps} from "react-router";
import {Route, Router} from "react-router-dom";
import {
    ROUTE_CONTACT,
    ROUTE_CONTACT_SUGGESTION,
    ROUTE_HISTORY,
    ROUTE_LOGIN,
    ROUTE_PERSONAL_AREA_CONTACT,
    ROUTE_PERSONAL_AREA_PAYMENT_CARDS,
    ROUTE_REGISTER_MERCHANT,
    ROUTE_REGISTER_MERCHANT_SUCCESS,
    ROUTE_START
} from './Routes';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import Alert from "../base/alerts/Alert";
import LoginScreen from "../modules/login/LoginScreen";
import StartScreen from "../modules/start/StartScreen";
import {connect} from "react-redux";
import MasterDataReducer from "../redux/reducers/MasterDataReducer";
import PersonalAreaScreen from "../modules/personalArea/PersonalAreaScreen";
import {ReducerBuilder} from "co-redux-builders";
import LoginReducer from "../modules/login/LoginReducer";
import AuthManager from "../utils/AuthManager";
import TaskLogin from "../ws/auth/TaskLogin";
import ContactScreen from "../modules/contact/ContactScreen";
import SuggestionForm from "../modules/contact/SuggestionForm";
import RegisterMerchantScreen from "../modules/merchantRegistration/RegisterMerchantScreen";
import RegisterMerchantSuccessScreen from "../modules/merchantRegistration/RegisterMerchantSuccessScreen";
import HistoryScreen from "../modules/history/HistoryScreen";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    MasterDataReducer.autoMapToProps(),
    LoginReducer.autoMapToProps()
);

type Props = typeof mapStateToProps & RouterProps;

class Routing extends React.Component<Props> {

    public componentDidMount(): void {
        if (!this.props.userData && AuthManager.isLogged()) {
            const authData = AuthManager.getAuth();
            if (!!authData) {
                new TaskLogin(authData.username, authData.password).execute();
            }
        }
    }


    public render(): ReactNode {
        return (
            <Router history={this.props.history}>
                <Route path="/" component={Alert}/>
                <Route path={ROUTE_LOGIN} exact component={LoginScreen}/>
                <Route path={ROUTE_START} exact component={StartScreen}/>
                <Route path={ROUTE_PERSONAL_AREA_CONTACT} exact component={PersonalAreaScreen}/>
                <Route path={ROUTE_PERSONAL_AREA_PAYMENT_CARDS} exact component={PersonalAreaScreen}/>
                <Route path={ROUTE_CONTACT} exact component={ContactScreen}/>
                <Route path={ROUTE_CONTACT_SUGGESTION} exact component={SuggestionForm}/>
                <Route path={ROUTE_REGISTER_MERCHANT} exact component={RegisterMerchantScreen}/>
                <Route path={ROUTE_REGISTER_MERCHANT_SUCCESS} exact component={RegisterMerchantSuccessScreen}/>
                <Route path={ROUTE_HISTORY} exact component={HistoryScreen}/>
            </Router>
        );
    }
}

export default connect(mapStateToProps)(Routing as unknown as React.ComponentType<RouterProps>);
