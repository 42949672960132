import * as React from "react";
import User from "../../model/User";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_ESTIMADO, TR_PAGADO, TR_PENDIENTE_DE_PAGO} from "../../I18n/constants";

export class HistoryViewDataHelpers {

    public static renderVehicleLicName(vehicleId: number, userData: User): React.ReactNode {
        const userVehicles = userData ? userData.vehicles : [];
        const currentVehicle = userVehicles.find((vehicle) => vehicle.id.toString() === vehicleId.toString());
        return currentVehicle ?
            <>{currentVehicle.licplate} <span className={"font-bold"}>{currentVehicle.name}</span></> : null;
    }

    public static getState(endTime?: number, paymentDate?: number): string {
        if (!endTime && !paymentDate) {
            return I18nUtils.tr(TR_ESTIMADO)
        } else if (endTime && paymentDate) {
            return I18nUtils.tr(TR_PAGADO)
        }
        return I18nUtils.tr(TR_PENDIENTE_DE_PAGO)
    }
}