import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Auth} from "../../utils/AuthManager";
import Vehicle, {VehicleData} from "../../model/Vehicle";

export interface TaskEditVehicleRequest extends Auth {
    vehicle: VehicleData
}

export default class TaskEditVehicle extends BaseGenericRequest<TaskEditVehicleRequest, TaskResponse<Vehicle>> {

    private readonly vehicleData: VehicleData;

    public constructor(vehicleData: VehicleData) {
        super(METHOD.METHOD_POST, Urls.URL_VEHICLE_EDIT);

        this.vehicleData = vehicleData;
    }

    protected getRequest(): TaskEditVehicleRequest {
        return {
            ...this.getAuth() as Auth,
            vehicle: this.vehicleData,
        }
    }
}