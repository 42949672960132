import * as React from "react";
import IconBase from "../../../../components/IconSVG/IconBase";
import {AppIconSVG, IconSVG} from "../../../../components/IconSVG/AppIconSVG";
import {goToRoute,} from "../../../../utils/Router";

interface Props {
    collapsed: boolean,
    title: string,
    icon?: IconSVG,
    active: boolean,
    route?: string,
    arrowClassName?: string,
    onClick?: (event?: any) => void,

    arrowItem?: boolean,
    arrowTitle?: string,
    userImage?: string,

    closeSessionItem?: boolean,
}

interface State {
    expanded: boolean,
}

export default class MenuItem extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {expanded: false,}
    }

    public render(): React.ReactNode {
        const {arrowItem = false, closeSessionItem, arrowClassName, route} = this.props;
        const itemClassName = `item-content ${closeSessionItem ? "close-session" : ""}`;


        return (
            <li className={`sidebar-item ${arrowItem && `arrow-item ${arrowClassName}`} pointer`}
                onClick={() => route && goToRoute(route)}
            >
                <div className={itemClassName}>
                    {arrowItem ?
                        this.renderLinkArrow()
                        : this.renderLink()}
                </div>
            </li>
        )
    }

    private renderLink(): React.ReactNode {
        const {title, icon, closeSessionItem, onClick} = this.props;

        const titleClassName = closeSessionItem ? "success-color" : "font-middle-bold";

        const item = (
            <>
                {icon && <IconBase icon={icon.icon} iconBaseProps={icon.iconProps}
                                   className={`icon-item ${closeSessionItem ? "close-session" : ""}`}/>}
                <span className={titleClassName}>{title}</span>
            </>);

        if (closeSessionItem) {
            return (
                <div className={"pointer"}
                     onClick={(event) => {
                         event.stopPropagation();
                         !!onClick && onClick()
                     }}>
                    {item}
                </div>
            )
        }
        return <div>{item}</div>;
    }

    private renderLinkArrow(): React.ReactNode {
        const {title, icon, route, arrowTitle, userImage} = this.props;

        return (
            <>
                <span className={"font-bold m-r-15"}>
                    {userImage ?
                        <img src={userImage} alt={"user"} className={"image-user"}/> :
                        icon && <IconBase icon={icon.icon} className={"icon-item"}/>
                    }
                    {title}
                </span>

                <span className={"arrow-title"} onClick={() => route && goToRoute(route)}>{arrowTitle}
                    <IconBase icon={AppIconSVG.ARROW_RIGHT.icon} className={"icon-arrow"}/>
                </span>
            </>)
    }
}