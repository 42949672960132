export const TR_VNG_APARCAMENTS = 'TR_VNG_APARCAMENTS';
export const TR_ACEPTAR = 'TR_ACEPTAR';
export const TR_DE = 'TR_DE';
export const TR_GENERIC_WS_ERROR = 'TR_GENERIC_WS_ERROR';
export const TR_CANCELAR = 'TR_CANCELAR';
export const TR_A = 'TR_A';
export const TR_TODOS = 'TR_TODOS';
export const TR_OLVIDATE_DE_LAS_MONEDAS = 'TR_OLVIDATE_DE_LAS_MONEDAS';
export const TR_SIN_RECARGAS_NI_ABONO_PREVIO = 'TR_SIN_RECARGAS_NI_ABONO_PREVIO';
export const TR_DISPONIBLE_EN = 'TR_DISPONIBLE_EN';
export const TR_INICIAR_SESION = 'TR_INICIAR_SESION';
export const TR_WEB = 'TR_WEB';
export const TR_TWITTER = 'TR_TWITTER';
export const TR_FACEBOOK = 'TR_FACEBOOK';
export const TR_PARQUIMETRO = 'TR_PARQUIMETRO';
export const TR_OTRAS_CIUDADES = 'TR_OTRAS_CIUDADES';
export const TR_CONTACTA_CON_NOSOTROS = 'TR_CONTACTA_CON_NOSOTROS';
export const TR_VEHICULOS = 'TR_VEHICULOS';
export const TR_MEDIOS_DE_PAGO = 'TR_MEDIOS_DE_PAGO';
export const TR_HISTORIAL = 'TR_HISTORIAL';
export const TR_CONFIGURACION = 'TR_CONFIGURACION';
export const TR_ACERCA_DE = 'TR_ACERCA_DE';
export const TR_CERRAR_SESION = 'TR_CERRAR_SESION';
export const TR_DATOS_DE_CONTACTO = 'TR_DATOS_DE_CONTACTO';
export const TR_POLITICA_DE_PRIVACIDAD = 'TR_POLITICA_DE_PRIVACIDAD';
export const TR_SEGURO_QUE_DESEAS_CERRAR_SESION = 'TR_SEGURO_QUE_DESEAS_CERRAR_SESION';
export const TR_AREA_PERSONAL = 'TR_AREA_PERSONAL';
export const TR_EMAIL = 'TR_EMAIL';
export const TR_CONTRASENA = 'TR_CONTRASENA';
export const TR_INTRODUCE_EMAIL = 'TR_INTRODUCE_EMAIL';
export const TR_RECUPERAR_CONTRASENA = 'TR_RECUPERAR_CONTRASENA';
export const TR_AUN_NO_TIENES_USUARIO = 'TR_AUN_NO_TIENES_USUARIO';
export const TR_REGISTRATE = 'TR_REGISTRATE';
export const TR_INTRODUCE_TU_EMAIL_SE_ENVIARAN_LAS_INSTRUCCIONES_PARA_RECUPERAR_LA_CONTRASENA_AL_EMAIL_INDICADO = 'TR_INTRODUCE_TU_EMAIL_SE_ENVIARAN_LAS_INSTRUCCIONES_PARA_RECUPERAR_LA_CONTRASENA_AL_EMAIL_INDICADO';
export const TR_ENVIAR = 'TR_ENVIAR';
export const TR_EMAIL_ENVIADO_CORRECTAMENTE = 'TR_EMAIL_ENVIADO_CORRECTAMENTE';
export const TR_NO_HA_SIDO_POSIBLE_ENVIAR_EL_EMAIL = 'TR_NO_HA_SIDO_POSIBLE_ENVIAR_EL_EMAIL';
export const TR_NOMBRE_Y_APELLIDOS = 'TR_NOMBRE_Y_APELLIDOS';
export const TR_TELEFONO = 'TR_TELEFONO';
export const TR_YA_TIENES_USUARIO = 'TR_YA_TIENES_USUARIO';
export const TR_ACCEDE = 'TR_ACCEDE';
export const TR_NOMBRE_Y_APELLLIDOS_OBLIGATORIO = 'TR_NOMBRE_Y_APELLLIDOS_OBLIGATORIO';
export const TR_EMAIL_OBLIGATORIO = 'TR_EMAIL_OBLIGATORIO';
export const TR_TELEFONO_OBLIGATORIO = 'TR_TELEFONO_OBLIGATORIO';
export const TR_CONTRASENA_OBLIGATORIA = 'TR_CONTRASENA_OBLIGATORIA';
export const TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO = 'TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO';
export const TR_TE_HAS_REGISTRADO_CORRECTAMENTE = 'TR_TE_HAS_REGISTRADO_CORRECTAMENTE';
export const TR_HAS_CREADO_TU_USUARIO_CON_EXITO_AHORA_PUEDES_DESCARGAR_LA_APP = 'TR_HAS_CREADO_TU_USUARIO_CON_EXITO_AHORA_PUEDES_DESCARGAR_LA_APP';
export const TR_SI_YA_TENIAS_LA_APP_DESCARGADA_VUELVE_A_INICIAR_SESION_EN_LA_APP_PARA_ACTUALIZAR_TU_CUENTA = 'TR_SI_YA_TENIAS_LA_APP_DESCARGADA_VUELVE_A_INICIAR_SESION_EN_LA_APP_PARA_ACTUALIZAR_TU_CUENTA';
export const TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO = 'TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO';
export const TR_NOMBRE_COMERCIO = 'TR_NOMBRE_COMERCIO';
export const TR_CIF = 'TR_CIF';
export const TR_CIF_O_DNI = 'TR_CIF_O_DNI';
export const TR_DIRECCION = 'TR_DIRECCION';
export const TR_CODIGO_POSTAL = 'TR_CODIGO_POSTAL';
export const TR_ASOCIACION = 'TR_ASOCIACION';
export const TR_NUMERO_DE_ASOCIADO = 'TR_NUMERO_DE_ASOCIADO';
export const TR_DATOS_USUARIO = 'TR_DATOS_USUARIO';
export const TR_DATOS_COMERCIO = 'TR_DATOS_COMERCIO';
export const TR_DATOS_DEL_COMERCIO = 'TR_DATOS_DEL_COMERCIO';
export const TR_ESTADO_LEGAL = 'TR_ESTADO_LEGAL';
export const TR_FORMA_LEGAL = 'TR_FORMA_LEGAL';
export const TR_NOMBRE_OBLIGATORIO = 'TR_NOMBRE_OBLIGATORIO';
export const TR_APELLIDOS_OBLIGATORIO = 'TR_APELLIDOS_OBLIGATORIO';
export const TR_DNI_OBLIGATORIO = 'TR_DNI_OBLIGATORIO';
export const TR_DNI_INVALIDO = 'TR_DNI_INVALIDO';
export const TR_CIUDAD_OBLIGATORIA = 'TR_CIUDAD_OBLIGATORIA';
export const TR_CIF_OBLIGATORIO = 'TR_CIF_OBLIGATORIO';
export const TR_CIF_O_DNI_OBLIGATORIO = 'TR_CIF_O_DNI_OBLIGATORIO';
export const TR_CIF_INVALIDO = 'TR_CIF_INVALIDO';
export const TR_CIF_O_NIF_INVALIDO = 'TR_CIF_O_NIF_INVALIDO';
export const TR_DIRECCION_OBLIGATORIA = 'TR_DIRECCION_OBLIGATORIA';
export const TR_CODIGO_POSTAL_INVALIDO = 'TR_CODIGO_POSTAL_INVALIDO';
export const TR_ESTADO_LEGAL_OBLIGATORIO = 'TR_ESTADO_LEGAL_OBLIGATORIO';
export const TR_NOMBRE = 'TR_NOMBRE';
export const TR_APELLIDOS = 'TR_APELLIDOS';
export const TR_DNI = 'TR_DNI';
export const TR_CIUDAD = 'TR_CIUDAD';
export const TR_CODIGO_POSTAL_OBLIGATORIO = 'TR_CODIGO_POSTAL_OBLIGATORIO';
export const TR_REGISTRO_EXCLUSIVO_PARA_COMERCIOS_Y_NEGOCIOS = 'TR_REGISTRO_EXCLUSIVO_PARA_COMERCIOS_Y_NEGOCIOS';
export const TR_TERMINOS_Y_CONDICIONES = 'TR_TERMINOS_Y_CONDICIONES';
export const TR_ACEPTAR_RECIBIR = 'TR_ACEPTAR_RECIBIR';
export const TR_COMUNICACIONES_COMERCIALES = 'TR_COMUNICACIONES_COMERCIALES';
export const TR_LOCALIZACION = 'TR_LOCALIZACION';
export const TR_PARA_EL_FUNCIONAMIENTO_DE_LA_APLICACION_ES_NECESARIO_SABER_LA_CIUDAD_EN_LA_QUE_TE_ENCUENTRAS_SELECCIONA_UNA_CIUDAD_DE_LA_LISTA_DE_CIUDADES = 'TR_PARA_EL_FUNCIONAMIENTO_DE_LA_APLICACION_ES_NECESARIO_SABER_LA_CIUDAD_EN_LA_QUE_TE_ENCUENTRAS_SELECCIONA_UNA_CIUDAD_DE_LA_LISTA_DE_CIUDADES';
export const TR_SELECCIONAR = 'TR_SELECCIONAR';
export const TR_SELECCIONA_LA_CIUDAD = 'TR_SELECCIONA_LA_CIUDAD';
export const TR_PARQUIMETROS = 'TR_PARQUIMETROS';
export const TR_PARKING_PUBLICO = 'TR_PARKING_PUBLICO';
export const TR_CIUDAD_SELECCIONADA_CORRECTAMENTE = 'TR_CIUDAD_SELECCIONADA_CORRECTAMENTE';
export const TR_NUEVO_VEHICULO = 'TR_NUEVO_VEHICULO';
export const TR_MATRICULA = 'TR_MATRICULA';
export const TR_REPETIR_MATRICULA = 'TR_REPETIR_MATRICULA';
export const TR_MI_MATRICULA_NO_ES_ESPANOLA = 'TR_MI_MATRICULA_NO_ES_ESPANOLA';
export const TR_DESCRIPCION = 'TR_DESCRIPCION';
export const TR_MOVILIDAD_REDUCIDA_TED = 'TR_MOVILIDAD_REDUCIDA_TED';
export const TR_ACTIVA_ESTA_OPCION_SI_TU_VEHICULO_ESTA_IDENTIFICADO_CON_LA_TARJETA_PRM = 'TR_ACTIVA_ESTA_OPCION_SI_TU_VEHICULO_ESTA_IDENTIFICADO_CON_LA_TARJETA_PRM';
export const TR_MAS_INFO = 'TR_MAS_INFO';
export const TR_NUEVO_COCHE = 'TR_NUEVO_COCHE';
export const TR_NO_TIENE_NINGUN_VEHICULO_VINCULADO = 'TR_NO_TIENE_NINGUN_VEHICULO_VINCULADO';
export const TR_VEHICULO_ANADIDO_CORRECTAMENTE = 'TR_VEHICULO_ANADIDO_CORRECTAMENTE';
export const TR_ESTAS_SEGURO_DE_ELIMINAR_EL_VEHICULO = 'TR_ESTAS_SEGURO_DE_ELIMINAR_EL_VEHICULO';
export const TR_ELIMINAR = 'TR_ELIMINAR';
export const TR_EDITAR = 'TR_EDITAR';
export const TR_MATRICULA_OBLIGATORIA = 'TR_MATRICULA_OBLIGATORIA';
export const TR_REPITE_LA_MATRICULA = 'TR_REPITE_LA_MATRICULA';
export const TR_LA_MATRICULA_NO_COINCIDE = 'TR_LA_MATRICULA_NO_COINCIDE';
export const TR_LA_DESCRIPCION_ES_OBLIGATORIA = 'TR_LA_DESCRIPCION_ES_OBLIGATORIA';
export const TR_VEHICULO_MODIFICADO_CORRECTAMENTE = 'TR_VEHICULO_MODIFICADO_CORRECTAMENTE';
export const TR_GUARDAR = 'TR_GUARDAR';
export const TR_EDITAR_VEHICULO = 'TR_EDITAR_VEHICULO';
export const TR_TOCA_PARA_SELECCIONAR_VEHICULO = 'TR_TOCA_PARA_SELECCIONAR_VEHICULO';
export const TR_CLIC_AQUI_PARA_SELECCIONAR_VEHICULO = 'TR_CLIC_AQUI_PARA_SELECCIONAR_VEHICULO';
export const TR_INFORMACION_SOBRE_MOVILIDAD_REDUCIDA = 'TR_INFORMACION_SOBRE_MOVILIDAD_REDUCIDA';
export const TR_TARJETA_COMERCIANTE = 'TR_TARJETA_COMERCIANTE';
export const TR_RESIDENTE = 'TR_RESIDENTE';
export const TR_LA_MATRICULA_NO_ES_VALIDA = 'TR_LA_MATRICULA_NO_ES_VALIDA';
export const TR_SECTOR_RESIDENTE = 'TR_SECTOR_RESIDENTE';
export const TR_TARJETA_DE_RESIDENTE = 'TR_TARJETA_DE_RESIDENTE';
export const TR_VEHICULO = 'TR_VEHICULO';
export const TR_TIPO_DE_VEHICULO = 'TR_TIPO_DE_VEHICULO';
export const TR_ESTACIONAR = 'TR_ESTACIONAR';
export const TR_LUGAR = 'TR_LUGAR';
export const TR_TICKET_TARIFA = 'TR_TICKET_TARIFA';
export const TR_ANULACION = 'TR_ANULACION';
export const TR_DENUNCIAS = 'TR_DENUNCIAS';
export const TR_LA_POSICION_ES_OBLIGATORIA = 'TR_LA_POSICION_ES_OBLIGATORIA';
export const TR_FUERA_DE_ZONA = 'TR_FUERA_DE_ZONA';
export const TR_SIGUIENTE = 'TR_SIGUIENTE';
export const TR_NO_TIENE_NINGUN_VEHICULO_ASOCIADO = 'TR_NO_TIENE_NINGUN_VEHICULO_ASOCIADO';
export const TR_ANADA_UN_VEHICULO_PARA_PODER_CONTINUAR_CON_EL_PROCESO_DE_ESTACIONAMIENTO = 'TR_ANADA_UN_VEHICULO_PARA_PODER_CONTINUAR_CON_EL_PROCESO_DE_ESTACIONAMIENTO';
export const TR_ES_NECESARIO_INDICAR_UNA_POSICION_PARA_PODER_CONTINUAR = 'TR_ES_NECESARIO_INDICAR_UNA_POSICION_PARA_PODER_CONTINUAR';
export const TR_CONFIRMAR_PAGO = 'TR_CONFIRMAR_PAGO';
export const TR_GASTOS_DE_GESTION_INCLUIDOS = 'TR_GASTOS_DE_GESTION_INCLUIDOS';
export const TR_HORA_DE_FIN = 'TR_HORA_DE_FIN';
export const TR_ZONA_DE_ESTACIONAMIENTO_DEL_VEHICULO_SELECCIONADA = 'TR_ZONA_DE_ESTACIONAMIENTO_DEL_VEHICULO_SELECCIONADA';
export const TR_SELECCIONA_TARJETA_DE_PAGO = 'TR_SELECCIONA_TARJETA_DE_PAGO';
export const TR_NO_TIENE_NINGUNA_TARJETA_VINCULADA = 'TR_NO_TIENE_NINGUNA_TARJETA_VINCULADA';
export const TR_ANADIR_TARJETA = 'TR_ANADIR_TARJETA';
export const TR_NECESITAS_REGISTRAR_UNA_TARJETA_PARA_PODER_CONTINUAR = 'TR_NECESITAS_REGISTRAR_UNA_TARJETA_PARA_PODER_CONTINUAR';
export const TR_TARJETA_MODIFICADA_CORRECTAMENTE = 'TR_TARJETA_MODIFICADA_CORRECTAMENTE';
export const TR_TARJETA_REGISTRADA_CORRECTAMENTE = 'TR_TARJETA_REGISTRADA_CORRECTAMENTE';
export const TR_NUEVA_TARJETA = 'TR_NUEVA_TARJETA';
export const TR_EDITAR_TARJETA = 'TR_EDITAR_TARJETA';
export const TR_TITULAR = 'TR_TITULAR';
export const TR_NUMERO_DE_TARJETA = 'TR_NUMERO_DE_TARJETA';
export const TR_FECHA_DE_CADUCIDAD = 'TR_FECHA_DE_CADUCIDAD';
export const TR_CVV = 'TR_CVV';
export const TR_TITULAR_OBLIGATORIO = 'TR_TITULAR_OBLIGATORIO';
export const TR_NUMERO_DE_TARJETA_OBLIGATORIO = 'TR_NUMERO_DE_TARJETA_OBLIGATORIO';
export const TR_ESTAS_SEGURO_DE_ELIMINAR_LA_TARJETA = 'TR_ESTAS_SEGURO_DE_ELIMINAR_LA_TARJETA';
export const TR_FECHA_DE_CADUCIDAD_OBLIGATORIA = 'TR_FECHA_DE_CADUCIDAD_OBLIGATORIA';
export const TR_EL_MES_ES_INVALIDO = 'TR_EL_MES_ES_INVALIDO';
export const TR_EL_ANO_ES_INVALIDO = 'TR_EL_ANO_ES_INVALIDO';
export const TR_CODIGO_CVV_OBLIGATORIO = 'TR_CODIGO_CVV_OBLIGATORIO';
export const TR_ANADIR_NUEVO_ESTACIONAMIENTO = 'TR_ANADIR_NUEVO_ESTACIONAMIENTO';
export const TR_DONDE_HE_APARCADO = 'TR_DONDE_HE_APARCADO';
export const TR_IMPORTE = 'TR_IMPORTE';
export const TR_AMPLIAR_PLAZO = 'TR_AMPLIAR_PLAZO';
export const TR_DESAPARCAR = 'TR_DESAPARCAR';
export const TR_EL_COSTE_DE_SU_ESTACIONAMIENTO_ES = 'TR_EL_COSTE_DE_SU_ESTACIONAMIENTO_ES';
export const TR_HA_ESTADO_APARCADO = 'TR_HA_ESTADO_APARCADO';
export const TR_DESEA_DESAPARCAR = 'TR_DESEA_DESAPARCAR';
export const TR_NO_TIENES_NINGUNA_DENUNCIA = 'TR_NO_TIENES_NINGUNA_DENUNCIA';
export const TR_ANULACION_DENUNCIAS = 'TR_ANULACION_DENUNCIAS';
export const TR_UNA_VEZ_SUPERADO_EL_TIEMPO_PASARA_A_COSTAR = 'TR_UNA_VEZ_SUPERADO_EL_TIEMPO_PASARA_A_COSTAR';
export const TR_HASTA_LAS = 'TR_HASTA_LAS';
export const TR_TIEMPO_RESTANTE_PARA_ANULAR_DENUNCIA = 'TR_TIEMPO_RESTANTE_PARA_ANULAR_DENUNCIA';
export const TR_IMPORTE_ANULACION = 'TR_IMPORTE_ANULACION';
export const TR_HISTORIAL_DE_DENUNCIAS = 'TR_HISTORIAL_DE_DENUNCIAS';
export const TR_ANULAR_DENUNCIA = 'TR_ANULAR_DENUNCIA';
export const TR_INFORMACION_SOBRE_DENUNCIAS = 'TR_INFORMACION_SOBRE_DENUNCIAS';
export const TR_DIRIJASE_A_NUESTROS_AGENTES_CONTROLADORES_O_EN_LA_CENTRAL = 'TR_DIRIJASE_A_NUESTROS_AGENTES_CONTROLADORES_O_EN_LA_CENTRAL';
export const TR_HORA = 'TR_HORA';
export const TR_FECHA_DE_EMISION = 'TR_FECHA_DE_EMISION';
export const TR_PAGADO = 'TR_PAGADO';
export const TR_GASTOS_DE_GESTION = 'TR_GASTOS_DE_GESTION';
export const TR_VER_EN_PDF = 'TR_VER_EN_PDF';
export const TR_UNA_VEZ_SUPERADO_EL_TIEMPO_LA_DENUNCIA_NO_SE_PODRA_ANULAR_Y_PASARA_A_TRAMITE = 'TR_UNA_VEZ_SUPERADO_EL_TIEMPO_LA_DENUNCIA_NO_SE_PODRA_ANULAR_Y_PASARA_A_TRAMITE';
export const TR_FECHA = 'TR_FECHA';
export const TR_CONTACTO = 'TR_CONTACTO';
export const TR_DATOS_PAGO = 'TR_DATOS_PAGO';
export const TR_DATOS_ACTUALIZADOS_CORRECTAMENTE = 'TR_DATOS_ACTUALIZADOS_CORRECTAMENTE';
export const TR_SELECCIONA_UNA_IMAGEN = 'TR_SELECCIONA_UNA_IMAGEN';
export const TR_SOLO_SE_ADMITEN_ARCHIVOS_DE_IMAGEN = 'TR_SOLO_SE_ADMITEN_ARCHIVOS_DE_IMAGEN';
export const TR_DNI_O_CIF = 'TR_DNI_O_CIF';
export const TR_DATOS_DE_FACTURACION = 'TR_DATOS_DE_FACTURACION';
export const TR_FORMULARIO_DE_CONTACTO = 'TR_FORMULARIO_DE_CONTACTO';
export const TR_TIPO_DE_COMENTARIO = 'TR_TIPO_DE_COMENTARIO';
export const TR_ESCRIBE_AQUI_TU_SUGERENCIA = 'TR_ESCRIBE_AQUI_TU_SUGERENCIA';
export const TR_EL_TEXTO_NO_PUEDE_ESTAR_VACIO = 'TR_EL_TEXTO_NO_PUEDE_ESTAR_VACIO';
export const TR_EL_MENSAJE_HA_SIDO_ENVIADO = 'TR_EL_MENSAJE_HA_SIDO_ENVIADO';
export const TR_NOTIFICACIONES = 'TR_NOTIFICACIONES';
export const TR_IDIOMAS = 'TR_IDIOMAS';
export const TR_FINALIZACION_DE_ESTACIONAMIENTO = 'TR_FINALIZACION_DE_ESTACIONAMIENTO';
export const TR_SEGURIDAD = 'TR_SEGURIDAD';
export const TR_PIN_DE_SEGURIDAD = 'TR_PIN_DE_SEGURIDAD';
export const TR_NO_LO_RECUERDAS = 'TR_NO_LO_RECUERDAS';
export const TR_MIS_FACTURAS = 'TR_MIS_FACTURAS';
export const TR_FACTURAS = 'TR_FACTURAS';
export const TR_NO_TIENES_NINGUNA_FACTURA = 'TR_NO_TIENES_NINGUNA_FACTURA';
export const TR_HISTORICO_DE_FACTURAS = 'TR_HISTORICO_DE_FACTURAS';
export const TR_NO_TIENES_NINGUN_TICKET = 'TR_NO_TIENES_NINGUN_TICKET';
export const TR_PARKING = 'TR_PARKING';
export const TR_ESTIMADO = 'TR_ESTIMADO';
export const TR_PENDIENTE_DE_PAGO = 'TR_PENDIENTE_DE_PAGO';
export const TR_HISTORICO_DE_TICKETS = 'TR_HISTORICO_DE_TICKETS';
export const TR_MENSUAL = 'TR_MENSUAL';
