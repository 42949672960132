import * as React from "react";
import Screen from "./Screen";
import MenuWrapper from "./menu/MenuWrapper";
import {Redirect} from "react-router";
import {
    ROUTE_CONFIGURATION,
    ROUTE_CONTACT,
    ROUTE_CONTACT_SUGGESTION,
    ROUTE_HISTORY,
    ROUTE_PARK_SUMMARY,
    ROUTE_PERSONAL_AREA_CONTACT,
    ROUTE_START
} from "../../routing/Routes";
import AuthManager from "../../utils/AuthManager";
import NavbarTitleManager from "../../utils/NavbarTitleManager";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_AREA_PERSONAL,
    TR_CONFIGURACION,
    TR_CONTACTO,
    TR_ESTACIONAR,
    TR_FORMULARIO_DE_CONTACTO,
    TR_HISTORIAL
} from "../../I18n/constants";

export default abstract class LoggedScreen<P = {}, S = {}> extends Screen<P, S> {

    private readonly navbarTitleManager: NavbarTitleManager;

    public constructor(props: P, context: any) {
        super(props, context);
        this.navbarTitleManager = new NavbarTitleManager();
    }

    public render(): React.ReactNode {
        if (!AuthManager.isLogged()) {
            return <Redirect to={ROUTE_START}/>;
        }
        return (
            <MenuWrapper navbarTitle={this.getNavbarTileScreen()}>
                {this.renderScreen()}
            </MenuWrapper>
        )
    }

    protected setNavbarTitleFromRouteNameScreen(routeName: string): void {
        this.navbarTitleManager.setTitleFromRouteName(this.getNavbarTitleFromRoute(routeName));
    }

    protected getNavbarTileScreen(): string {
        return this.navbarTitleManager.getTitle();
    }

    private getNavbarTitleFromRoute(routeName: string): string {
        switch (routeName) {
            case ROUTE_PERSONAL_AREA_CONTACT:
                return I18nUtils.tr(TR_AREA_PERSONAL);
            case ROUTE_CONTACT:
                return I18nUtils.tr(TR_CONTACTO);
            case ROUTE_CONTACT_SUGGESTION:
                return I18nUtils.tr(TR_FORMULARIO_DE_CONTACTO);
            case ROUTE_CONFIGURATION:
                return I18nUtils.tr(TR_CONFIGURACION);
            case ROUTE_PARK_SUMMARY:
                return I18nUtils.tr(I18nUtils.tr(TR_ESTACIONAR));
            case ROUTE_HISTORY:
                return I18nUtils.tr(I18nUtils.tr(TR_HISTORIAL));

            default:
                return "";
        }
    }
}