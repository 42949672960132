import * as React from 'react';
import {Component, ReactNode} from 'react';
import classNames from 'classnames';

export interface IconProps {
    className?: string,
    icon?: string,
    children?: React.ReactNode,

    onClick?: (event: any) => void,
    onMouseOver?: (event: any) => void,
}

export default class Icon extends Component<IconProps> {

    public render(): ReactNode {
        const classComponent = classNames('material-icons', this.props.className);

        return (
            <i className={classComponent}
               onClick={this.props.onClick}
               onMouseOver={this.props.onMouseOver}
            >
                {this.props.icon || this.props.children}
            </i>
        );
    }

}

