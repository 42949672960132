import {ReducerBuilder} from "co-redux-builders";
import ParkTicket from "../../../model/ParkTicket";
import TaskParkTicketList from "../../../ws/park/TaskParkTicketList";
import ParkingMeterListViewActions from "./ParkingMeterListViewActions";


interface State {
    parkTicketLoading: boolean,
    parkTicketList: ParkTicket[],
    currentParkTicket?: ParkTicket,
}

const INITIAL_STATE: State = {
    parkTicketLoading: false,
    parkTicketList: [],
    currentParkTicket: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskParkTicketList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, parkTicketLoading: true}
    })
    .onEvent(TaskParkTicketList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, parkTicketLoading: false}
    })

    .onEvent(TaskParkTicketList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, parkTicketList: payload.data}
    })

    .onEvent(ParkingMeterListViewActions.setCurrentParkTicket, (oldState, payload) => {
        return {...oldState, currentParkTicket: payload.currentParkTicket}
    })

    .onEvent(ParkingMeterListViewActions.clearParkingMeterListViewReducer, () => INITIAL_STATE)

    .build();
