import {ReducerBuilder} from "co-redux-builders";
import UndergroundParkTicket from "../../../model/UndergroundParkTicket";
import TaskUndergroundParkTicketList from "../../../ws/undergroundParkTicket/TaskUndergroundParkTicketList";
import ParkingListViewActions from "./ParkingListViewActions";

interface State {
    undergroundParkTicketLoading: boolean,
    undergroundParkTicketList: UndergroundParkTicket[],
    currentParkTicket?: UndergroundParkTicket,
}

const INITIAL_STATE: State = {
    undergroundParkTicketLoading: false,
    undergroundParkTicketList: [],
    currentParkTicket: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskUndergroundParkTicketList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, undergroundParkTicketLoading: true}
    })
    .onEvent(TaskUndergroundParkTicketList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, undergroundParkTicketLoading: false}
    })

    .onEvent(TaskUndergroundParkTicketList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, undergroundParkTicketList: payload.data}
    })

    .onEvent(ParkingListViewActions.setCurrentParkTicket, (oldState, payload) => {
        return {...oldState, currentParkTicket: payload.currentParkTicket}
    })

    .onEvent(ParkingListViewActions.clearParkingListViewReducer, () => INITIAL_STATE)

    .build();
