
let BASE_URL = '';

switch (process.env.REACT_APP_ENV) {
    case 'prod':
        BASE_URL = 'https://vilanovamoviltik.cuatroochenta.com/webservice.php';
        break;
    default:
        BASE_URL = 'https://vilanovamoviltiktest.cuatroochenta.com/webservice.php';
        break;
}

export default class {

    // AUTH
    public static URL_LOGIN = `${BASE_URL}/moviltik-login`;
    public static URL_REGISTER = `${BASE_URL}/moviltik-register`;
    public static URL_RECOVER_PASSWORD = `${BASE_URL}/moviltik-recover-password`;
    public static URL_UPDATE_USER = `${BASE_URL}/moviltik-update-user`;
    public static URL_UPDATE_USER_ICON = `${BASE_URL}/moviltik-update-user-icon`;

    // MASTER DATA
    public static URL_RETRIEVE_MASTER_DATA = `${BASE_URL}/retrieve-master-data`;
    public static URL_TIMESTAMP = `${BASE_URL}/timestamp`;

    // TRANSLATIONS
    public static URL_RETRIEVE_TRANSLATIONS = `${BASE_URL}/retrieve-translations`;

    // SUGGESTIONS
    public static URL_SEND_SUGGESTION = `${BASE_URL}/send-suggestion`;

    // VEHICLES
    public static URL_VEHICLE_LIST = `${BASE_URL}/user-vehicle-list`;
    public static URL_VEHICLE_ADD = `${BASE_URL}/add-vehicle`;
    public static URL_VEHICLE_EDIT = `${BASE_URL}/edit-vehicle`;
    public static URL_VEHICLE_REMOVE = `${BASE_URL}/remove-vehicle`;

    // PARK
    public static URL_PARK_TICKET_LIST = `${BASE_URL}/park-ticket-list`;
    public static URL_PARK_CALCULATE_PRICE = `${BASE_URL}/calculate_price_park_car`;
    public static URL_LIST_MINUTES_FOR_CAR_PARK = `${BASE_URL}/list-minutes-for-car-park`;

    // CARD
    public static URL_CARD_LIST = `${BASE_URL}/user-card-list`;
    public static URL_CARD_ADD = `${BASE_URL}/add-user-card`;
    public static URL_CARD_EDIT = `${BASE_URL}/edit-user-card`;
    public static URL_CARD_REMOVE = `${BASE_URL}/remove-user-card`;

    // COMPLAINT
    public static URL_COMPLAINT_LIST = `${BASE_URL}/complaint-list`;
    public static URL_COMPLAINT_CANCEL = `${BASE_URL}/complaint-cancel`;
    public static URL_COMPLAINT_CODE_CANCEL = `${BASE_URL}/complaint-code-cancel`;

    // ASSOCIATIONS
    public static URL_ASSOCIATION_LIST = `${BASE_URL}/retrieve-associations`;

    // LEGAL STATUSES
    public static URL_LEGAL_STATUSES_LIST = `${BASE_URL}/retrieve-legal-statuses`;

    // UNDERGROUND PARKING
    public static URL_UNDERGROUND_PARK_TICKET_LIST = `${BASE_URL}/underground-park-ticket-list`;

    // INVOICE
    public static URL_INVOICE_LIST = `${BASE_URL}/retrieve-invoices`;
}
