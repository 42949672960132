import {ReducerBuilder} from "co-redux-builders";
import TaskUpdateUser from "../../../ws/auth/TaskUpdateUser";

interface State {
   loadingUploadUser: boolean,
}

const INITIAL_STATE: State = {
    loadingUploadUser: false
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUpdateUser.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingUploadUser: true}))
    .onEvent(TaskUpdateUser.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingUploadUser: false}))

    .build();
