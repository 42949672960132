import {ReducerBuilder} from "co-redux-builders";
import Invoice from "../../../model/Invoice";
import TaskRetrieveInvoices from "../../../ws/invoices/TaskRetrieveInvoices";


interface State {
    billingLoading: boolean,
    billingList: Invoice[],
}

const INITIAL_STATE: State = {
    billingLoading: false,
    billingList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskRetrieveInvoices.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, billingLoading: true}
    })
    .onEvent(TaskRetrieveInvoices.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, billingLoading: false}
    })

    .onEvent(TaskRetrieveInvoices.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, billingList: payload.data}
    })

    .build();
