import {ReduxStorageUtils} from "co-redux-builders";
import {push} from "react-router-redux";

export function generateRoute(route: string, params: object = {}): string{
    let routeStr = route;
    for (const key in params) {
        // @ts-ignore: type
        if (key && params[key]) {
            // @ts-ignore: type
            routeStr = routeStr.replace(String(':' + key).trim(), String(params[key]).trim());
        }
    }
    return routeStr;
}

export function generateRouteWithParams(route: string, params: object = {}): string {
    const ret = [];
    for (let param in params)
    {
        // @ts-ignore
        ret.push(encodeURIComponent(param) + '=' + encodeURIComponent(params[param]));
    }
    return route + '?' + ret.join('&');
}

export function goToRoute(route: string, params: object = {}): void {
    const routeWithParams = generateRoute(route, params);
    ReduxStorageUtils.dispatch(push(routeWithParams));
}
