import * as React from "react";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Row from "../../components/Row";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_APELLIDOS,
    TR_APELLIDOS_OBLIGATORIO,
    TR_ASOCIACION,
    TR_CIF_O_DNI,
    TR_CIF_O_DNI_OBLIGATORIO,
    TR_CIF_O_NIF_INVALIDO,
    TR_CODIGO_POSTAL,
    TR_CODIGO_POSTAL_INVALIDO,
    TR_CODIGO_POSTAL_OBLIGATORIO,
    TR_CONTRASENA,
    TR_CONTRASENA_OBLIGATORIA,
    TR_DATOS_DEL_COMERCIO,
    TR_DATOS_USUARIO,
    TR_DIRECCION,
    TR_DIRECCION_OBLIGATORIA,
    TR_DNI,
    TR_DNI_INVALIDO,
    TR_DNI_OBLIGATORIO,
    TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO,
    TR_EMAIL,
    TR_EMAIL_OBLIGATORIO,
    TR_ESTADO_LEGAL_OBLIGATORIO,
    TR_FORMA_LEGAL,
    TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO,
    TR_GUARDAR,
    TR_NOMBRE,
    TR_NOMBRE_COMERCIO,
    TR_NOMBRE_OBLIGATORIO,
    TR_TELEFONO,
    TR_TELEFONO_OBLIGATORIO
} from "../../I18n/constants";
import FormInput from "../../components/form/FormInput";
import {isEmailValid, isNifCompanyValid, isNifNiePersonValid, isPostalCodeValid} from "../../utils/StringUtils";
import Button from "../../components/Button";
import FormSelect from "../../components/form/FormSelect";
import Association from "../../model/Association";
import LegalStatuses from "../../model/LegalStatuses";
import FormInputOption from "../../components/form/FormInputOption";

const FORM_NAME = "registerMerchantForm";

export interface MerchantFormData {
    username: string, //email
    name: string,
    lastName: string,
    dni: string,
    password: string,
    userPhone: string,

    shopName: string,
    cif: string,
    shopPhone: string,
    address: string,
    postalCode: string,
    associationId: string
    associationNumber: string,
    legalStatusId: string,
}

enum MerchantFormFields {
    // usuario
    USERNAME = "username", // email
    NAME = "name",
    LAST_NAME = "lastName",
    DNI = "dni",
    PASSWORD = "password",
    PHONE = "userPhone",
    // comercio
    SHOP_NAME = "shopName",
    CIF = "cif",
    SHOP_PHONE = "shopPhone",
    ADDRESS = "address",
    POSTAL_CODE = "postalCode",
    ASSOCIATION_ID = "associationId",
    ASSOCIATION_NUMBER = "associationNumber",
    LEGAL_STATUS_ID = "legalStatusId",
}

interface MerchantFormProps {
    onSubmit: (data: MerchantFormData) => void,
    initialValues?: Partial<MerchantFormData>,
    associations: Association[],
    legalStatuses: LegalStatuses[],
}

type Props = InjectedFormProps<MerchantFormData> & MerchantFormProps;

class RegisterMerchantForm extends React.Component<Props> {

    public render(): React.ReactNode {
        const {handleSubmit, onSubmit, invalid, pristine, initialValues, associations, legalStatuses} = this.props;

        const associationOptions: FormInputOption[] = associations.map((association: Association) =>
            ({value: association.id.toString(), name: association.name}));
        const legalStatusOptions: FormInputOption[] = legalStatuses.map((legalStatus: LegalStatuses) =>
            ({value: legalStatus.id.toString(), name: legalStatus.name}));

        return (
            <form className="form-signin"
                  id={"form-signin"}
                  onSubmit={handleSubmit(onSubmit)}>

                {/* Datos usuario ******************************************/}
                <h4>{I18nUtils.tr(TR_DATOS_USUARIO)}</h4>

                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.NAME}
                        label={I18nUtils.tr(TR_NOMBRE)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_NOMBRE)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.LAST_NAME}
                        label={I18nUtils.tr(TR_APELLIDOS)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_APELLIDOS)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.USERNAME}
                        label={I18nUtils.tr(TR_EMAIL)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_EMAIL)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.DNI}
                        label={I18nUtils.tr(TR_DNI)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_DNI)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"tel"}
                        name={MerchantFormFields.PHONE}
                        label={I18nUtils.tr(TR_TELEFONO)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_TELEFONO)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"password"}
                        name={MerchantFormFields.PASSWORD}
                        label={I18nUtils.tr(TR_CONTRASENA)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_CONTRASENA)}
                    />
                </Row>

                {/* Datos comercio ******************************************/}
                <h4 className={"m-t-20"}>{I18nUtils.tr(TR_DATOS_DEL_COMERCIO)}</h4>

                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.SHOP_NAME}
                        label={I18nUtils.tr(TR_NOMBRE_COMERCIO)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_NOMBRE_COMERCIO)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.CIF}
                        label={I18nUtils.tr(TR_CIF_O_DNI)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_CIF_O_DNI)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"tel"}
                        name={MerchantFormFields.SHOP_PHONE}
                        label={I18nUtils.tr(TR_TELEFONO)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_TELEFONO)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.ADDRESS}
                        label={I18nUtils.tr(TR_DIRECCION)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_DIRECCION)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        type={"text"}
                        name={MerchantFormFields.POSTAL_CODE}
                        label={I18nUtils.tr(TR_CODIGO_POSTAL)}
                        component={FormInput}
                        lineMode={true}
                        placeholder={I18nUtils.tr(TR_CODIGO_POSTAL)}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        name={MerchantFormFields.LEGAL_STATUS_ID}
                        label={I18nUtils.tr(TR_FORMA_LEGAL)}
                        component={FormSelect}
                        lineMode={true}
                        options={legalStatusOptions}
                    />
                </Row>
                <Row className={"m-t-20"}>
                    <Field
                        name={MerchantFormFields.ASSOCIATION_ID}
                        label={I18nUtils.tr(TR_ASOCIACION)}
                        component={FormSelect}
                        lineMode={true}
                        options={associationOptions}
                    />
                </Row>

                {/* DESHABILITADO */}
                {/*<Row className={"m-t-20"}>*/}
                {/*    <Field*/}
                {/*        name={MerchantFormFields.ASSOCIATION_NUMBER}*/}
                {/*        label={I18nUtils.tr(TR_NUMERO_DE_ASOCIADO)}*/}
                {/*        component={FormInput}*/}
                {/*        lineMode={true}*/}
                {/*        placeholder={I18nUtils.tr(TR_NUMERO_DE_ASOCIADO)}*/}
                {/*    />*/}
                {/*</Row>*/}
                <Button
                    className={"btn btn-lg btn-form btn-submit btn-center m-t-60"}
                    text={I18nUtils.tr(initialValues ? TR_GUARDAR : TR_ACEPTAR).toUpperCase()}
                    block={true}
                    type={"submit"}
                    disabled={invalid || pristine}
                />
            </form>

        )
    }
}


function validate(values: MerchantFormData) {
    const errors: FormErrors<MerchantFormData> = {};

    // validación datos usuario
    if (!values.username || values.username.length === 0) {
        errors.username = I18nUtils.tr(TR_EMAIL_OBLIGATORIO)
    } else if (!isEmailValid(values.username)) {
        errors.username = I18nUtils.tr(TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO)
    }
    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO)
    }
    if (!values.lastName || values.lastName.length === 0) {
        errors.lastName = I18nUtils.tr(TR_APELLIDOS_OBLIGATORIO)
    }
    if (!values.dni || values.dni.length === 0) {
        errors.dni = I18nUtils.tr(TR_DNI_OBLIGATORIO)
    } else if (!isNifNiePersonValid(values.dni)) {
        errors.dni = I18nUtils.tr(TR_DNI_INVALIDO)
    }
    if (!values.password || values.password.length === 0) {
        errors.password = I18nUtils.tr(TR_CONTRASENA_OBLIGATORIA)
    }
    if (!values.userPhone || values.userPhone.length === 0) {
        errors.userPhone = I18nUtils.tr(TR_TELEFONO_OBLIGATORIO)
    } else if (values.userPhone.length !== 9 || !/^\d+$/.test(values.userPhone)) {
        errors.userPhone = I18nUtils.tr(TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO)
    }

    // validación datos comercio
    if (!values.shopName || values.shopName.length === 0) {
        errors.shopName = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO)
    }
    if (!values.cif || values.cif.length === 0) {
        errors.cif = I18nUtils.tr(TR_CIF_O_DNI_OBLIGATORIO)
    } else if (!isNifCompanyValid(values.cif) && !isNifNiePersonValid(values.cif)) {
        errors.cif = I18nUtils.tr(TR_CIF_O_NIF_INVALIDO);
    }
    if (!values.shopPhone || values.shopPhone.length === 0) {
        errors.shopPhone = I18nUtils.tr(TR_TELEFONO_OBLIGATORIO)
    } else if (values.shopPhone.length !== 9 || !/^\d+$/.test(values.shopPhone)) {
        errors.shopPhone = I18nUtils.tr(TR_FORMATO_DE_NUMERO_DE_TELEFONO_INVALIDO)
    }
    if (!values.address || values.address.length === 0) {
        errors.address = I18nUtils.tr(TR_DIRECCION_OBLIGATORIA)
    }
    if (!values.postalCode || values.postalCode.length === 0) {
        errors.postalCode = I18nUtils.tr(TR_CODIGO_POSTAL_OBLIGATORIO)
    } else if (!isPostalCodeValid(values.postalCode)) {
        errors.postalCode = I18nUtils.tr(TR_CODIGO_POSTAL_INVALIDO)
    }
    if (!values.legalStatusId || values.legalStatusId === '-1') {
        errors.legalStatusId = I18nUtils.tr(TR_ESTADO_LEGAL_OBLIGATORIO)
    }
    return errors;
}

export default reduxForm<MerchantFormData, MerchantFormProps>({
    validate,
    form: FORM_NAME,
    enableReinitialize: true
})(RegisterMerchantForm as any) as unknown as React.ComponentType<MerchantFormProps>;