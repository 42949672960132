import * as React from "react";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_DISPONIBLE_EN,
    TR_INICIAR_SESION,
    TR_OLVIDATE_DE_LAS_MONEDAS,
    TR_SIN_RECARGAS_NI_ABONO_PREVIO,
    TR_WEB
} from "../../I18n/constants";
import Button from "../../components/Button";
import {goToRoute} from "../../utils/Router";
import {ROUTE_LOGIN} from "../../routing/Routes";
import UnloggedScreen from "../../base/screens/UnloggedScreen";
import IconBase from "../../components/IconSVG/IconBase";
import {AppIconSVG} from "../../components/IconSVG/AppIconSVG";
import Logo from "../../res/img/logo.png";
import Config from "../../Config";

export default class StartScreen extends UnloggedScreen<{}> {

    public componentDidMount(): void {
        const rootNode = document.getElementById("root");
        if (!!rootNode) {
            rootNode.className = "start-background";
        }
    }

    public componentWillUnmount(): void {
        const rootNode = document.getElementById("root");
        if (!!rootNode) {
            rootNode.className = "";
        }
    }

    public renderScreen(): React.ReactNode {

        return (
            <Card loading={false}>
                <CardBody className={"start-container"}>
                    <div className={"header p-t-100"}>
                        <img src={Logo} alt={"logo"}/>
                    </div>

                    <div className={"slogan"}>
                        <p className={"title"}>{I18nUtils.tr(TR_OLVIDATE_DE_LAS_MONEDAS)}</p>
                        <p className={"subtitle"}>{I18nUtils.tr(TR_SIN_RECARGAS_NI_ABONO_PREVIO)}</p>
                    </div>

                    <div className={'platforms'}>
                        <p>{`${I18nUtils.tr(TR_DISPONIBLE_EN)}:`}</p>
                        <a href={"https://play.google.com/store/apps/details?id=com.vilanova.app"}>
                            <IconBase icon={AppIconSVG.GOOGLE_PLAY.icon} className={"icon-platform icon-google-play"}/>
                        </a>
                        <a href={"https://apps.apple.com/es/app/id1511535550"}>
                            <IconBase icon={AppIconSVG.APPLE_STORE.icon}
                                      iconBaseProps={AppIconSVG.APPLE_STORE.iconProps}
                                      className={"icon-platform"}/>
                        </a>
                    </div>

                    <div className={"social-networks"}>
                        <div className={"social-container"}>
                            <span className={"social"}>
                                <a href={Config.SVG_WEB_URL}>
                                     <IconBase icon={AppIconSVG.WEB.icon} className={"icon-social"}/>
                                    <p className={"social-name"}>{I18nUtils.tr(TR_WEB)}</p>
                                </a>
                            </span>
                        </div>
                    </div>

                    <div className={"footer"}>
                        <Button
                            className={"btn btn-start btn-login"}
                            text={I18nUtils.tr(TR_INICIAR_SESION)}
                            block={true}
                            onClick={() => goToRoute(ROUTE_LOGIN)}
                        />
                    </div>
                </CardBody>
            </Card>
        )
    }
}