import * as React from "react";
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {ReactComponent as BackgroundCarSVG} from "../../res/icons/ilustracion-coche.svg";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import ModalHeaderBack from "../../components/modal/ModalHeaderBack";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_A,
    TR_DE,
    TR_FECHA_DE_EMISION,
    TR_GASTOS_DE_GESTION,
    TR_HISTORIAL,
    TR_HORA,
    TR_VER_EN_PDF
} from "../../I18n/constants";
import {AppIconSVG} from "../../components/IconSVG/AppIconSVG";
import IconBase from "../../components/IconSVG/IconBase";
import {setFirstCharToUpperCase} from "../../utils/StringUtils";

interface Props extends BaseModalProps {
    startTime: string,
    endTime?: string
    startDate: string,
    paidAmount: string,
    commission: string,
    licplateWithDescription: React.ReactNode,
    zone: React.ReactNode | string,
    pdfUrl: string,
    show: boolean,
    onClose: () => void,
    state: string,
}

export default class ParkingTicketDetailModal extends BaseModal<Props> {

    public render(): React.ReactNode {
        const {onClose, show, startTime, endTime, startDate, paidAmount, licplateWithDescription, zone, pdfUrl, commission, state} = this.props;

        const time = endTime ? <>{`${setFirstCharToUpperCase(I18nUtils.tr(TR_DE))} ${startTime}h`}<br/>{`${I18nUtils.tr(TR_A)} ${endTime}h`}</> : `${startTime}h`;

        return (
            <>
                <Modal onHide={onClose} show={show} className={"full-screen modal-right-pane"}>
                    <ModalHeaderBack title={I18nUtils.tr(TR_HISTORIAL)}
                                     onBack={onClose}
                                     whiteStyle={true}
                    />

                    <ModalBody className={"transaction-detail-container light"}>

                        <div className={"boxes-info"}>
                            <div className={"box m-r-15"}>
                                <div className={"text"}>{I18nUtils.tr(TR_HORA)}</div>
                                <div className={"data-time"}>
                                    {time}
                                </div>
                                <br/>
                                <div className={"text"}>{I18nUtils.tr(TR_FECHA_DE_EMISION)}</div>
                                <div className={"data emission"}>
                                    {startDate}
                                </div>
                            </div>

                            <div className={"box m-l-15"}>
                                <div className={"text align-right"}>{state}</div>
                                <div className={"data"}>
                                    {`${paidAmount}€`}
                                </div>
                                {commission && commission !== "0.00" && <div
                                    className={"commission"}>{`${I18nUtils.tr(TR_GASTOS_DE_GESTION)}: ${commission}€`}</div>}
                            </div>
                        </div>

                        <div className={"info-section"}>
                            <div className={"section"}>
                                <IconBase icon={AppIconSVG.CAR.icon}
                                          className={"icon"}
                                />
                                <div className={"text"}>
                                    {licplateWithDescription}
                                </div>
                            </div>

                            <div className={"section m-t-20"}>
                                <IconBase icon={AppIconSVG.STAR.icon}
                                          className={"icon"}
                                />
                                <div className={"text"}>
                                    {zone}
                                </div>
                            </div>
                        </div>

                        <hr className={"bar"}/>

                        {pdfUrl && pdfUrl.length !== 0 &&
                        <div className={"show-pdf pointer"}
                             onClick={() => window.open(pdfUrl, "_blank")}>
                            {I18nUtils.tr(TR_VER_EN_PDF).toUpperCase()}
                        </div>}

                    </ModalBody>

                    <ModalFooter className={"transaction-detail-container footer-img-car"}>

                        <div className={"img-content"}>
                            <BackgroundCarSVG viewBox={"-70 -80 587 237"}/>
                        </div>

                    </ModalFooter>


                </Modal>

            </>
        )
    }
}