import * as React from "react";
import LoggedScreen from "../../base/screens/LoggedScreen";
import CardBody from "../../components/card/CardBody";
import Card from "../../components/card/Card";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_ESTACIONAR, TR_MENSUAL, TR_PARKING} from "../../I18n/constants";
import {RouteProps} from "react-router";
import {ROUTE_HISTORY} from "../../routing/Routes";
import BillingListView from "./billing/BillingListView";
import ParkingMeterListView from "./parkingMeter/ParkingMeterListView";
import ParkingListView from "./parking/ParkingListView";
import MasterDataReducer from "../../redux/reducers/MasterDataReducer";
import {connect} from "react-redux";
import TaskMasterData from "../../ws/masterdata/TaskMasterData";

export type HistorySection = 'parkingMeter' | 'publicParking' | 'billing';

interface State {
    currentActiveSection: HistorySection,
}

const mapStateToProps = MasterDataReducer.autoMapToProps();

type Props = RouteProps & typeof mapStateToProps;

class HistoryScreen extends LoggedScreen<Props, State> {

    public constructor(props: Props, context: any) {
        super(props, context);
        this.setNavbarTitleFromRouteNameScreen(ROUTE_HISTORY);
        this.state = {
            currentActiveSection: 'parkingMeter',
        }
    }

    public componentDidMount(): void {
        new TaskMasterData(1).execute();
    }

    private setCurrentActiveSection = (section: HistorySection): void => {
        this.setState({currentActiveSection: section})
    };

    public renderScreen(): React.ReactNode {
        const {loading} = this.props;
        const {currentActiveSection} = this.state;

        return (
            <Card loading={loading}>
                <CardBody>
                    <div className={"history-container"}>

                        <div className={"section-container"}>
                            <div className={`section ${currentActiveSection === "parkingMeter" ? "active" : ""}`}
                                 onClick={() => this.setCurrentActiveSection("parkingMeter")}>
                                <div className={"font-middle-bold m-t-5"}>{I18nUtils.tr(TR_ESTACIONAR).toUpperCase()}</div>
                            </div>

                            <div
                                className={`section ${currentActiveSection === "publicParking" ? "active" : ""}`}
                                onClick={() => this.setCurrentActiveSection("publicParking")}>
                                <div className={"font-middle-bold m-t-5"}>{I18nUtils.tr(TR_PARKING).toUpperCase()}</div>
                            </div>

                            <div className={`section ${currentActiveSection === "billing" ? "active" : ""}`}
                                 onClick={() => this.setCurrentActiveSection("billing")}>
                                <div
                                    className={"font-middle-bold m-t-5"}>{I18nUtils.tr(TR_MENSUAL).toUpperCase()}</div>
                            </div>
                        </div>

                        {currentActiveSection === "parkingMeter" && this.renderParkingMeterSection()}
                        {currentActiveSection === "publicParking" && this.renderParkingSection()}
                        {currentActiveSection === "billing" && this.renderBillingSection()}
                    </div>
                </CardBody>
            </Card>
        )
    }

    private renderParkingSection(): React.ReactNode {
        return (
            <ParkingListView/>
        )
    }

    private renderParkingMeterSection(): React.ReactNode {
        return (
            <ParkingMeterListView/>
        )
    }

    private renderBillingSection(): React.ReactNode {
        return (
            <BillingListView/>
        )
    }
}

export default connect(mapStateToProps)(HistoryScreen as unknown as React.ComponentType<{}>)