import {ReducerBuilder} from "co-redux-builders";
import MasterData, {City} from "../../model/MasterData";
import TaskMasterData from "../../ws/masterdata/TaskMasterData";
import MasterDataActions from "./MasterDataActions";

interface State {
    masterData?: MasterData,
    masterDataError?: string,

    loading: boolean,

    cities: City[],
    park_machines: number[][]
}

const INITIAL_STATE: State = {
    masterData: undefined,
    masterDataError: undefined,

    loading: false,

    cities: [],
    park_machines: []
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskMasterData.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskMasterData.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskMasterData.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const citiesOptions: City[] = payload.data.cities
            .filter((city: any) => city.active || city.active_underground)
            .sort((a: City, b: City) => a.name.localeCompare(b.name));

        return {
            ...oldState,
            masterData: payload.data,
            masterDataError: undefined,
            cities: citiesOptions,
            park_machines: payload.data.park_machines
        }
    })
    .onEvent(TaskMasterData.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {
            ...oldState,
            masterDataError: payload,
        }
    })

    .onEvent(MasterDataActions.clearMasterDataReducer, () => INITIAL_STATE)

    .build();
