import * as React from "react";
import IconBase from "../../../components/IconSVG/IconBase";
import {AppIconSVG} from "../../../components/IconSVG/AppIconSVG";

interface NavBarProps {
    sideBarCollapsedHandler: () => void,
    navbarTitle: string,
}

type Props = NavBarProps;

interface State {
    expandMenu: boolean,
}

export default class NavBar extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            expandMenu: false,
        }
    }

    // private handleNotifications = () => {
    // };

    public render(): React.ReactNode {
        return (
            <nav className={"navbar expanded-menu navbar-nav"}>
                {this.state.expandMenu ?
                    <div>{this.renderNavbar()}</div> : this.renderNavbar()
                }
            </nav>
        )
    }

    private renderNavbar(): React.ReactNode {
        const {sideBarCollapsedHandler, navbarTitle} = this.props;

        return (
            <div className={"menu-container"}>
                <div className={"icon-container"}>
                    <IconBase icon={AppIconSVG.MENU.icon}
                              className={"icon-menu"}
                              onClick={sideBarCollapsedHandler}/>
                </div>

                <div className={"title-container"}>
                    <h4>{navbarTitle}</h4>
                </div>

                {/*<div className={"container-notification"}>*/}

                {/*    <div className={"icon-notification"} onClick={this.handleNotifications}>*/}
                {/*        <IconBase icon={AppIconSVG.NOTIFICATION.icon}*/}
                {/*                  className={"icon-bell"}*/}
                {/*        />*/}
                {/*        <div className={"notification-size"}>5</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        )
    }
}