import {ReducerBuilder} from "co-redux-builders";
import Association from "../../model/Association";
import LegalStatuses from "../../model/LegalStatuses";
import TaskRetrieveAssociations from "../../ws/retrieveAssociations/TaskRetrieveAssociations";
import TaskRetrieveLegalStatuses from "../../ws/retrieveLegalStatuses/TaskRetrieveLegalStatuses";
import RegisterMerchantScreenActions from "./RegisterMerchantScreenActions";
import TaskMerchantRegister from "../../ws/merchantRegistration/TaskMerchantRegister";

interface State {
    loadingAssociations: boolean,
    listAssociations: Association[],
    loadingLegalStatuses: boolean,
    listLegalStatuses: LegalStatuses[],
    loadingMerchantRegister: boolean,

    acceptedTerms: boolean,
    acceptedCommunications: boolean,
    showTermsModal: boolean,
    showExtendedTermsModal: boolean,
    showCommunicationsModal: boolean,

    currentFormData?: any,

}

const INITIAL_STATE: State = {
    loadingAssociations: false,
    listAssociations: [],
    loadingLegalStatuses: false,
    listLegalStatuses: [],
    loadingMerchantRegister: false,

    acceptedTerms: false,
    acceptedCommunications: false,
    showTermsModal: false,
    showExtendedTermsModal: false,
    showCommunicationsModal: false,

    currentFormData: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskRetrieveAssociations.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loadingAssociations: true}
    })
    .onEvent(TaskRetrieveAssociations.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loadingAssociations: false}
    })
    .onEvent(TaskRetrieveAssociations.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, listAssociations: payload.data}
    })

    .onEvent(TaskRetrieveLegalStatuses.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loadingLegalStatuses: true}
    })
    .onEvent(TaskRetrieveLegalStatuses.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loadingLegalStatuses: false}
    })
    .onEvent(TaskRetrieveLegalStatuses.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, listLegalStatuses: payload.data}
    })

    .onEvent(TaskMerchantRegister.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, loadingMerchantRegister: true}
    })
    .onEvent(TaskMerchantRegister.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, loadingMerchantRegister: false}
    })

    // Actions *******************
    .onEvent(RegisterMerchantScreenActions.onShowTermsModal, (oldState) => {
        return {...oldState, showTermsModal: true}
    })
    .onEvent(RegisterMerchantScreenActions.onHideTermsModal, (oldState) => {
        return {...oldState, showTermsModal: false}
    })

    .onEvent(RegisterMerchantScreenActions.onShowExtendedTermsModal, (oldState) => {
        return {...oldState, showExtendedTermsModal: true}
    })
    .onEvent(RegisterMerchantScreenActions.onHideExtendedTermsModal, (oldState) => {
        return {...oldState, showExtendedTermsModal: false}
    })

    .onEvent(RegisterMerchantScreenActions.onShowCommunicationsModal, (oldState) => {
        return {...oldState, showCommunicationsModal: true}
    })
    .onEvent(RegisterMerchantScreenActions.onHideCommunicationsModal, (oldState) => {
        return {...oldState, showCommunicationsModal: false}
    })

    .onEvent(RegisterMerchantScreenActions.setAcceptedTerms, (oldState) => {
        return {...oldState, acceptedTerms: !oldState.acceptedTerms}
    })
    .onEvent(RegisterMerchantScreenActions.setAcceptedCommunications, (oldState) => {
        return {...oldState, acceptedCommunications: !oldState.acceptedCommunications}
    })

    .onEvent(RegisterMerchantScreenActions.setCurrentFormData, (oldState, payload) => {
        return {...oldState, currentFormData: payload.currentFormData}
    })

    .onEvent(RegisterMerchantScreenActions.clearRegisterMerchantScreenReducer, (oldState) => INITIAL_STATE)

    .build();
