import * as React from "react";
import {Modal} from "react-bootstrap";
import IconBase from "../IconSVG/IconBase";
import {AppIconSVG} from "../IconSVG/AppIconSVG";

interface ModalHeaderTitleProps {
    title: string,
    onBack: () => void,
    iconRight?: React.ReactNode,
    whiteStyle?: boolean,
}

export default class ModalHeaderTitle extends React.Component<ModalHeaderTitleProps> {

    public render(): React.ReactNode {
        const {title, iconRight, onBack, whiteStyle} = this.props;

        return (
            <Modal.Header className={`modal-back-header ${whiteStyle ? "white" : ""}`}>
                <button type="button" className="close" onClick={onBack}>
                    <IconBase icon={AppIconSVG.ARROW_LEFT.icon} className={"icon icon-back"}/>
                </button>
                <Modal.Title>
                    <h4>{title}</h4>
                </Modal.Title>
                {iconRight}
            </Modal.Header>
        )
    }
}