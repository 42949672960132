import * as React from 'react';
import {Component, ReactNode} from 'react';
import Button from "../Button";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_CANCELAR} from "../../I18n/constants";

export interface CardDeleteFooterProps {
    okTitle: string,
    cancelTitle?: string,
    okHandler: () => void,
    cancelHandler: () => void,
    disableOk?: boolean,
    classNameButton?: string,
}

export default class CardOkCancelFooter extends Component<CardDeleteFooterProps> {

    public render(): ReactNode {
        const {
            okTitle, okHandler, cancelTitle = I18nUtils.tr(TR_CANCELAR), cancelHandler, disableOk = false, classNameButton
        } = this.props;
        return (
            <div className={"form-footer-container confirm-btns"}>
                <Button
                    text={cancelTitle}
                    onClick={cancelHandler}
                    type={'button'}
                    block={true}
                    className={`btn btn-lg btn-form float-left btn-cancel ${classNameButton}`}
                />
                <Button
                    text={okTitle}
                    onClick={okHandler}
                    type={'button'}
                    block={true}
                    className={`btn btn-lg btn-form float-right m-l-15 btn-submit ${classNameButton}`}
                    disabled={disableOk}
                />
            </div>
        );
    }
}

