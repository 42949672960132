import * as React from 'react';
import {ReducerBuilder} from "co-redux-builders";
import {connect} from "react-redux";
import I18nUtils from "../../../I18n/I18nUtils";
import MasterDataReducer from "../../../redux/reducers/MasterDataReducer";
import {TR_HISTORICO_DE_TICKETS, TR_NO_TIENES_NINGUN_TICKET} from "../../../I18n/constants";
import {AppIconSVG} from "../../../components/IconSVG/AppIconSVG";
import IconBase from "../../../components/IconSVG/IconBase";
import TaskRetrieveInvoices from "../../../ws/invoices/TaskRetrieveInvoices";
import BillingListViewReducer from "./BillingListViewReducer";
import LoadingView from "../../../base/loading/LoadingView";
import DateFormatter from "../../../utils/DateFormatter";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    BillingListViewReducer.autoMapToProps(),
    MasterDataReducer.autoMapToProps(),
);

type Props = typeof mapStateToProps;

class BillingListView extends React.Component<Props> {

    public componentDidMount(): void {
        new TaskRetrieveInvoices().execute();
    }

    public render(): React.ReactNode {
        const {billingLoading, billingList} = this.props;

        if (billingLoading) {
            return <LoadingView loading={true}/>
        }

        return (
            <div className={"billing-container"}>
                {billingList.length !== 0 ? this.renderBillings() :
                    <div
                        className={"empty-text"}>{`${I18nUtils.tr(TR_NO_TIENES_NINGUN_TICKET)}.`}
                    </div>}
            </div>
        )
    }

    private renderBillings(): React.ReactNode {
        const {billingList} = this.props;

        return (
            <div className={"list-billing"}>
                <div className={"title m-b-20"}>{I18nUtils.tr(TR_HISTORICO_DE_TICKETS).toUpperCase()}</div>

                {billingList.map((billing, index) => {
                    return (
                        <div key={index}
                             className={"billing"}>

                            <div className={"data-container"}>
                                <div className={"date"}>{DateFormatter.formatTimestampToDate(billing.date)}</div>
                                <div className={"site"}>{billing.type}</div>
                            </div>

                            <div className={"icon-container"}>
                                <IconBase icon={AppIconSVG.DOWNLOAD.icon}
                                          className={"icon-download"}
                                          onClick={() => window.open(billing.pdf, "_blank")}/>
                            </div>

                        </div>
                    )
                })}

            </div>)

    }

}

export default connect(mapStateToProps)(BillingListView as unknown as React.ComponentType<{}>);