import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import User, {UserRegister} from "../../model/User";
import {Auth} from "../../utils/AuthManager";
import AuthRequest from "../AuthRequest";

export interface TaskUpdateUserRequest extends AuthRequest{
    user: Partial<UserRegister>,
}

export default class TaskUpdateUser extends BaseGenericRequest<TaskUpdateUserRequest, TaskResponse<User>> {

    private readonly data: Partial<UserRegister>;

    public constructor(data: Partial<UserRegister>) {
        super(METHOD.METHOD_POST, Urls.URL_UPDATE_USER);
        this.data = data;
    }

    protected getRequest(): TaskUpdateUserRequest {
        return {
            ...this.getAuth() as Auth,
            user: {
                ...this.data,
            }
        }
    }
}