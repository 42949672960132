import * as React from "react";
import {UserRegister} from "../../../model/User";
import UserForm, {UserFormData} from "./UserForm";
import TaskUpdateUser from "../../../ws/auth/TaskUpdateUser";
import TaskLogin from "../../../ws/auth/TaskLogin";
import md5 from "md5";
import AuthManager from "../../../utils/AuthManager";
import Alert from "../../../base/alerts/Alert";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_DATOS_ACTUALIZADOS_CORRECTAMENTE} from "../../../I18n/constants";
import UserContactSectionViewReducer from "./UserContactSectionViewReducer";
import {connect} from "react-redux";
import LoadingView from "../../../base/loading/LoadingView";

export interface ContactSectionProps {
    initialValues: Partial<UserRegister>
}

const mapStateToProps = UserContactSectionViewReducer.autoMapToProps();

type Props = ContactSectionProps & typeof mapStateToProps;

class UserContactSectionView extends React.Component<Props> {

    private handleSubmit = (values: UserFormData): void => {
        new TaskUpdateUser(values)
            .onSuccess((response) => {
                const auth = AuthManager.getAuth();
                const password = values.password ? md5(values.password) : auth && auth.password;
                password && new TaskLogin(values.username, password).execute();
                if (values.password) {
                    AuthManager.setAuth(values.username, md5(values.password));
                }
                Alert.success(I18nUtils.tr(TR_DATOS_ACTUALIZADOS_CORRECTAMENTE));
            })
            .onFail((message) => Alert.error(message))
            .execute();
    };

    public render(): React.ReactNode {
        const {loadingUploadUser} = this.props;

        if (loadingUploadUser) {
            return <LoadingView loading={true}/>
        }

        return (
            <UserForm parentLoading={false}
                      onSubmit={this.handleSubmit}
                      initialValues={this.props.initialValues}
                      lineMode={false}
            />
        )
    }
}

export default connect(mapStateToProps)(UserContactSectionView as unknown as React.ComponentType<ContactSectionProps>)