import {ActionBuilder} from "co-redux-builders";

export default ActionBuilder.build({
    onShowTermsModal: () =>({}),
    onHideTermsModal: () => ({}),

    onShowExtendedTermsModal: () => ({}),
    onHideExtendedTermsModal: () => ({}),

    onShowCommunicationsModal: () => ({}),
    onHideCommunicationsModal: () => ({}),

    setAcceptedTerms: () => ({}),
    setAcceptedCommunications: () => ({}),

    setCurrentFormData: (currentFormData: any) => ({currentFormData}),

    clearRegisterMerchantScreenReducer: () => ({}),
});