import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Auth} from "../../utils/AuthManager";
import UndergroundParkTicket from "../../model/UndergroundParkTicket";

export interface TaskUndergroundParkTicketRequest extends Auth {
    city_id?: number,
}

export default class TaskUndergroundParkTicketList extends BaseGenericRequest<Auth, TaskResponse<UndergroundParkTicket[]>> {

    private data: {cityId?: number};

    public constructor(cityId?: number) {
        super(METHOD.METHOD_POST, Urls.URL_UNDERGROUND_PARK_TICKET_LIST);
        this.data = {cityId: cityId}
    }

    protected getRequest(): TaskUndergroundParkTicketRequest {
        return {
            ...this.getAuth() as Auth,
            city_id: this.data.cityId ? this.data.cityId : undefined,
        };
    }
}