import * as React from "react";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Row from "../../../components/Row";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_CIF_O_DNI_OBLIGATORIO,
    TR_CIF_O_NIF_INVALIDO,
    TR_CIUDAD,
    TR_CIUDAD_OBLIGATORIA,
    TR_CODIGO_POSTAL,
    TR_CODIGO_POSTAL_INVALIDO,
    TR_CODIGO_POSTAL_OBLIGATORIO,
    TR_DIRECCION,
    TR_DIRECCION_OBLIGATORIA,
    TR_DNI_O_CIF,
    TR_GUARDAR
} from "../../../I18n/constants";
import FormInput from "../../../components/form/FormInput";
import Button from "../../../components/Button";
import {isNifCompanyValid, isNifNiePersonValid, isPostalCodeValid} from "../../../utils/StringUtils";
import LoadingView from "../../../base/loading/LoadingView";

const FORM_NAME = "billingDataForm";

export interface BillingFormData {
    dni: string,
    address: string,
    postal_code: string,
    city: string,
}

enum BillingFormFields {
    DNI = "dni",
    ADDRESS = "address",
    POSTAL_CODE = "postal_code",
    CITY = "city"
}

interface BillingFormProps {
    onSubmit: (data: BillingFormData) => void,
    initialValues?: Partial<BillingFormData>,
    parentLoading: boolean,
}

type Props = InjectedFormProps<BillingFormData> & BillingFormProps;

class BillingDataForm extends React.Component<Props> {

    public render(): React.ReactNode {
        const {handleSubmit, onSubmit, invalid, pristine, initialValues, parentLoading} = this.props;

        return (
            <form className="form-signin"
                  id={"form-signin"}
                  onSubmit={handleSubmit(onSubmit)}>
                <Row className={"m-t-30"}>
                    <Field
                        type={"text"}
                        name={BillingFormFields.DNI}
                        component={FormInput}
                        lineMode={false}
                        placeholder={I18nUtils.tr(TR_DNI_O_CIF)}
                        label={I18nUtils.tr(TR_DNI_O_CIF)}
                    />
                </Row>
                <Row className={"m-t-30"}>
                    <Field
                        type={"text"}
                        name={BillingFormFields.ADDRESS}
                        component={FormInput}
                        lineMode={false}
                        placeholder={I18nUtils.tr(TR_DIRECCION)}
                        label={I18nUtils.tr(TR_DIRECCION)}
                    />
                </Row>
                <Row className={"m-t-30"}>
                    <Field
                        type={"text"}
                        name={BillingFormFields.CITY}
                        component={FormInput}
                        lineMode={false}
                        placeholder={I18nUtils.tr(TR_CIUDAD)}
                        label={I18nUtils.tr(TR_CIUDAD)}
                    />
                </Row>
                <Row className={"m-t-30"}>
                    <Field
                        type={"text"}
                        name={BillingFormFields.POSTAL_CODE}
                        component={FormInput}
                        lineMode={false}
                        placeholder={I18nUtils.tr(TR_CODIGO_POSTAL)}
                        label={I18nUtils.tr(TR_CODIGO_POSTAL)}
                    />
                </Row>
                <Button
                    className={"btn btn-lg btn-form btn-submit btn-center m-t-60"}
                    text={I18nUtils.tr(initialValues ? TR_GUARDAR : TR_ACEPTAR)}
                    block={true}
                    type={"submit"}
                    disabled={invalid || pristine}
                />

                <LoadingView loading={parentLoading}/>
            </form>

        )
    }
}

function validate(values: BillingFormData) {
    const errors: FormErrors<BillingFormData> = {};

    if (!values.dni || values.dni.length === 0) {
        errors.dni = I18nUtils.tr(TR_CIF_O_DNI_OBLIGATORIO)
    } else if (!isNifCompanyValid(values.dni) && !isNifNiePersonValid(values.dni)) {
        errors.dni = I18nUtils.tr(TR_CIF_O_NIF_INVALIDO);
    }
    if (!values.address || values.address.length === 0) {
        errors.address = I18nUtils.tr(TR_DIRECCION_OBLIGATORIA);
    }
    if (!values.postal_code || values.postal_code.length === 0) {
        errors.postal_code = I18nUtils.tr(TR_CODIGO_POSTAL_OBLIGATORIO)
    } else if (!isPostalCodeValid(values.postal_code)) {
        errors.postal_code = I18nUtils.tr(TR_CODIGO_POSTAL_INVALIDO);
    }
    if (!values.city || values.city.length === 0) {
        errors.city = I18nUtils.tr(TR_CIUDAD_OBLIGATORIA)
    }

    return errors;
}

export default reduxForm<BillingFormData, BillingFormProps>({
    validate,
    form: FORM_NAME,
    enableReinitialize: true
})(BillingDataForm as any) as unknown as React.ComponentType<BillingFormProps>;