import * as React from 'react';
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import ModalHeaderTitle from "../../components/modal/ModalHeaderTitle";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO,
    TR_EMAIL, TR_EMAIL_OBLIGATORIO,
    TR_ENVIAR,
    TR_INTRODUCE_EMAIL,
    TR_INTRODUCE_TU_EMAIL_SE_ENVIARAN_LAS_INSTRUCCIONES_PARA_RECUPERAR_LA_CONTRASENA_AL_EMAIL_INDICADO,
    TR_RECUPERAR_CONTRASENA
} from "../../I18n/constants";
import {isEmailValid} from "../../utils/StringUtils";
import CardOkCancelFooter from "../../components/card/CardOkCancelFooter";

interface ConfirmModalProps extends BaseModalProps {
    show: boolean,
    onSubmit: (email: string) => void,
    onClose: () => void,
}

interface State {
    email: string,
    isValidEmail: boolean,
    emailError: string,
}

export default class RecoverPasswordModal extends BaseModal<ConfirmModalProps, State> {

    public constructor(props: ConfirmModalProps) {
        super(props);
        this.state = {
            email: '',
            isValidEmail: false,
            emailError: '',
        };
    }

    public componentDidUpdate(prevProps: any): void {
        if (this.props.show !== prevProps.show) {
            if (this.props.show) {
                this.setState({email: '',
                    isValidEmail: false,
                    emailError: ''
                });
            }
        }
    }

    private onChangeEmail = (email: string): void => {
        this.setState({
            isValidEmail: isEmailValid(email),
            email,
            emailError: email.length === 0 ? I18nUtils.tr(TR_EMAIL_OBLIGATORIO) :
                isEmailValid(email) ? '' : I18nUtils.tr(TR_EL_EMAIL_NO_TIENE_UN_FORMATO_VALIDO)
        })
    };

    private handleKeyboard = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && this.state.isValidEmail) {
            this.props.onSubmit(this.state.email);
        }
    };

    public render(): React.ReactNode {
        const {onClose, show, onSubmit} = this.props;
        const {email, isValidEmail, emailError} = this.state;

        const contentSize = 'lg';

        return (
            <Modal onHide={onClose} show={show} size={contentSize}>
                <ModalHeaderTitle title={I18nUtils.tr(TR_RECUPERAR_CONTRASENA)}
                                  classNameTitle={"modal-subtitlep-b-0"}
                                  showCloseButton={true}
                />

                <Modal.Body>
                    <div className={"m-b-40"}>
                        {I18nUtils.tr(TR_INTRODUCE_TU_EMAIL_SE_ENVIARAN_LAS_INSTRUCCIONES_PARA_RECUPERAR_LA_CONTRASENA_AL_EMAIL_INDICADO)}
                    </div>

                    <div className={"form-wrapper"}>
                        <label htmlFor={"email"}>{I18nUtils.tr(TR_EMAIL)}</label>
                        <input type="email"
                               required={true}
                               autoFocus={true}
                               value={email}
                               onChange={(event) => {
                                   this.onChangeEmail(event.target.value)
                               }}
                               placeholder={I18nUtils.tr(TR_INTRODUCE_EMAIL)}
                               className="form-control p-l-0 m-b-20"
                               onKeyPress={this.handleKeyboard}
                               autoComplete="username"
                               onBlur={(event) => {
                                   if (event.target.value.length === 0) {
                                       this.setState({emailError: I18nUtils.tr(TR_EMAIL_OBLIGATORIO)})
                                   }
                               }}
                        />
                        {emailError.length !== 0 && <label className={"error"}>{emailError}</label>}
                    </div>
                </Modal.Body>

                <Modal.Footer className={"footer-terms m-t-40"}>
                    <CardOkCancelFooter okTitle={I18nUtils.tr(TR_ENVIAR)}
                                        okHandler={() => onSubmit(email)}
                                        cancelHandler={onClose}
                                        disableOk={!isValidEmail}
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}