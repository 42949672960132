import {ReducerBuilder} from "co-redux-builders";
import TaskAddVehicle from "../../../ws/vehicle/TaskAddVehicle";
import TaskEditVehicle from "../../../ws/vehicle/TaskEditVehicle";

interface State {
    loadingVehicleEdit: boolean,
    loadingVehicleAdd: boolean,
}

const INITIAL_STATE: State = {
    loadingVehicleAdd: false,
    loadingVehicleEdit: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskAddVehicle.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) =>({...oldState, loadingVehicleAdd: true}))
    .onEvent(TaskAddVehicle.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) =>({...oldState, loadingVehicleAdd: false}))

    .onEvent(TaskEditVehicle.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) =>({...oldState, loadingVehicleEdit: true}))
    .onEvent(TaskEditVehicle.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) =>({...oldState, loadingVehicleEdit: false}))

    .build();
