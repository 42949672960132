import {ReducerBuilder} from "co-redux-builders";
import TaskSendSuggestion from "../../ws/suggestion/TaskSendSuggestion";

interface State {
    sendLoading: boolean,
}

const INITIAL_STATE: State = {
    sendLoading: false
};


export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskSendSuggestion.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, sendLoading: true}))
    .onEvent(TaskSendSuggestion.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, sendLoading: false}))

    .build();
