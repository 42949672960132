import * as React from 'react';
import {ReactNode} from 'react';
import LoadingView from "../../base/loading/LoadingView";

export interface CardProps {
    loading?: boolean,
    className?: string,
    onClick?: () => void,
}

export default class Card extends React.Component<CardProps> {

    public render(): ReactNode {
        const {children, loading, className = 'container', onClick} = this.props;

        return (
            <div className={`card ${className}`} onClick={onClick}>
                {children}
                <LoadingView loading={loading || false}/>
            </div>
        );
    }
}

