import * as React from "react";
import LoggedScreen from "../../base/screens/LoggedScreen";
import {ROUTE_CONTACT, ROUTE_CONTACT_SUGGESTION} from "../../routing/Routes";
import {RouteComponentProps} from "react-router";
import CardBody from "../../components/card/CardBody";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Suggestion from "../../model/Suggestion";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_EL_MENSAJE_HA_SIDO_ENVIADO,
    TR_EL_TEXTO_NO_PUEDE_ESTAR_VACIO,
    TR_ENVIAR,
    TR_ESCRIBE_AQUI_TU_SUGERENCIA,
    TR_TIPO_DE_COMENTARIO
} from "../../I18n/constants";
import FormSelect from "../../components/form/FormSelect";
import MasterDataReducer from "../../redux/reducers/MasterDataReducer";
import {compose} from "redux";
import {connect} from "react-redux";
import FormInputOption from "../../components/form/FormInputOption";
import FormTextArea from "../../components/form/FormTextArea";
import Button from "../../components/Button";
import TaskSendSuggestion from "../../ws/suggestion/TaskSendSuggestion";
import Alert from "../../base/alerts/Alert";
import {goToRoute} from "../../utils/Router";
import TaskMasterData from "../../ws/masterdata/TaskMasterData";
import {ReducerBuilder} from "co-redux-builders";
import SuggestionFormReducer from "./SuggestionFormReducer";
import LoadingView from "../../base/loading/LoadingView";

const FORM_NAME = "suggestionForm";

export interface SuggestionFormData {
    suggestion_type_id: number,
    text: string,
}

enum SuggestionFormFields {
    SUGGESTION_TYPE_ID = "suggestion_type_id",
    TEXT = "text",
}

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    MasterDataReducer.autoMapToProps(),
    SuggestionFormReducer.autoMapToProps()
);

type Props = InjectedFormProps<SuggestionFormData> & RouteComponentProps & typeof mapStateToProps;

class SuggestionForm extends LoggedScreen<Props, {}> {

    public constructor(props: Props, context: any) {
        super(props, context);
        this.setNavbarTitleFromRouteNameScreen(ROUTE_CONTACT_SUGGESTION);
    }

    public componentDidMount(): void {
        const {masterData} = this.props;
        if (!masterData) {
            new TaskMasterData(1).execute();
        }
        if (masterData && masterData.suggestion_types.length !== 0) {
            const selectDefaultValue = masterData.suggestion_types[0];
            this.props.change(SuggestionFormFields.SUGGESTION_TYPE_ID, selectDefaultValue.id);
        }
    }

    private handleSubmit(values: SuggestionFormData) {
        const submitData: Suggestion = {
            city_id: 1,
            suggestion_type_id: values.suggestion_type_id,
            text: values.text
        };
        new TaskSendSuggestion(submitData)
            .onSuccess(() => {
                Alert.success(I18nUtils.tr(TR_EL_MENSAJE_HA_SIDO_ENVIADO));
                goToRoute(ROUTE_CONTACT);
            })
            .execute();
    }

    public renderScreen(): React.ReactNode {
        const {handleSubmit, masterData, invalid, pristine, sendLoading} = this.props;
        let suggestionTypeOptions: FormInputOption[] = [];
        if (masterData) {
            suggestionTypeOptions = masterData.suggestion_types.map((suggestionType) =>
                ({value: suggestionType.id.toString(), name: suggestionType.name}))
        }

        if (sendLoading) {
            return <LoadingView loading={true}/>
        }

        return (
            <CardBody>
                <div className={"suggestion-container"}>
                    <form onSubmit={handleSubmit(this.handleSubmit)} className={"form-suggestion"}>
                        <div className={"form-wrapper"}>
                            <label>{I18nUtils.tr(TR_TIPO_DE_COMENTARIO)}</label>
                            <Field
                                name={SuggestionFormFields.SUGGESTION_TYPE_ID}
                                component={FormSelect}
                                className={"form-control"}
                                options={suggestionTypeOptions}
                                enabledEmptyOption={false}
                                formLine={false}
                            />
                        </div>

                        <div className={"form-wrapper m-t-20"}>
                            <label>{I18nUtils.tr(TR_ESCRIBE_AQUI_TU_SUGERENCIA)}</label>
                            <Field
                                name={SuggestionFormFields.TEXT}
                                component={FormTextArea}
                                containerClassName={"suggestion-container"}
                                textareaClassName={"suggestion-textarea"}
                            />
                        </div>


                        <div className={"suggestion-footer"}>
                            <Button
                                className={"btn btn-lg btn-center btn-bottom btn-submit"}
                                text={I18nUtils.tr(TR_ENVIAR)}
                                type={"submit"}
                                block={true}
                                disabled={pristine || invalid}
                            />
                        </div>
                    </form>
                </div>
            </CardBody>

        )
    }
}

function validate(values: SuggestionFormData) {
    const errors: FormErrors<SuggestionFormData> = {};

    if (!values.text || values.text.length === 0) {
        errors.text = I18nUtils.tr(TR_EL_TEXTO_NO_PUEDE_ESTAR_VACIO);
    }

    return errors;
}

export default compose(reduxForm<SuggestionFormData>({
    validate,
    form: FORM_NAME,
    enableReinitialize: true
}), connect(mapStateToProps))(SuggestionForm as any) as unknown as React.ComponentType<{}>;