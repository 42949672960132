import {PersonalAreaSection} from "../modules/personalArea/PersonalAreaScreen";

const base = "";

// const personalAreaVehicles: PersonalAreaSection = "vehicles";
const personalAreaBilling: PersonalAreaSection = "billingData";
const personalAreaContact: PersonalAreaSection = "contact";

export const ROUTE_START = base + "/";
export const ROUTE_LOGIN = base + "/login";
export const ROUTE_REGISTER = base + "/register";
export const ROUTE_PARK_SHOW_CITIES = base + "/park/showCities";
export const ROUTE_PARK_SUMMARY = base + "/parkSummary";
// export const ROUTE_PERSONAL_AREA_VEHICLES = base + `/personalArea/${personalAreaVehicles}`;
export const ROUTE_PERSONAL_AREA_PAYMENT_CARDS = base + `/personalArea/${personalAreaBilling}`;
export const ROUTE_PERSONAL_AREA_CONTACT = base + `/personalArea/${personalAreaContact}`;
export const ROUTE_CONTACT = base + `/contact`;
export const ROUTE_CONTACT_SUGGESTION = base + `/contact/suggestion`;
export const ROUTE_CONFIGURATION = base + `/configuration`;

export const ROUTE_REGISTER_MERCHANT = base + '/merchant/register';
export const ROUTE_REGISTER_MERCHANT_SUCCESS = base + '/merchant/register/success';

export const ROUTE_HISTORY = base + '/history';