import * as React from "react";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ACEPTAR,
    TR_ACEPTAR_RECIBIR,
    TR_COMUNICACIONES_COMERCIALES,
    TR_DE,
    TR_REGISTRO_EXCLUSIVO_PARA_COMERCIOS_Y_NEGOCIOS,
    TR_TERMINOS_Y_CONDICIONES,
    TR_VNG_APARCAMENTS
} from "../../I18n/constants";

import Checkbox from "../../components/Checkbox";

import {connect} from "react-redux";
import Alert from "../../base/alerts/Alert";
import RegisterMerchantScreenReducer from "./RegisterMerchantScreenReducer";
import TaskRetrieveAssociations from "../../ws/retrieveAssociations/TaskRetrieveAssociations";
import RegisterMerchantScreenActions from "./RegisterMerchantScreenActions";
import TaskMerchantRegister, {TaskMerchantRegisterRequest} from "../../ws/merchantRegistration/TaskMerchantRegister";
import RegisterMerchantForm, {MerchantFormData} from "./RegisterMerchantForm";
import {Int} from "../../utils/StringUtils";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import Logo from "../../res/img/logo.png";
import TaskRetrieveLegalStatuses from "../../ws/retrieveLegalStatuses/TaskRetrieveLegalStatuses";
import {ReducerBuilder} from "co-redux-builders";
import MasterDataReducer from "../../redux/reducers/MasterDataReducer";
import {goToRoute} from "../../utils/Router";
import {ROUTE_REGISTER_MERCHANT_SUCCESS} from "../../routing/Routes";
import TaskMasterData from "../../ws/masterdata/TaskMasterData";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    RegisterMerchantScreenReducer.autoMapToProps(),
    MasterDataReducer.autoMapToProps(),
);
const mapActionsToProps = RegisterMerchantScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class RegisterMerchantScreen extends React.Component<Props> {

    public componentDidMount(): void {
        new TaskRetrieveAssociations().execute();
        new TaskRetrieveLegalStatuses().execute();
        if (!this.props.masterData) {
            new TaskMasterData().execute()
        }
    }

    public componentWillUnmount(): void {
        this.props.clearRegisterMerchantScreenReducer();
    }

    private handleSubmit = (values: MerchantFormData): void => {
        if (!this.props.acceptedTerms) {
            this.props.onShowTermsModal();
            this.props.setCurrentFormData(values);
        } else {
            let submitData: TaskMerchantRegisterRequest = {
                user: {
                    username: values.username,
                    name: values.name,
                    last_name: values.lastName,
                    dni: values.dni,
                    password: values.password,
                    phone: values.userPhone
                },
                shop: {
                    name: values.shopName,
                    city_id: 1, // Vilanova
                    cif: values.cif,
                    phone: values.shopPhone,
                    address: values.address,
                    postal_code: values.postalCode,
                    association_id: Int(values.associationId),
                    association_number: values.associationNumber,
                    legal_status_id: Int(values.legalStatusId),
                }
            };

            new TaskMerchantRegister(submitData)
                .onSuccess(() => goToRoute(ROUTE_REGISTER_MERCHANT_SUCCESS))
                .onFail((error: any) => Alert.error(error))
                .execute();
        }
    };

    public render(): React.ReactNode {
        const {
            loadingAssociations, loadingLegalStatuses, loading, listAssociations, listLegalStatuses, loadingMerchantRegister
        } = this.props;

        return (
            <Card loading={loadingAssociations || loadingLegalStatuses || loading || loadingMerchantRegister}>
                <CardBody>
                    <div className={"signin-container"}>

                        <div className={"head"}>
                            <div className={"head-img"}><img src={Logo} alt={"logo"}/></div>
                            <div className={'title'}>{I18nUtils.tr(TR_REGISTRO_EXCLUSIVO_PARA_COMERCIOS_Y_NEGOCIOS).toUpperCase()}</div>
                        </div>

                        <div className={"border-bg"}/>

                        <RegisterMerchantForm onSubmit={this.handleSubmit}
                                              associations={listAssociations}
                                              legalStatuses={listLegalStatuses}
                        />
                    </div>

                </CardBody>

                {this.renderTermsModal()}
                {this.renderExtendedTermsModal()}
                {this.renderCommunicationModal()}
            </Card>
        )
    }

    private renderTermsModal(): React.ReactNode {
        const {
            masterData, onHideTermsModal, showTermsModal, acceptedTerms, onShowExtendedTermsModal, setAcceptedTerms,
            acceptedCommunications, onShowCommunicationsModal, setAcceptedCommunications, currentFormData
        } = this.props;
        return (
            <TermsAndConditionsModal
                key={"terms"}
                title={I18nUtils.tr(TR_TERMINOS_Y_CONDICIONES)}
                onConfirmAction={() => {
                    onHideTermsModal();
                    this.handleSubmit(currentFormData);
                }}
                show={showTermsModal}
                onClose={onHideTermsModal}
                disabled={!acceptedTerms}
            >
                <div className={"terms-text"}>
                    {masterData && masterData.appinfo.legal_info_summary}
                </div>
                <div className={'p-b-75'}>
                    <Checkbox
                        checked={acceptedTerms}
                        onChange={() => {
                            if (!acceptedTerms) {
                                onShowExtendedTermsModal();
                            }
                            setAcceptedTerms();
                        }}
                        label={<>{`${I18nUtils.tr(TR_ACEPTAR)} `}
                            <span className={'font-middle-bold pointer'}
                                  onClick={(event) => {
                                      event.stopPropagation();
                                      onShowExtendedTermsModal();
                                      onHideTermsModal();
                                  }}>
                                      {I18nUtils.tr(TR_TERMINOS_Y_CONDICIONES).substr(0).toLowerCase()}
                                  </span>{` ${I18nUtils.tr(TR_DE)} ${I18nUtils.tr(TR_VNG_APARCAMENTS)}.`}</>}
                    />
                    <Checkbox onChange={() => {
                        if (!acceptedCommunications) {
                            onShowCommunicationsModal();
                        }
                        setAcceptedCommunications()
                    }}
                              checked={acceptedCommunications}
                              label={<>{I18nUtils.tr(TR_ACEPTAR_RECIBIR)} <span
                                  className={'font-middle-bold pointer'}
                                  onClick={(event) => {
                                      event.stopPropagation();
                                      onShowCommunicationsModal();
                                      onHideTermsModal();
                                  }}>
                                      {I18nUtils.tr(TR_COMUNICACIONES_COMERCIALES).substr(0).toLowerCase()}
                                  </span>{`.`}</>}
                    />
                </div>
            </TermsAndConditionsModal>
        )
    }

    private renderExtendedTermsModal(): React.ReactNode {
        const {onShowTermsModal, onHideExtendedTermsModal, showExtendedTermsModal, masterData} = this.props;
        return (
            <TermsAndConditionsModal
                key={"extendedTerms"}
                title={I18nUtils.tr(TR_TERMINOS_Y_CONDICIONES)}
                onConfirmAction={() => {
                    onShowTermsModal();
                    onHideExtendedTermsModal()
                }}
                show={showExtendedTermsModal}
                onClose={() => {
                    onShowTermsModal();
                    onHideExtendedTermsModal()
                }}
                showCloseButton={false}
            >
                <div className={"terms-text"}>
                    {masterData && masterData.appinfo.legal_info}
                </div>

            </TermsAndConditionsModal>
        )
    }

    private renderCommunicationModal(): React.ReactNode {
        const {onShowTermsModal, onHideCommunicationsModal, showCommunicationsModal, masterData} = this.props;
        return (
            <TermsAndConditionsModal
                key={"communications"}
                title={I18nUtils.tr(TR_COMUNICACIONES_COMERCIALES)}
                onConfirmAction={() => {
                    onShowTermsModal();
                    onHideCommunicationsModal()
                }}
                show={showCommunicationsModal}
                onClose={() => {
                    onShowTermsModal();
                    onHideCommunicationsModal()
                }}
                showCloseButton={false}
            >
                <div className={"terms-text"}>
                    {masterData && masterData.appinfo.commercial_info}
                </div>

            </TermsAndConditionsModal>
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(RegisterMerchantScreen as unknown as React.ComponentType<{}>);