import * as React from 'react';
import {Component, ReactNode} from 'react';

export interface ButtonProps {
    text: string,
    onClick?: (event: React.MouseEvent<HTMLElement>) => void,
    className?: string,
    block?: boolean,
    disabled?: boolean,
    type?: 'submit' | 'reset' | 'button',
    children?: ReactNode,
}

export default class Button extends Component<ButtonProps> {

    public render(): ReactNode {
        const {className, onClick, type, disabled, text, children} = this.props;

        return (
            <button
                className={className}
                onClick={onClick}
                type={type || 'button'}
                disabled={disabled}
            >
                {text}
                {children}
            </button>
        );
    }


}