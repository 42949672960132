import {ReducerBuilder} from "co-redux-builders";
import TaskUpdateUser from "../../../ws/auth/TaskUpdateUser";

interface State {
   loadingUploadBilling: boolean,
}

const INITIAL_STATE: State = {
    loadingUploadBilling: false
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUpdateUser.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingUploadBilling: true}))
    .onEvent(TaskUpdateUser.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingUploadBilling: false}))

    .build();
