import i18next from "i18next";
import translationsEs from './translations/translations.es.json';
import translationsEn from './translations/translations.en.json';
import translationsCa from './translations/translations.ca.json';

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'ca',
        // Using simple hardcoded resources for simple example
        resources: {
            es: {
                translation: translationsEs,
            },
            en: {
                translation: translationsEn,
            },
            ca: {
                translation: translationsCa,
            },
        },
    });

export default i18next;