import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Auth} from "../../utils/AuthManager";
import AuthRequest from "../AuthRequest";
import MasterData from "../../model/MasterData";
import Lodash from "lodash";

export interface TaskMasterDataRequest extends AuthRequest {
    city_id?: number,
}

export default class TaskMasterData extends BaseGenericRequest<TaskMasterDataRequest, TaskResponse<MasterData>> {

    private readonly cityId?: number;

    public constructor(cityId?: number) {
        super(METHOD.METHOD_POST, Urls.URL_RETRIEVE_MASTER_DATA);
        this.cityId = cityId;
    }

    protected getRequest(): TaskMasterDataRequest {
        let authData = this.getAuth() as Auth;
        if ( !Lodash.isEqual(authData, {})) {
            authData = {...authData}
        }

        return {
            ...authData,
            city_id: this.cityId ? this.cityId : undefined
        }
    }
}