import * as React from "react";
import {IconBaseProps} from "./IconBase";

export interface IconSVG {
    icon: React.ReactNode,
    iconProps?: IconBaseProps,
}

export class AppIconSVG {

    private static VIEWBOX_28: string = "0 0 28 28";
    private static VIEWBOX_30: string = "0 0 30 30";


    public static COMPLAINT: IconSVG = {
        icon: (<>
            <path
                d="M0,33 L39,33 L19.5,0 L0,33 L0,33 Z M21.5647059,27.0416667 L18.8117647,27.0416667 L18.8117647,24.2916667 L21.5647059,24.2916667 L21.5647059,27.0416667 L21.5647059,27.0416667 Z M21.5647059,21.5416667 L18.8117647,21.5416667 L18.8117647,14.2083333 L21.5647059,14.2083333 L21.5647059,21.5416667 L21.5647059,21.5416667 Z"
                id="icon-complaint"
            />
        </>),
        iconProps: {viewBox: "0 0 39 33"}
    };

    public static FACEBOOK: IconSVG = {
        icon: <>
            <path
                d="M12,0 L12,4.49994988 L9.00020047,4.49994988 C8.10050117,4.49994988 7.49989977,5.09892753 7.49989977,6.00020046 L7.49989977,8.99989977 L12,8.99989977 L12,13.4998497 L7.49989977,13.4998497 L7.49989977,24 L2.99979953,24 L2.99979953,13.4998497 L0,13.4998497 L0,8.99989977 L2.99979953,8.99989977 L2.99979953,5.25127794 C2.99979953,2.4015235 5.39979953,0 8.25045105,0 L12,0 Z"
                id="icon-facebook"
            />
        </>
    };

    public static LOCATION: IconSVG = {
        icon: <>
            <path
                d="M11.5,0 C5.14214286,0 0,5.1645 0,11.55 C0,20.2125 11.5,33 11.5,33 C11.5,33 23,20.2125 23,11.55 C23,5.1645 17.8578571,0 11.5,0 L11.5,0 Z M11.5,15.675 C9.23285714,15.675 7.39285714,13.827 7.39285714,11.55 C7.39285714,9.273 9.23285714,7.425 11.5,7.425 C13.7671429,7.425 15.6071429,9.273 15.6071429,11.55 C15.6071429,13.827 13.7671429,15.675 11.5,15.675 L11.5,15.675 Z"
                id="icon-location"
            />
        </>,
        iconProps: {viewBox: "0 0 23 33"}
    };

    public static PARKING_METER: IconSVG = {
        icon: <>
            <path
                d="M13.0966491,7.57663348 C12.8148184,5.38534914 10.937675,3.68675985 8.67096774,3.68675985 C6.40430896,3.68675985 4.5271171,5.3853007 4.24523799,7.57663348 L7.93693884,7.57663348 L7.93693884,5.27127021 L9.40499665,5.27127021 L9.40499665,7.57663348 L13.0966491,7.57663348 Z"
                id="icon-parkingmeter"/>
            <path
                d="M8.67096774,0 C3.90663428,0 0.0299850784,3.87616479 0.0299850784,8.64049825 C0.0299850784,10.4946806 1.2694975,13.5159073 3.7157761,17.6245412 L13.6261594,17.6245412 C16.072438,13.5159557 17.3119504,10.4946806 17.3119504,8.64049825 C17.3119504,3.87616479 13.4353012,0 8.67096774,0 Z M14.5940622,8.35619708 L14.5514824,9.0446913 L2.79040461,9.0446913 L2.74782483,8.35619708 C2.74293228,8.2793694 2.74051022,8.21135801 2.74051022,8.14872359 C2.74051022,4.87884837 5.40114096,2.21875048 8.67096774,2.21875048 C11.9407945,2.21875048 14.6014253,4.87889681 14.6014253,8.14872359 C14.6014253,8.21135801 14.5989548,8.27888499 14.5940622,8.35619708 Z"
                id="icon-parkingmeter"/>
            <polygon
                id="icon-parkingmeter"
                points="4.85743738 19.092599 4.85743738 21.1155988 6.48467284 21.1155988 6.48467284 24.8018742 10.8572626 24.8018742 10.8572626 21.1155988 12.4844981 21.1155988 12.4844981 19.092599"/>
        </>
    };

    public static PIN: IconSVG = {
        icon: <>
            <path
                d="M25.737218,0 L51.4738135,10.952862 C51.4738135,10.952862 49.7624191,51.6029929 25.737218,51.6029929 C1.71139442,51.6029929 3.55271368e-13,10.952862 3.55271368e-13,10.952862 L25.7365955,0 L25.737218,0 Z M37.8891145,16.0590305 L23.0415695,28.2778512 L16.9066345,23.8213154 L12.6611437,27.5560026 L22.8479559,38.9100991 L22.8678776,38.8901775 L22.8815737,38.9100991 L41.1497286,20.3449871 L37.8891145,16.0590305 Z"
                id="icon-pin"
            />
        </>,
        iconProps: {viewBox: "0 0 52 52"}
    };

    public static TICKET: IconSVG = {
        icon: <>
            <path
                d="M36.7555974,12.9264024 L34.9782619,10.3906161 C34.9673191,10.3982891 34.9569808,10.4067476 34.9461589,10.4142394 C32.9343079,11.8230565 30.1606899,11.3343991 28.7513,9.32370204 C27.34191,7.31336743 27.8306501,4.54140879 29.8427429,3.13307497 C29.8536857,3.12540193 29.8648704,3.11869558 29.8758132,3.1112038 L28.0985382,0.575779994 C27.6704976,-0.0351021728 26.8275964,-0.183366918 26.2162481,0.244389559 L19.1865288,5.16540157 L20.7251168,7.36055356 L19.0570888,8.52727748 L17.5189845,6.33266925 L0.576499439,18.193003 C-0.0351511318,18.620397 -0.183695705,19.4627993 0.244888944,20.0736815 L8.9019482,32.4243039 C9.32998873,33.0351861 10.1729505,33.1834508 10.7838151,32.7553318 L27.7261792,20.8956627 L26.2068167,18.7278194 L27.8747238,17.5604309 L29.3938444,19.7284554 L36.4239869,14.8072622 C37.0347306,14.3790828 37.183638,13.5370429 36.7555974,12.9264024 L36.7555974,12.9264024 Z M20.243269,10.1396415 L21.9111156,8.97243422 L23.9077916,11.8203378 L22.2391591,12.9879075 L20.243269,10.1396415 L20.243269,10.1396415 Z M25.1306091,17.1923066 L23.2890071,14.5654106 L24.9569141,13.3982033 L26.7982744,16.0250993 L25.1306091,17.1923066 L25.1306091,17.1923066 Z"
                id="icon-ticket"
            />
        </>,
        iconProps: {viewBox: "0 0 37 33"}
    };

    public static TWITTER: IconSVG = {
        iconProps: {
            viewBox: AppIconSVG.VIEWBOX_28
        },
        icon: (
            <path
                d="M26.5062077,5.96289 C26.2811779,16.3217966 19.7103064,23.5236048 9.74206128,23.975438 C5.66267409,24.2002078 2.71651413,22.8492954 0,21.2747599 C2.94385197,21.7231527 6.79590131,20.5993037 8.83386391,18.7965581 C5.89001194,18.5717883 4.07592519,16.9949592 3.17003581,14.5201978 C4.07592519,14.7438208 4.98412256,14.5201978 5.66267409,14.5201978 C2.94385197,13.6176782 1.13091922,12.0419959 0.904735376,8.43994505 C1.58444091,8.89063146 2.49033028,9.11540127 3.39852766,9.11540127 C1.35825706,7.99040543 0,3.71175155 1.58444091,1.00992669 C4.53060088,4.16243796 8.15646637,6.86196924 14.0464783,7.31494922 C12.4597294,1.00992669 21.0697175,-2.36391403 24.4670911,1.9112995 C26.05384,1.68538291 27.1882212,1.00992669 28.3202945,0.560387073 C27.8667728,2.13492253 26.9608834,3.03744212 25.8265022,3.93652136 C26.9608834,3.71175155 28.0929566,3.48698174 29,3.03744212 C28.7726622,4.16243796 27.6394349,5.06381076 26.5062077,5.96289"
                id="icon-twitter"/>
        )
    };

    public static WEB: IconSVG = {
        icon: <path
            d="M11.988,0 C5.364,0 0,5.376 0,12 C0,18.624 5.364,24 11.988,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 11.988,0 L11.988,0 Z M20.304,7.2 L16.764,7.2 C16.38,5.7 15.828,4.26 15.108,2.928 C17.316,3.684 19.152,5.22 20.304,7.2 L20.304,7.2 Z M12,2.448 C12.996,3.888 13.776,5.484 14.292,7.2 L9.708,7.2 C10.224,5.484 11.004,3.888 12,2.448 L12,2.448 Z M2.712,14.4 C2.52,13.632 2.4,12.828 2.4,12 C2.4,11.172 2.52,10.368 2.712,9.6 L6.768,9.6 C6.672,10.392 6.6,11.184 6.6,12 C6.6,12.816 6.672,13.608 6.768,14.4 L2.712,14.4 L2.712,14.4 Z M3.696,16.8 L7.236,16.8 C7.62,18.3 8.172,19.74 8.892,21.072 C6.684,20.316 4.848,18.792 3.696,16.8 L3.696,16.8 Z M7.236,7.2 L3.696,7.2 C4.848,5.208 6.684,3.684 8.892,2.928 C8.172,4.26 7.62,5.7 7.236,7.2 L7.236,7.2 Z M12,21.552 C11.004,20.112 10.224,18.516 9.708,16.8 L14.292,16.8 C13.776,18.516 12.996,20.112 12,21.552 L12,21.552 Z M14.808,14.4 L9.192,14.4 C9.084,13.608 9,12.816 9,12 C9,11.184 9.084,10.38 9.192,9.6 L14.808,9.6 C14.916,10.38 15,11.184 15,12 C15,12.816 14.916,13.608 14.808,14.4 L14.808,14.4 Z M15.108,21.072 C15.828,19.74 16.38,18.3 16.764,16.8 L20.304,16.8 C19.152,18.78 17.316,20.316 15.108,21.072 L15.108,21.072 Z M17.232,14.4 C17.328,13.608 17.4,12.816 17.4,12 C17.4,11.184 17.328,10.392 17.232,9.6 L21.288,9.6 C21.48,10.368 21.6,11.172 21.6,12 C21.6,12.828 21.48,13.632 21.288,14.4 L17.232,14.4 L17.232,14.4 Z"
            id="icon-web"
        />
    };

    public static GOOGLE_PLAY: IconSVG = {
        icon: <path
            d="M0,20.8856291 L0,1.53589114 C0,0.860664962 0.391463129,0.27699719 0.959543346,0 L12.1524424,11.2074593 L0.955041809,22.4192666 C0.389394241,22.1414271 0,21.5591138 0,20.8856291 Z M15.7034269,14.7629167 L3.46470503,21.8380915 L13.1170897,12.1732388 L15.7034269,14.7629167 Z M19.5076796,9.85422966 C19.9147503,10.1662157 20.1773399,10.6578129 20.1773399,11.2107601 C20.1773399,11.7553983 19.9227076,12.2403938 19.5260949,12.552949 L16.9264578,14.0558545 L14.0816234,11.2074593 L16.9245254,8.36086242 L19.5076796,9.85422966 Z M3.47187793,0.583963709 L15.7014944,7.65384576 L13.1170897,10.2415659 L3.47187793,0.583963709 Z"
            id="icon-google-play"/>

    };

    public static APPLE_STORE: IconSVG = {
        icon: (<path
            d="M22.382714,26.2176245 C21.1293172,28.0424272 19.8327934,29.8560054 17.7857695,29.894019 C15.7711664,29.9320327 15.1253172,28.706019 12.826468,28.706019 C10.5252061,28.706019 9.80531716,29.8560054 7.90319097,29.9320327 C5.92699494,30.0056653 4.42432113,27.9611619 3.16059494,26.1445905 C0.578841767,22.430332 -1.39165427,15.6425088 1.25845367,11.0616898 C2.57078066,8.7867551 4.92231716,7.34738639 7.47056399,7.30938776 C9.41129335,7.27382857 11.2446426,8.61201224 12.4300314,8.61201224 C13.6172299,8.61201224 15.8424918,7.00536871 18.1819045,7.24102313 C19.1611584,7.2812966 21.9095235,7.63386531 23.6745632,10.2051714 C23.5350791,10.296449 20.3940473,12.1157741 20.4323489,15.9060599 C20.4708013,20.4337197 24.4175235,21.9386993 24.4633648,21.9592027 C24.4276267,22.0656109 23.8324441,24.1077197 22.382714,26.2176245 Z M13.7879283,2.26730204 C14.8713807,1.00331973 16.7020156,0.0631714286 18.2128172,1.42108547e-14 C18.405833,1.75573469 17.6963489,3.51389388 16.6484838,4.78079456 C15.5985076,6.04542041 13.8797616,7.0305415 12.1929838,6.89900544 C11.9637775,5.18126939 12.8139521,3.38981088 13.7879283,2.26730204 Z"
            id="icon-apple-store"/>),
        iconProps: {viewBox: AppIconSVG.VIEWBOX_30}
    };

    public static CITY: IconSVG = {
        icon: <path
            d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"
            id="icon-city"
        />

    };

    public static PARKING: IconSVG = {
        icon: <path d="M13 3H6v18h4v-6h3c3.31 0 6-2.69 6-6s-2.69-6-6-6zm.2 8H10V7h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z"
                    id="icon-parking"
        />

    };

    public static CAR: IconSVG = {
        icon: <path
            d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"
            id={"icon-car"}
        />

    };

    public static ACCESSIBLE: IconSVG = {
        icon: <>
            <circle cx="12" cy="4" r="2"/>
            <path
                d="M19 13v-2c-1.54.02-3.09-.75-4.07-1.83l-1.29-1.43c-.17-.19-.38-.34-.61-.45-.01 0-.01-.01-.02-.01H13c-.35-.2-.75-.3-1.19-.26C10.76 7.11 10 8.04 10 9.09V15c0 1.1.9 2 2 2h5v5h2v-5.5c0-1.1-.9-2-2-2h-3v-3.45c1.29 1.07 3.25 1.94 5 1.95zm-6.17 5c-.41 1.16-1.52 2-2.83 2-1.66 0-3-1.34-3-3 0-1.31.84-2.41 2-2.83V12.1c-2.28.46-4 2.48-4 4.9 0 2.76 2.24 5 5 5 2.42 0 4.44-1.72 4.9-4h-2.07z"
                id={"icon-accessible"}
            />
        </>
    };

    public static MENU: IconSVG = {
        icon: <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
                    id={"icon-menu"}
        />

    };

    public static NOTIFICATION: IconSVG = {
        icon: <path
            d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"
            id={"icon-notification"}
        />

    };

    public static ARROW_RIGHT: IconSVG = {
        icon: <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                    id={"icon-arrow-right"}
        />,
    };

    public static ARROW_LEFT: IconSVG = {
        icon: <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                    id={"icon-arrow-left"}
        />
    };

    public static TELEPHONE: IconSVG = {
        icon: <path
            d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"
            id={"icon-telephone"}
        />
    };

    public static CREDIT_CARD: IconSVG = {
        icon: <>
            <path
                d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
                id={"icon-credit-card"}
            />
        </>
    };

    public static TIME: IconSVG = {
        icon: <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z"
                    id={"icon-time"}
        />
    };

    public static SETTINGS: IconSVG = {
        icon: <path d="M10.82 12.49c.02-.16.04-.32.04-.49 0-.17-.02-.33-.04-.49l1.08-.82c.1-.07.12-.21.06-.32l-1.03-1.73c-.06-.11-.2-.15-.31-.11l-1.28.5c-.27-.2-.56-.36-.87-.49l-.2-1.33c0-.12-.11-.21-.24-.21H5.98c-.13 0-.24.09-.26.21l-.2 1.32c-.31.12-.6.3-.87.49l-1.28-.5c-.12-.05-.25 0-.31.11l-1.03 1.73c-.06.12-.03.25.07.33l1.08.82c-.02.16-.03.33-.03.49 0 .17.02.33.04.49l-1.09.83c-.1.07-.12.21-.06.32l1.03 1.73c.06.11.2.15.31.11l1.28-.5c.27.2.56.36.87.49l.2 1.32c.01.12.12.21.25.21h2.06c.13 0 .24-.09.25-.21l.2-1.32c.31-.12.6-.3.87-.49l1.28.5c.12.05.25 0 .31-.11l1.03-1.73c.06-.11.04-.24-.06-.32l-1.1-.83z
    M7 13.75c-.99 0-1.8-.78-1.8-1.75s.81-1.75 1.8-1.75 1.8.78 1.8 1.75S8 13.75 7 13.75z"
                    id={"icon-settings"}
        />,
        iconProps: {viewBox: "2 7 11 11"}
    };

    public static INFORMATION: IconSVG = {
        icon: <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
                    id={"icon-information"}
        />
    };

    public static CLOSE_SESSION: IconSVG = {
        icon: <path
            d="M18.0989832,2.64970323 L16.3650065,4.3823071 C18.2939355,5.93231484 19.5268026,8.31292903 19.5268026,10.9832258 C19.5268026,15.7073858 15.7073858,19.5268026 10.9832258,19.5268026 C6.26043871,19.5268026 2.44102194,15.7073858 2.44102194,10.9832258 C2.44102194,8.31292903 3.67388903,5.93231484 5.59046194,4.36857806 L3.86884129,2.64970323 C1.50195613,4.66375226 0,7.64020645 0,10.9832258 C0,17.0473394 4.91773935,21.9664516 10.9832258,21.9664516 C17.0487123,21.9664516 21.9664516,17.0473394 21.9664516,10.9832258 C21.9664516,7.64020645 20.4658684,4.66375226 18.0989832,2.64970323 L18.0989832,2.64970323 Z M9.76408774,12.2037368 L12.2037368,12.2037368 L12.2037368,0 L9.76408774,0 L9.76408774,12.2037368 Z"
            id={"icon-information"}
        />
    };

    public static ADD: IconSVG = {
        icon: <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
                    id={"icon-add"}
        />
    };

    public static STAR: IconSVG = {
        icon: <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                    id={"icon-star"}
        />
    };

    public static PERSON: IconSVG = {
        icon: <path
            d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
            id={"icon-person"}
        />
    };

    public static MERCHANT_CARD: IconSVG = {
        icon: <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"
                    id={"icon-merchant-card"}
        />
    };

    public static RESIDENT: IconSVG = {
        icon: <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"
                    id={"icon-resident"}
        />
    };

    public static CHECK: IconSVG = {
        icon: <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41"
                    id={"icon-check"}
        />
    };

    public static CONTACT_FORM: IconSVG = {
        icon: <path
            d="M18.7603333,2.28466667 L13.98538,2.28466667 C13.5056,0.95956 12.2490333,0 10.764,0 C9.27896667,0 8.0224,0.95956 7.54262,2.28466667 L2.76766667,2.28466667 C1.5111,2.28466667 0.483,3.31276667 0.483,4.56933333 L0.483,22.8466667 C0.483,24.1032333 1.5111,25.1313333 2.76766667,25.1313333 L18.7603333,25.1313333 C20.0169,25.1313333 21.045,24.1032333 21.045,22.8466667 L21.045,4.56933333 C21.045,3.31276667 20.0169,2.28466667 18.7603333,2.28466667 L18.7603333,2.28466667 Z M10.764,2.28466667 C11.3922833,2.28466667 11.9063333,2.79871667 11.9063333,3.427 C11.9063333,4.05528333 11.3922833,4.56933333 10.764,4.56933333 C10.1357167,4.56933333 9.62166667,4.05528333 9.62166667,3.427 C9.62166667,2.79871667 10.1357167,2.28466667 10.764,2.28466667 L10.764,2.28466667 Z M18.7603333,22.8466667 L2.76766667,22.8466667 L2.76766667,4.56933333 L5.05233333,4.56933333 L5.05233333,7.99633333 L16.4756667,7.99633333 L16.4756667,4.56933333 L18.7603333,4.56933333 L18.7603333,22.8466667 L18.7603333,22.8466667 Z"
            id={"icon-contact-form"}
        />
    };

    public static MAIL: IconSVG = {
        icon: <path
            d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            id={"icon-mail"}
        />
    };

    public static DNI: IconSVG = {
        icon: (<>
            <g id="APP-LICITACION-DISEÑO-FINAL-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="B.01-Registro" transform="translate(-126.000000, -715.000000)" fill="#67A1D3" fillRule="nonzero">
                    <path
                        d="M162.301979,715 L130.698021,715 C128.10643,715 126,717.123556 126,719.736216 L126,741.263784 C126,743.876444 128.10643,746 130.698021,746 L162.301979,746 C164.89357,746 167,743.876444 167,741.263784 L167,719.736216 C167,717.123556 164.89357,715 162.301979,715 Z M138.8125,721.888784 C141.166672,721.888784 143.083229,723.821238 143.083229,726.19455 C143.083229,728.567546 141.166672,730.5 138.8125,730.5 C136.458328,730.5 134.541771,728.567546 134.541771,726.19455 C134.541771,723.821238 136.458328,721.888784 138.8125,721.888784 Z M146.5,737.81955 C146.5,738.532552 145.926002,739.111216 145.21875,739.111216 L132.40625,739.111216 C131.698998,739.111216 131.125,738.532552 131.125,737.81955 L131.125,736.958333 C131.125,734.345673 133.23143,732.222117 135.823021,732.222117 L141.801979,732.222117 C144.39357,732.222117 146.5,734.345673 146.5,736.958333 L146.5,737.81955 Z M160.59375,739.111216 L151.198021,739.111216 C150.490768,739.111216 149.916771,738.532552 149.916771,737.81955 C149.916771,737.106547 150.490768,736.527883 151.198021,736.527883 L160.59375,736.527883 C161.301002,736.527883 161.875,737.106547 161.875,737.81955 C161.875,738.532552 161.301002,739.111216 160.59375,739.111216 Z M160.59375,732.222117 L151.198021,732.222117 C150.490768,732.222117 149.916771,731.643453 149.916771,730.93045 C149.916771,730.217448 150.490768,729.638784 151.198021,729.638784 L160.59375,729.638784 C161.301002,729.638784 161.875,730.217448 161.875,730.93045 C161.875,731.643453 161.301002,732.222117 160.59375,732.222117 Z M160.59375,725.333333 L151.198021,725.333333 C150.490768,725.333333 149.916771,724.754669 149.916771,724.041667 C149.916771,723.328664 150.490768,722.75 151.198021,722.75 L160.59375,722.75 C161.301002,722.75 161.875,723.328664 161.875,724.041667 C161.875,724.754669 161.301002,725.333333 160.59375,725.333333 Z"
                        id="Shape"/>
                </g>
            </g>
        </>),
        iconProps: {viewBox: "0 0 41 31"}
    };

    public static PASSWORD: IconSVG = {
        icon: <path
            d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
            id={'icon-password'}
        />
    };

    public static BILLING: IconSVG = {
        icon: <path
            d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
            id={"icon-billing"}
        />
    };

    public static DOWNLOAD: IconSVG = {
        icon: <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ListItem-factura" transform="translate(-322.000000, -33.000000)" fill="#67A1D3" fillRule="nonzero">
                <g id="Group-2-Copy" transform="translate(333.000000, 45.000000) rotate(-270.000000) translate(-333.000000, -45.000000) translate(321.000000, 34.000000)">
                    <rect id="Rectangle" x="14.2352941" y="0" width="9.05882353" height="2.58823529" rx="1.29411765"/>
                    <rect id="Rectangle-Copy-6" transform="translate(22.000000, 11.000000) rotate(90.000000) translate(-22.000000, -11.000000) " x="11" y="9.70588235" width="22" height="2.58823529" rx="1.29411765"/>
                    <rect id="Rectangle-Copy" x="14.2352941" y="19.4117647" width="9.05882353" height="2.58823529" rx="1.29411765"/>
                    <path d="M1.61764706,9.70588235 L14.5588235,9.70588235 C15.273545,9.70588235 15.8529412,10.2852786 15.8529412,11 C15.8529412,11.7147214 15.273545,12.2941176 14.5588235,12.2941176 L1.61764706,12.2941176 C0.902925618,12.2941176 0.323529412,11.7147214 0.323529412,11 C0.323529412,10.2852786 0.902925618,9.70588235 1.61764706,9.70588235 Z" id="Rectangle" transform="translate(8.088235, 11.000000) scale(-1, 1) rotate(180.000000) translate(-8.088235, -11.000000) "/>
                    <path d="M7.44117656,12.9414036 L15.2058825,12.9427661 C15.9206406,12.9428915 16.5000001,13.5223526 16.5000001,14.2371108 C16.5000001,14.9517069 15.9207055,15.5310014 15.2061095,15.5310014 C15.2060339,15.5310014 15.2059581,15.5310014 15.2058825,15.5310014 L7.44117656,15.5296389 C6.7264184,15.5295134 6.14705893,14.9500523 6.14705893,14.2352941 C6.14705893,13.5206981 6.72635348,12.9414036 7.44094952,12.9414036 C7.4410252,12.9414036 7.44110088,12.9414036 7.44117656,12.9414036 Z" id="Rectangle" transform="translate(11.323530, 14.236202) scale(-1, 1) rotate(225.000000) translate(-11.323530, -14.236202) "/>
                    <path d="M7.44117656,6.47081552 L15.2058825,6.47217803 C15.9206406,6.47230349 16.5000001,7.0517646 16.5000001,7.76652279 C16.5000001,8.48111883 15.9207055,9.06041339 15.2061095,9.06041339 C15.2060339,9.06041339 15.2059581,9.06041339 15.2058825,9.06041339 L7.44117656,9.05905087 C6.72641843,9.05892541 6.14705893,8.4794643 6.14705893,7.76470611 C6.14705893,7.05011007 6.72635347,6.47081552 7.44094951,6.47081552 C7.44102521,6.47081552 7.44110085,6.47081552 7.44117656,6.47081552 Z" id="Rectangle" transform="translate(11.323530, 7.765614) scale(-1, 1) rotate(135.000000) translate(-11.323530, -7.765614) "/>
                </g>
            </g>
        </g>
    }
}