import * as React from 'react';
import {Component, ReactNode} from 'react';
import classNames from 'classnames';

export interface CardBodyProps {
    className?: string
}

export default class CardBody extends Component<CardBodyProps> {

    public render(): ReactNode {
        const classComponent = classNames('body', this.props.className);
        return (
            <div className={classComponent}>
                {this.props.children}
            </div>
        );
    }

}

