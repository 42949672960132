import * as React from "react";
import {Modal} from "react-bootstrap";
import IconBase from "../IconSVG/IconBase";

interface ModalHeaderTitleProps {
    title: string,
    message?: any,
    classNameTitle?: string,
    showCloseButton?: boolean,
    icon?: React.ReactNode,
    classNameIcon?: string,
}

export default class ModalHeaderTitle extends React.Component<ModalHeaderTitleProps> {

    public render(): React.ReactNode {
        const {title, message, classNameTitle, showCloseButton = true, icon, classNameIcon} = this.props;

        return (
            <>
                <Modal.Header className={"modal-header"} closeButton={showCloseButton}>
                    <Modal.Title>
                        {icon && <IconBase icon={icon} className={`icon-header ${classNameIcon}`} />}
                        <h4>{title}</h4>
                    </Modal.Title>
                </Modal.Header>
                {message && <Modal.Title className={classNameTitle}>
                    {message}
                </Modal.Title>}
            </>
        )
    }
}