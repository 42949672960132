import {ReduxStorageUtils} from "co-redux-builders";
import { reducer as FormReducer } from "redux-form";
import MasterDataReducer from "./reducers/MasterDataReducer";
import LoginReducer from "../modules/login/LoginReducer";
import RegisterMerchantScreenReducer from "../modules/merchantRegistration/RegisterMerchantScreenReducer";
import UserContactSectionViewReducer from "../modules/personalArea/userDataView/UserContactSectionViewReducer";
import BillingDataSectionViewReducer from "../modules/personalArea/billingDataView/BillingDataSectionViewReducer";
import VehicleFormReducer from "../modules/vehicle/form/VehicleFormReducer";
import ParkingMeterListViewReducer from "../modules/history/parkingMeter/ParkingMeterListViewReducer";
import ParkingListViewReducer from "../modules/history/parking/ParkingListViewReducer";
import BillingListViewReducer from "../modules/history/billing/BillingListViewReducer";
import SuggestionFormReducer from "../modules/contact/SuggestionFormReducer";

// reactotronKey
(FormReducer as any).reactotronKey = "form";

export default ReduxStorageUtils.combineReducers([
    /* GENERAL */
    FormReducer as any,
    MasterDataReducer,

    /* LOGIN */
    LoginReducer,

    /* REGISTER MERCHANT */
    RegisterMerchantScreenReducer,

    /* PERSONAL AREA */
    UserContactSectionViewReducer,
    BillingDataSectionViewReducer,

    /* VEHICLE */
    VehicleFormReducer,

    /* HISTORY */
    ParkingMeterListViewReducer,
    ParkingListViewReducer,
    BillingListViewReducer,

    /* CONTACT */
    SuggestionFormReducer,
]);