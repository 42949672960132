import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Auth} from "../../utils/AuthManager";

export interface TaskSendSuggestionRequest extends Auth {
    suggestion: {
        suggestion_type_id: number,
        text: string,
        city_id: number,
    }
}

export default class TaskSendSuggestion extends BaseGenericRequest<TaskSendSuggestionRequest, TaskResponse<{}>> {

    private readonly suggestionData: TaskSendSuggestionRequest['suggestion'];

    public constructor(suggestionData: TaskSendSuggestionRequest['suggestion']) {
        super(METHOD.METHOD_POST, Urls.URL_SEND_SUGGESTION);

        this.suggestionData = suggestionData;
    }

    protected getRequest(): TaskSendSuggestionRequest {
        return {
            ...this.getAuth() as Auth,
            suggestion: this.suggestionData,
        }
    }
}