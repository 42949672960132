import {METHOD, ReduxAwareGenericRequest} from "co-generic-request";
import {ReduxStorageUtils} from "co-redux-builders";
import I18nUtils from "../I18n/I18nUtils";
import {TR_GENERIC_WS_ERROR} from "../I18n/constants";
import {Auth} from "../utils/AuthManager";
import AuthManager from "../utils/AuthManager";

export default abstract class BaseGenericRequest<Request = {}, Response = {}>
    extends ReduxAwareGenericRequest<Request, Response> {

    protected constructor(method: METHOD, url: string) {
        super(method, url, I18nUtils.tr(TR_GENERIC_WS_ERROR), ReduxStorageUtils.dispatch);

        this.addHeader("Accept", "application/json");
        this.addHeader("Content-type", "application/json");

        this.setErrorMessage(500, I18nUtils.tr(TR_GENERIC_WS_ERROR));
        // this.setErrorMessage(400, I18nUtils.tr(TR_FALTAN_DATOS));
        // this.setErrorMessage(404, I18nUtils.tr(TR_EL_ELEMENTO_NO_EXISTE));
        // this.setErrorMessage(401, I18nUtils.tr(TR_NO_TIENES_PERMISOS_PARA_REALIZAR_ESTA_ACCION));
    }

    protected isResponseSuccess(response: { status: number; _bodyText?: string }): boolean {
        let success = super.isResponseSuccess(response);
        const responseBody = JSON.parse(response._bodyText || '{}');
        success = success || responseBody.code === 'SUCCESS';
        return success;
    }

    protected parseResponse(responseObj: any): Response {
        // if (this.errorMessages[responseObj.code]) {
        //     throw new Error(this.errorMessages[responseObj.code]);
        // }
        if (responseObj.code !== 'SUCCESS') {
            if (responseObj.errorMessage) {
                throw new Error(responseObj.errorMessage);
            } else {
                throw new Error(responseObj.message);
            }
        }
        return super.parseResponse(responseObj);
    }

    protected parseError(errorObj: string): string {
        try {
            return JSON.parse(errorObj).errorMessage || super.parseError(errorObj);
        } catch (ignored) {
            return super.parseError(errorObj);
        }
    }

    protected getAuth(): Auth | {} {
        if (AuthManager.isLogged()) {
            return AuthManager.getAuth() as Auth;
        } else {
            return {};
        }
    }

}
