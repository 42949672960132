import * as React from 'react';
import {WrappedFieldProps} from 'redux-form';

export interface FormTextAreaProps {
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    autoComplete?: string
    rows: number,
    forceErrorMessage?: string,

    labelClassName?: string,
    textareaClassName?: string,
    containerClassName?: string,
}

class FormTextArea extends React.Component<FormTextAreaProps & WrappedFieldProps> {

    public render(): React.ReactNode {

        const {
            input, meta, label, placeholder, disabled, rows, labelClassName, forceErrorMessage, textareaClassName, containerClassName
        } = this.props;

        let errorMessage = '';
        if (forceErrorMessage && meta.touched) {
            errorMessage = forceErrorMessage;
        } else {
            errorMessage = meta.touched ? meta.error : '';
        }

        return (
            <div className={containerClassName}>
                {label && <label className={labelClassName}>{label}</label>}
                <textarea rows={rows}
                          className={`form-control input-textarea ${textareaClassName}`}
                          {...input} disabled={disabled || false}
                          placeholder={placeholder}>
                        </textarea>
                <label className="error">{errorMessage}</label>
            </div>
        );
    }
}

export default FormTextArea as unknown as React.ComponentType<FormTextAreaProps>;
