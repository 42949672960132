import * as React from "react";
import {CSSProperties} from "react";

export interface IconBaseProps {
    fill?: string,
    width?: string,
    height?: string,
    viewBox?: string,
}

interface Props {
    style?: CSSProperties,
    className?: string,
    // renderIcon: () => React.ReactNode,
    icon: React.ReactNode,
    iconBaseProps?: IconBaseProps,
    onClick?: () => void,
}

export default class IconWeb extends React.Component<Props> {

    public render() {
        const {
            icon,
            iconBaseProps,
            style,
            className,
            onClick
        } = this.props;

        return (
            <svg
                fill={iconBaseProps ? iconBaseProps.fill: "#000"}
                width={iconBaseProps ? iconBaseProps.width: "100"}
                height={iconBaseProps ? iconBaseProps.height: "100"}
                viewBox={iconBaseProps ? iconBaseProps.viewBox : "0 0 24 24"}
                style={style}
                xmlns="http://www.w3.org/2000/svg"
                // className={`svg-icon ${className || ""}`}
                className={`${!!onClick && "pointer"} ${className}`}
                onClick={onClick}

                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {/*{renderIcon()}*/}
                {icon}
            </svg>)
    }
}
