export default class Config {
    public static AUTH_COOKIE_USERNAME = "AUTH_COOKIE_USERNAME";
    public static AUTH_COOKIE_PASSWORD = "AUTH_COOKIE_PASSWORD";
    public static AUTH_COOKIE_CITY_ID = "AUTH_COOKIE_CITY_ID";
    public static AUTH_COOKIE_CITY_NAME = "AUTH_COOKIE_CITY_NAME";
    public static COOKIE_CAR_ID = "COOKIE_CAR_ID";
    public static COOKIE_CAR_NAME = "COOKIE_CAR_NAME";

    public static DEFAULT_MAP_POSITION = {
        lat: 40.41390113139636,
        lng: -3.704974429443382
    };
    // AIzaSyCO6cE7jmE7zU83ivfE4c3Mi0jt1lAjUKU
    public static GOOGLE_API_KEY = 'AIzaSyDdqIbZ8bcg8eKRebo6fwoBM84NpIqfO-g';

    public static SVG_WEB_URL = 'https://vng-aparcaments.cat';
}

export class AppIcon {

    public static PARKING_METER: string = 'compass_calibration';
    public static INFORMATION: string = 'info';
    public static PUBLIC_PARKING: string = 'local_parking';
    public static CITY: string = 'location_city';

}