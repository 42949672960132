import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import User from "../../model/User";
import {Auth} from "../../utils/AuthManager";
import AuthRequest from "../AuthRequest";

export interface TaskUpdateUserRequest extends AuthRequest {
    user: {
        icon: string,
    },
}

export default class TaskUpdateUserIcon extends BaseGenericRequest<TaskUpdateUserRequest, TaskResponse<User>> {

    private readonly icon: string;

    public constructor(icon: string) {
        super(METHOD.METHOD_POST, Urls.URL_UPDATE_USER_ICON);
        this.icon = icon;
    }

    protected getRequest(): TaskUpdateUserRequest {
        return {
            ...this.getAuth() as Auth,
            user: {
                icon: this.icon,
            }
        }
    }
}