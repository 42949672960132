import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Association from "../../model/Association";

export default class TaskRetrieveAssociations extends BaseGenericRequest<{}, TaskResponse<Association[]>> {

    public constructor() {
        super(METHOD.METHOD_POST, Urls.URL_ASSOCIATION_LIST);
    }
}